import React from 'react'
import styles from './main.module.scss'

const Main = ({ children }) => (
  <main role="main" className={styles.root}>
    {children}
  </main>
)

export default Main

import prodImages from 'assets/images/products'
import images from 'assets/images'

export default {
  id: 22,
  name: 'アクロパス・エイシーケア プラス',
  images: prodImages.accarePlus.slides.map((image, i) => ({
    id: i + 1,
    src: image,
  })),
  illustration: images.acropassProductDetails,
  lpImage: (
    <img
      src={prodImages.accarePlus.lps?.[0]}
      style={{ width: '100%' }}
      alt="lp"
    />
  ),
  intro:
    '発売以来、皆様から大変ご好評いただいております「アクロパス エイシーケア 」の仲間として、\n ☽ナイトケア用と☀デイケア用が入ったお得なセットです。ぜひお試しください!!!\n お肌に突然できたポツポツ (S.O.S)に悩んでいる方に、\n より健やかなお肌を目指して、☽ナイトケア用と☀デイケア用に分けて、1日中ケアができるセット品！\n\n ☽ナイトケア\nSTEP1. アクロパス スキンクレンザー\n →ティーツリー葉油成分が、肌に付着している汚れ等をきれいにしてくれます。\n\nSTEP2. アクロパス エイシーケア（Microneedle Patch)　＜素肌ケア＞\n →ニードル状の美容液シートが高濃度ヒアルロン酸とナイアシンアミドをお肌の奥すみずみまで美肌成分をしっかりと届けます！\n\n 貼って寝ている間に、美容成分がお肌の中でじわじわと浸透し、すこやかな肌に整えます\n (*角質層まで）\n\n ☀デイケア\n STEP3. アクロパス デイパッチ（ハイドロコロイド素材）＜赤みカバー＞\n →赤くなったポツッの上に付着して皮膚を保護・赤みをカバー。しかもその上からメイクも可能です！\n カバーするだけではなく、刺激や雑菌から肌を保護し、清潔に保ちます。',
  details: [
    {
      title: '商品名',
      content: 'アクロパス・エイシーケア プラス',
    },
    {
      title: 'メーカー',
      content: '株式会社RAPHAS JAPAN / ラパス ジャパン',
    },
    {
      title: '用途',
      content: 'ニキビや吹き出物のケアに',
    },
    {
      title: 'JAN',
      content: '4573161350209',
    },
    {
      title: '容量',
      content:
        '【スキンクレンザー】9枚入り\n【エイシーケア(Microcone Patch)】９パッチ\n【デイケアパッチ(ハイドロコロイド素材)】15パッチ',
    },
    {
      title: '全成分',
      content:
        '【スキンクレンザー】エタノール、水、ティーツリー葉油、サリチル酸、酢酸トコフェロール\n【エイシーケア(Microcone Patch)】加水分解ヒアルロン酸、ナイアシンアミド、オリゴペプチド-76、水酸化Ｎａ',
    },
    {
      title: '製造国',
      content: '韓国',
    },
    {
      title: '使用の目安',
      content: '1日1回（最低2時間以上貼ること・一晩貼っても構いません。）',
    },
    {
      title: '使用上のご注意',
      content:
        '・水に触れると表面のヒアルロン酸が溶けてしまいますので、必ず水分をふき取ったところに乾いた手でお貼りください。\n・使用中または使用後に、赤み・はれ・かゆみや刺激などがあらわれた時はすぐにご使用を中止してください。',
    },
  ],
  price: {
    amount: '¥ 3,190',
    tax: '（税込）',
  },
}

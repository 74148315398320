import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/Modal'
import FontIcon from 'components/FontIcon'
import Button from 'components/Button'
import styles from './modalDelete.module.scss'

const ModalDelete = ({
  isOpen,
  title,
  description,
  onDelete,
  toggle,
  isLoading,
}) => (
  <Modal isOpen={isOpen} toggle={toggle} size="md" centered>
    <div className={styles.inner}>
      <FontIcon name="exclamation-triangle" className={styles.warning} />
      <h4 className={styles.title}>{title}</h4>
      <p>{description}</p>
      <div className={styles.actionBtns}>
        <Button
          className="btn btn-lg btn-primary"
          onClick={toggle}
          disabled={isLoading}
        >
          キャンセル
        </Button>
        <Button
          className={`btn btn-lg btn-secondary ${styles.deleteButton}`}
          onClick={() => {
            toggle()
            onDelete()
          }}
          isLoading={isLoading}
        >
          削除する
        </Button>
      </div>
    </div>
  </Modal>
)

ModalDelete.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  isOpen: PropTypes.bool,
  onDelete: PropTypes.func,
  toggle: PropTypes.func,
  isLoading: PropTypes.bool,
}

ModalDelete.defaultProps = {
  title: '',
  description: '',
  isOpen: false,
  onDelete: () => null,
  toggle: () => null,
  isLoading: () => null,
}

export default ModalDelete

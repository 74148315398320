import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { path } from 'utils/const'
import Login from './Login'
import ResetPassword from './ResetPassword'
import styles from './auth.module.scss'

const Auth = () => (
  <div className={styles.root}>
    <div className={styles.container}>
      <Switch>
        <Route exact path={path.login}>
          <Login />
        </Route>
        <Route exact path={path.resetPassword}>
          <ResetPassword />
        </Route>
        <Redirect to={path.login} />
      </Switch>
    </div>
  </div>
)

export default Auth

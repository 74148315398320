import React from 'react'
import { useSelector } from 'react-redux'
import Auth from './auth'
import Dashboard from './dashboard'

const Admin = () => {
  const { loggedIn, checked } = useSelector((state) => state.app)
  if (!checked) return null
  return loggedIn ? <Dashboard /> : <Auth />
}

export default Admin

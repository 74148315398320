import React from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'slices/app.slice'
import FontIcon from 'components/FontIcon'
import Button from 'components/Button'
import styles from './userPopup.module.scss'

const UserPopup = React.forwardRef((_, ref) => {
  const dispatch = useDispatch()
  const logout = () => dispatch(actions.logout())

  return (
    <div ref={ref} className={`${styles.root} shadow`}>
      <span className={styles.title}>プロフィール</span>
      <ul className={styles.menu}>
        <li className={styles.menuItem}>
          <Button
            className={`${styles.menuItemInner} ${styles.btnLogout}`}
            onClick={logout}
          >
            <FontIcon name="power-off" />
            ログアウト
          </Button>
        </li>
      </ul>
    </div>
  )
})

UserPopup.propTypes = {}
UserPopup.defaultProps = {}

export default UserPopup

import React from 'react'
import { PropTypes } from 'prop-types'
import { useTable } from 'react-table'
import styles from './table.module.scss'

/**
 * https://react-table-v7.tanstack.com/docs/overview
 * @param {object}
 * @returns
 */
const Table = ({ columns, data, onClick, className, style }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    })

  const onRowClick = (row) => () => onClick(row)

  return (
    <table
      {...getTableProps()}
      className={`${styles.root} ${className}`}
      style={style}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps({ style: column?.style })}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row)
          console.log('[##] row', row)
          return (
            <tr {...row.getRowProps()} onClick={onRowClick(row)}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

Table.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.shape({}),
}

Table.defaultProps = {
  onClick: () => null,
  className: '',
  style: {},
}

export default Table

import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from 'slices/member.slice'
import ModalDelete from 'pageComponents/ModalDelete'
import PageHeader from 'pageComponents/PageHeader'
import ModalInvite from './ModalInvite'
import MemberList from './MemberList'

// ------------------------------------
// Constants
// ------------------------------------

const ADMIN = 1
const INVITE = 'INVITE'
const UPDATE = 'UPDATE'
const DELETE = 'DELETE'
const DELETE_CONFIRM_MESSAGE =
  'ユーザーを削除すると、そのユーザーは管理画面にアクセスできません。削除しますか?'

// ------------------------------------
// Page
// ------------------------------------

const Member = () => {
  const dispatch = useDispatch()
  const { me } = useSelector((state) => state.app)

  // ------------------------------------
  // Stats
  // ------------------------------------
  const [isOpen, setOpen] = useState(null)
  const [member, setMember] = useState(null)
  const [isLoading, setLoading] = useState(false)

  // ------------------------------------
  // Handlers
  // ------------------------------------
  const closeModal = () => setOpen(false)

  const deleteMember = async () => {
    if (!window.confirm(DELETE_CONFIRM_MESSAGE)) return
    try {
      setLoading(true)
      await dispatch(actions.deleteMember(member?.id))
      await dispatch(actions.getMembers())
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  // ------------------------------------
  // Subscriptions
  // ------------------------------------

  useEffect(() => {
    dispatch(actions.getMembers())
  }, [])

  // ------------------------------------
  // Renderings
  // ------------------------------------
  return (
    <>
      {/* Header */}
      <PageHeader
        title="管理者一覧"
        isLoading={isLoading}
        buttons={
          me?.role === ADMIN && [
            {
              text: '新しい管理者を招待',
              icon: 'paper-plane',
              onClick: () => {
                setMember(null)
                setOpen(INVITE)
              },
            },
          ]
        }
      />
      {/* Table */}
      <MemberList
        onDelete={(m) => {
          setMember(m)
          setOpen(DELETE)
        }}
        onEdit={(m) => {
          setMember(m)
          setOpen(UPDATE)
        }}
      />
      {/* Modal */}
      <ModalInvite
        member={member}
        isOpen={isOpen === INVITE || isOpen === UPDATE}
        toggle={closeModal}
      />
      <ModalDelete
        isOpen={isOpen === DELETE}
        isLoading={isLoading}
        title="管理者の削除"
        description={`${member?.name}さんの管理画面へのアクセス権を削除します。`}
        onDelete={deleteMember}
        toggle={closeModal}
      />
    </>
  )
}

export default Member

import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { auth } from 'utils/firebase'
import config from 'utils/config'

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
}

const httpLink = createHttpLink({
  uri: config.app.apiUrl,
})
const authLink = setContext(async (_, { headers }) => {
  const token = await auth.currentUser?.getIdToken()
  return token
    ? { headers: { ...headers, authorization: `Bearer ${token}` } }
    : { headers }
})

const link = authLink.concat(httpLink)

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions,
})

export default client

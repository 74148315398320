import apollo from 'utils/apollo'
import { gql } from '@apollo/client'
import moment from 'moment'
import { createSlice } from '@reduxjs/toolkit'

// ------------------------------------
// State
// ------------------------------------

const initialState = {}

// ------------------------------------
// Slices
// -----------------------------------

const slice = createSlice({
  name: 'contact',
  initialState,
  reducers: {},
})

// ------------------------------------
// Actions
// -----------------------------------

const sendInquiry = (input) => async () => {
  try {
    const { data, errors } = await apollo.mutate({
      mutation: gql`
        mutation sendInquiry($input: InquiryInput!) {
          sendInquiry(input: $input)
        }
      `,
      variables: {
        input: {
          ...input,
          date: moment().format('YYYY年MM月DD日 HH時mm分'),
        },
      },
    })

    const error = errors?.[0]?.message
    if (error) {
      return new Promise((_, reject) => {
        reject(new Error(error))
      })
    }

    return new Promise((resolve) => {
      resolve(data?.sendInquiry)
    })
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

export const actions = {
  ...slice.actions,
  sendInquiry,
}

export default slice.reducer

import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Input from 'components/Input'
import Button from 'components/Button'
import ErrorBox from 'components/ErrorBox'
import SentResetPassword from 'pageComponents/SentResetPassword'
import { tests } from 'utils/validate'
import { actions } from 'slices/app.slice'
import { path } from 'utils/const'
import styles from './resetPassword.module.scss'

function ResetPassword() {
  const history = useHistory()
  const dispatch = useDispatch()

  // ------------------------------------
  // State
  // ------------------------------------
  const [input, setInput] = useState({
    email: '',
  })
  const [resErr, setResError] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const [isValidated, setIsValidated] = useState(false)

  // ------------------------------------
  // Handlers
  // ------------------------------------
  const handleOnChange = ({ target: { name, value } }) => {
    setInput((prev) => ({ ...prev, [name]: value }))
    setResError('')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsValidated(true)

    const form = e.currentTarget
    // eslint-disable-next-line no-useless-return
    if (!form.checkValidity()) return

    setLoading(true)

    try {
      await dispatch(actions.resetPassword(input.email))
      setOpen(true)
      setResError('')
      setLoading(false)
    } catch (err) {
      setResError(err.message)
      setLoading(false)
    }
  }

  return (
    <div className={styles.root}>
      {resErr && <ErrorBox>{resErr}</ErrorBox>}
      <h1 className={styles.title}>パスワードリセット</h1>

      <form
        noValidate
        className={`${styles.form} ${isValidated ? 'was-validated' : ''}`}
        onSubmit={handleSubmit}
      >
        <Input
          label="メールアドレス"
          name="email"
          placeholder="email@example.com"
          pattern={tests.email.test.source}
          value={input.email}
          onChange={handleOnChange}
          error="メールアドレスが不正です"
          required
        />
        <Button
          type="submit"
          label="パスワードをリセット"
          className={`btn btn-lg btn-secondary ${styles.submitButton}`}
          isLoading={isLoading}
        />
      </form>

      <div>
        <Button
          label="ログイン"
          className={styles.linkButton}
          onClick={() => history.push(path.login)}
        />{' '}
        ページへ戻る
      </div>
      <SentResetPassword
        email={input.email}
        isOpen={isOpen}
        toggle={() => setOpen((prev) => !prev)}
        onSubmit={() => {
          history.push(path.login)
          setOpen((prev) => !prev)
        }}
      />
    </div>
  )
}

ResetPassword.propTypes = {}
ResetPassword.defaultProps = {}

export default ResetPassword

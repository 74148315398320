// import React, { useState } from 'react'
import React from 'react'
import { useSelector } from 'react-redux'
// import Button from 'components/Button'
import images from 'assets/images'
import Icon from 'components/Icon'
import styles from './karadaArrangeBrandStory.module.scss'

const visionData = [
  {
    title: '予防美容',
    content: '生命、自然の知恵、\n顧客のニーズを探求する',
  },
  {
    title: 'SOS応急ケア',
    content: 'インナービューティソリューションを\n創造する新しい方法',
  },
  {
    title: 'アフターケア',
    content: '新しい方法が顧客の\n最高の満足を提供',
  },
]

const identityData = [
  {
    title: 'logo-karada',
    description: 'からだ',
    img: images.brandStoryKarada,
  },
  {
    title: 'logo-arrange',
    description: '整える',
    img: images.brandStoryArrange,
  },
]

const essenceData = [
  {
    title: '予防美容で\n体の内側から\nエイジング対策',
    description:
      'こだわりの成分が\nマスクを外した時に輝けるよう\nあなたをお手伝い',
    keyword: '専門家グループ',
  },
  {
    title: 'シンプルでも\n確かな効果',
    description:
      '高濃度美容菌が\nライフスタイルに合わせて\n本来のバランスを取り戻す',
    keyword: 'New Beauty Solution',
  },
  {
    title: '乳酸菌や美容成分の\n力を借りて\nバランスを整える',
    description: '体に必要な成分を届け\n健やかな体を\n作り上げる',
    keyword: '誠実な販売',
  },
]

const KaradaArrangeBrandStory = () => {
  const { headerHeight } = useSelector((state) => state.status)
  const minHeight = headerHeight && `calc(100vh - ${headerHeight}px)`
  return (
    <>
      {/* Hero */}
      <div style={{ minHeight }} className={styles.hero}>
        <div className={`${styles.heroContainer} container`}>
          <h1 className="sr-only">KARADA ARRANGE by 美肌factory</h1>
          <Icon
            name="logo-karada-arrange-by-bihada-factory"
            className={styles.heroTitle}
          />
          <p className={styles.heroDescription}>
            肌健康の本質に
            <br />
            アプローチし、
            <br />
            肌悩みを根本的に
            <br />
            解決するブランド
          </p>
          <Icon name="text-beauty-force" className={styles.heroText} />
        </div>
      </div>

      {/* Brand Identity */}
      <section
        style={{ minHeight }}
        className={`${styles.section} overflow-hidden`}
      >
        <div className={`${styles.container} container`}>
          <h2 className={styles.sectionTitle}>Brand Identity</h2>
          <div className={styles.identityContent}>
            {identityData.map((item, i) => (
              <React.Fragment key={i.toString()}>
                <div className={styles.identityItem}>
                  <div className={styles.identityItemImg}>
                    <img src={item.img} alt={item.title} />
                  </div>
                  <Icon name={item.title} className={styles.identityItemLogo} />
                  <span className={styles.identityItemDescription}>
                    {item.description}
                  </span>
                </div>
              </React.Fragment>
            ))}

            <img
              src={images.brandStoryIkumi}
              alt="イクミ"
              className={`${styles.identityCharacter} ${styles.identityIkumi}`}
            />
            <img
              src={images.brandStoryMamoru}
              alt="マモル"
              className={`${styles.identityCharacter} ${styles.identityMamoru}`}
            />
          </div>
          <div>
            <span className={styles.identityDescription}>
              カラダの中から健康的に
              <br className="d-sm-none" />
              美しく整える
            </span>
          </div>
        </div>
      </section>

      {/* Our Vision */}
      <section
        style={{ minHeight }}
        className={`${styles.section} ${styles.visionSection}`}
      >
        <div className={`${styles.container} container`}>
          <h2 className={styles.sectionTitle}>Our Vision</h2>
          <span className={styles.visionSubtitle}>
            内側から健やかな体へ導くスペシャルブランド
          </span>
          <div className={styles.visionContent}>
            <ul className={styles.visionContentInner}>
              {visionData.map((item, i) => (
                <li key={i.toString()} className={styles.visionItem}>
                  <div className={styles.visionItemTitle}>
                    <span>{item.title}</span>
                  </div>
                  {/* <span className={styles.visionItemDescription}>
                    {item.content}
                  </span> */}
                </li>
              ))}
            </ul>
          </div>
          <div>
            <span className={styles.visionDescription}>
              体に必要な成分をダイレクトに届けるスペシャルケア
            </span>
          </div>
          <ol className={styles.visionIndicators}>
            <li className={styles.active} />
            <li />
            <li />
          </ol>
        </div>
      </section>

      {/* Our Policy
      <section
        style={{ minHeight }}
        className={`${styles.section} ${styles.policySection}`}
      >
        <div className={`${styles.container} container`}>
          <h2 className={styles.sectionTitle}>Our Policy</h2>
          <ul className={styles.policyContent}>
            {policies.map((policy) => (
              <li key={policy.id} className={styles.policyItem}>
                <Button
                  className={policy.id === activeId ? styles.active : ''}
                  onClick={() => handleClick(policy.id)}
                >
                  {policy.title}
                </Button>
              </li>
            ))}
          </ul>
          {policies.map((policy) =>
            policy.id === activeId ? (
              <div key={policy.id} className={styles.policyText}>
                <span className={styles.policySubtitle}>{policy.subtitle}</span>
                {policy.description && (
                  <span className={styles.policyDescription}>
                    {policy.description}
                  </span>
                )}
              </div>
            ) : null,
          )}
        </div>
      </section> */}

      {/* Essence */}
      <section style={{ minHeight }} className={styles.section}>
        <div className={`${styles.container} container`}>
          <div className={styles.essenceHeader}>
            <h2 className={`${styles.sectionTitle} mb-3`}>Essence</h2>
            {/* <span className={styles.essenceSubtitle}>
              Home Derma filling System
            </span> */}
          </div>
          <div className={styles.essenceContent}>
            {essenceData.map((item, i) => (
              <React.Fragment key={i.toString()}>
                <h3 className={styles.essenceItemTitle}>{item.title}</h3>
                <p className={styles.essenceItemContent}>
                  <span className={styles.essenceItemDescription}>
                    {item.description}
                  </span>
                  {/* <span className={styles.essenceItemKeyword}>
                    {item.keyword}
                  </span> */}
                </p>
              </React.Fragment>
            ))}
          </div>
          <div>
            <span className={styles.essenceDescription}>
              あらゆる変化にも負けない体ケアしましょう
            </span>
          </div>
        </div>
      </section>
    </>
  )
}

export default KaradaArrangeBrandStory

import prodImages from 'assets/images/products'
import images from 'assets/images'

export default {
  id: 7,
  name: 'カラダアレンジ リラクシー入浴料',
  images: prodImages.relaxy.slides.map((image, i) => ({
    id: i + 1,
    src: image,
  })),
  illustration: images.karadaArrangeProductDetails,
  lpImage: (
    <>
      <img
        src={prodImages.relaxy.lps?.[0]}
        style={{ width: '100%' }}
        alt="lp1"
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#f9d0d5',
          width: '100%',
        }}
      >
        <video loop autoPlay muted style={{ width: '50%' }}>
          <source src={prodImages.relaxy.lps?.[1]} type="video/mp4" />
          <track kind="captions" label="japanese_captions" />
        </video>
      </div>
      <img
        src={prodImages.relaxy.lps?.[2]}
        style={{ width: '100%' }}
        alt="lp3"
      />
    </>
  ),
  intro:
    '「デリケートゾーンも乳酸菌でやさしく整える。女性のためのハート保湿美容入浴料」\n\n 毎月訪れる不快な時期やめぐりをよくしたいときのリフレッシュに。シュワシュワ泡に、はじけるローズの香りで気持ちもリラックス。\n\n KARADA ARRANGEに含まれている乳酸菌は、\n 健やかさをサポートする『エンテロコッカスフェカリス菌』なのです。\n そして、\n 本品には、１錠あたり乳酸菌がヨーグルト100個分（※）含まれているんです！\n ※ヨーグルト一個（100グラム）に100億個の乳酸菌が含まれるとした場合',
  details: [
    {
      title: '商品名',
      content: 'カラダアレンジ リラクシー入浴料',
    },
    {
      title: 'メーカー',
      content: '株式会社 RAPHAS JAPAN / ラパス ジャパン',
    },
    {
      title: '用途',
      content:
        '・デリケートエリアに気をつかいたい\n ・ニオイやスッキリしない不快な時期を開放したい\n ・リラックス＆全身の巡りをよくしたい\n ・お風呂の使用が衛生的に気になる\n ・家族みんなで入りたい',
    },
    {
      title: 'JAN',
      content: '4573161350339',
    },
    {
      title: '容量',
      content: '40ｇ×３錠',
    },
    {
      title: '成分',
      content:
        '硫酸Na、リンゴ酸、炭酸水素Na、炭酸Na、PEG-150、シリカ、デキストリン、ヒアルロン酸Na、エンテロコッカスフェカリス、水、香料、赤106、タルク',
    },
    {
      title: '製造国',
      content: '日本',
    },
    {
      title: '使用の目安',
      content:
        '浴槽の湯（２００L）に本品１錠を入れ、よくかきまぜてからご入浴ください。',
    },
    {
      title: '使用上のご注意',
      content:
        '◆皮膚あるいは体質に異常がある場合は医師に相談してご使用ください。\n◆使用中、使用後、または使用したお肌に直射日光があたって、赤味、はれ、かゆみ、刺激等の異常があらわれた場合は使用を中止し、皮膚科専門医等にご相談されることをおすすめします。そのまま使用を続けますと症状が悪化することがあります。\n◆本品は食べられません。万一多量に飲み込んだときは、水を飲ませる等の処置を行った後、医師にご相談ください。\n◆乳幼児の手の届かない所に保管してください。\n◆入浴以外の用途には使用しないでください。\n◆本品には浴槽・風呂釜をいためるイオウは入っておりません。\n◆開封後はすぐにご使用ください。\n◆残り湯は洗髪や洗剤による洗濯にお使いいただけます。ただし、洗濯後のすすぎや柔軟仕上げをするときは、清水をお使いください。\n◆残り湯を洗濯にお使いになる場合は、つけおき洗い、おろしたての衣料、柔軟仕上げを施した衣料、おしゃれ着洗い用の洗剤を使用しての洗濯はお避けください。\n◆白物、おしゃれ着等大切な衣料の洗濯はお避けください。\n◆本品は浴槽に十分お湯を入れてからご使用ください。お湯が少量のうちに本品をご使用されると浴槽に着色することがあります。\n◆大理石・人工大理石の浴槽でのご使用はお避けください。\n◆高温(多湿)及び、直射日光のあたる所には置かないでください。',
    },
  ],
  price: {
    amount: '¥ 1,078',
    tax: '（税込）',
  },
}

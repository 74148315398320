import React from 'react'
import { useSelector } from 'react-redux'
import Icon from 'components/Icon'
import images from 'assets/images'
import styles from './heroPrimary.module.scss'

const HeroPrimary = () => {
  const { headerHeight } = useSelector((state) => state.status)

  return (
    <div
      style={{
        minHeight: headerHeight ? `calc(100vh - ${headerHeight}px)` : '',
      }}
      className={styles.hero}
    >
      <div className={styles.heroContainer}>
        <h1 className="sr-only">美肌factory</h1>
        <Icon
          name="bihada-factory-hero-text"
          className={`${styles.heroText} fill-reset`}
        />

        {/* Mobile */}
        <img
          src={images.bihadaFactoryHeroHiaru}
          alt="Hiaru"
          className={`${styles.heroCharacter} ${styles.heroHearu}`}
        />
        <img
          src={images.bihadaFactoryHeroNidoru}
          alt="Nidoru"
          className={`${styles.heroCharacter} ${styles.heroNidoru}`}
        />
        <img
          src={images.bihadaFactoryHeroMamoru}
          alt="Mamoru"
          className={`${styles.heroCharacter} ${styles.heroMamoru}`}
        />

        {/* Desktop */}
        <img
          src={images.bihadaFactoryHeroHiaruPc}
          alt="Hiaru"
          className={`${styles.heroCharacterPc} ${styles.heroHearu}`}
        />
        <img
          src={images.bihadaFactoryHeroNidoruPc}
          alt="Nidoru"
          className={`${styles.heroCharacterPc} ${styles.heroNidoru}`}
        />
        <img
          src={images.bihadaFactoryHeroIkumiPc}
          alt="Ikumi"
          className={`${styles.heroCharacterPc} ${styles.heroIkumi}`}
        />
        <img
          src={images.bihadaFactoryHeroMamoruPc}
          alt="Mamoru"
          className={`${styles.heroCharacterPc} ${styles.heroMamoru}`}
        />
      </div>
    </div>
  )
}

export default HeroPrimary

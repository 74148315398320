export const path = {
  admin: '/admin',
  login: '/admin/login',
  resetPassword: '/admin/reset-password',
  confirmEmail: '/admin/confirm-email',
  dashboard: '/admin/dashboard',
  notice: '/admin/notice',
  noticeUpdate: '/admin/notice-update',
  reviews: '/admin/reviews',
  reviewsPerProduct: '/admin/reviews-product',
  reviewUpdate: '/admin/reviewUpdate',
  members: '/admin/members',

  home: '/',
  bihadaFactory: '/bihada-factory',
  history: '/history',
  about: '/about',
  acropassBrandStory: '/acropass-brand-story',
  acropassMicroneedle: '/acropass-microneedle',
  karadaArrangeBrandStory: '/karada-arrange-brand-story',
  karadaArrangeLacticAcidBacteria: '/karada-arrange-lactic-acid-bacteria',
  shop: '/shop',
  shopDetail: '/shop/:id',
  blog: '/blog',
  blogDetail: '/blog/:id',
  faq: '/faq',
  contact: '/contact',
  userGuide: '/user-guide',
  privacy: '/privacy',
  commercialTransactions: '/specified-commercial-transactions',
}

export const roles = {
  ADMIN: '管理者',
  VIEW_ONLY: '閲覧者',
}

export const categoryOptions = [
  { value: 1, label: 'ニュース', label2: 'news' },
  { value: 2, label: 'メディア', label2: 'media' },
  { value: 3, label: 'キャンペーン', label2: 'campaign' },
  { value: 4, label: 'その他', label2: 'others' },
]

export const statusOptions = [
  { value: 1, label: '公開' },
  { value: 0, label: '非公開' },
]

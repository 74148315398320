import prodImages from 'assets/images/products'
import images from 'assets/images'

export default {
  id: 12,
  name: 'カラダアレンジ スムースリー乳酸菌シロップ（７包入り）',
  images: prodImages.smoothly_7.slides.map((image, i) => ({
    id: i + 1,
    src: image,
  })),
  illustration: images.karadaArrangeProductDetails,
  lpImage: (
    <img
      src={prodImages.smoothly_7.lps?.[0]}
      style={{ width: '100%' }}
      alt="lp"
    />
  ),
  intro:
    '「いつまでも美しく若々しく活力のある生活のためにインナーフローラから整える」\n\n 乳酸菌、オリゴ糖、食物繊維がインナーフローラを整え、石のようなカチカチ、うさぎのようなコロコロをスッキリスムーズに導きます。\n\n KARADA ARRANGEに含まれている乳酸菌は、\n 健やかさをサポートする『エンテロコッカス・フェカリス菌』なのです。\n そして、\n 本品には、１包あたり乳酸菌がヨーグルト50個分（※）含まれているんです！ ※ヨーグルト一個（100グラム）に100億個の乳酸菌が含まれるとした場合',
  details: [
    {
      title: '商品名',
      content: 'カラダアレンジ スムースリー乳酸菌シロップ（７包入り）',
    },
    {
      title: 'メーカー',
      content: '株式会社 RAPHAS JAPAN / ラパス ジャパン',
    },
    {
      title: '用途',
      content:
        '・石のようなカチカチ、うさぎのようなコロコロをスッキリさせたい方\n ・ヨーグルトの酸味が苦手な方\n ・乳酸菌をおいしく、たくさん摂りたい方\n ・野菜と果物を使ったスムージーを毎日の食生活に取り入れている方\n ',
    },
    {
      title: 'JAN',
      content: '4573161350315',
    },
    {
      title: '容量',
      content: '70ｇ（10ｇ×7包）',
    },
    {
      title: '成分',
      content:
        'ガラクトオリゴ糖液糖（国内製造）、食物繊維、乳酸菌末(国内製造)、酸味料、グレープフルーツ種子抽出物、香料',
    },
    {
      title: '製造国',
      content: '日本',
    },
    {
      title: '使用の目安',
      content:
        '健康補助食品として１日１～２包を目安に、そのままお召し上がりください。ヨーグルトにかけて、スムージーに入れて、シリアルのトッピングにもおすすめです。',
    },
    {
      title: '使用上のご注意',
      content:
        '【保存方法】\n 直射日光、高温多湿を避けて保存してください。\n \n 【使用上の注意】\n ※食品アレルギーがある方は、原材料をご確認ください。\n ※開封後はお早めにお召し上がりください。\n ※小さなお子様の手の届かない所に保管してください。\n ※体質・体調に合わない場合は、ご利用を中止してください。\n ※乳酸菌が分離する場合がありますが、品質には問題ありません。\n ※本品は、多量摂取により疾病が治癒したり、より健康が増進するものではありません。',
    },
  ],
  price: {
    amount: '¥ 1,650',
    tax: '（税込）',
  },
}

import React from 'react'
import { PropTypes } from 'prop-types'
import styles from './productItem.module.scss'
import ProductTag from '../ProductTag'

const ProductItem = ({
  slogan,
  image,
  name,
  reading,
  tag,
  className,
  style,
}) => (
  <div className={`${styles.root} ${className}`} style={style}>
    <div className={styles.imageContainer}>
      <img src={image} alt="product" />
    </div>
    <div className={styles.textContainer}>
      <p className={styles.slogan}>{slogan}</p>
      <p className={styles.name}>{name}</p>
      <p className={styles.reading}>{reading}</p>
    </div>
    <div>
      <ProductTag title={tag} />
    </div>
  </div>
)

ProductItem.propTypes = {
  slogan: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  reading: PropTypes.string,
  tag: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape({}),
}

ProductItem.defaultProps = {
  slogan: '',
  image: '',
  name: '',
  reading: '',
  tag: '',
  className: '',
  style: {},
}

export default ProductItem

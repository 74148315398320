import { useSelector } from 'react-redux'

const ADMIN = 1

const useMe = () => {
  const { me } = useSelector((state) => state.app)
  const isReadonly = me?.role !== ADMIN
  return { me, isReadonly }
}

export default useMe

import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { actions } from 'slices/app.slice'
import PropTypes from 'prop-types'
import Input from 'components/Input'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { path } from 'utils/const'
import styles from './searchForm.module.scss'

const SearchForm = ({ className }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { searchText } = useSelector((state) => state.app)

  const handleChange = ({ target: { value } }) => {
    history.push(path.shop)
    dispatch(actions.setSearch(value))
  }

  return (
    <form className={`${styles.root} ${className}`} noValidate>
      <Input
        value={searchText}
        className={styles.inputWrap}
        onChange={handleChange}
        required
      />
      <Button className={styles.btnSubmit}>
        <Icon name="search" />
      </Button>
    </form>
  )
}

SearchForm.propTypes = {
  className: PropTypes.string,
}

SearchForm.defaultProps = {
  className: '',
}

export default SearchForm

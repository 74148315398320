import React from 'react'
import PropTypes from 'prop-types'
import Spinner from 'components/Spinner'
import Button from 'components/Button'
import FontIcon from 'components/FontIcon'
import styles from './pageHeader.module.scss'

const PageHeader = ({ title, buttons, isLoading, className }) => (
  <div className={`${styles.root} ${className}`}>
    <div className={styles.titleContainer}>
      <h3>{title}</h3>
      <Spinner isLoading={isLoading} />
    </div>
    <div>
      {Array.isArray(buttons) &&
        buttons.map((button, i) => (
          <Button
            key={i.toString()}
            type={button?.form ? 'submit' : 'button'}
            onClick={button?.onClick}
            className={`btn btn-primary ${button?.className}`}
            style={button?.style}
            form={button?.form}
          >
            <FontIcon name={button?.icon} style={{ marginRight: 8 }} />
            <span>{button?.text}</span>
          </Button>
        ))}
    </div>
  </div>
)

PageHeader.propTypes = {
  isLoading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
}

PageHeader.defaultProps = {
  isLoading: false,
  buttons: [],
  className: '',
}

export default PageHeader

import prodImages from 'assets/images/products'
import images from 'assets/images'

export default {
  id: 23,
  name: 'アクロパス・エイシーケア',
  images: prodImages.accare.slides.map((image, i) => ({
    id: i + 1,
    src: image,
  })),
  illustration: images.acropassProductDetails,
  lpImage: (
    <img src={prodImages.accare.lps?.[0]} style={{ width: '100%' }} alt="lp" />
  ),
  intro:
    '発売以来、皆様から大変ご好評いただいております「アクロパス エイシーケア 」！！！\n できはじめのプツン（S.O.S)には今すぐこれ！つぶさず触らず即効スポットケア！\n 持ち運びに便利なサイズなので、突然のS.OS.にもすぐ対応できます！\n DENマイクロニードル製法技術から誕生したパッチ型化粧品 「アクロパスシリーズ」は、\n 保湿成分のヒアルロン酸を主成分として作られたマイクロニードルがお肌のすみずみ*１まで美肌成分*2を届けます。\n （*１角質層まで）（*2ナイアシンアミド）\n デリケートな素肌ケアに必需品です。',
  details: [
    {
      title: '商品名',
      content: 'アクロパス・エイシーケア',
    },
    {
      title: 'メーカー',
      content: '株式会社RAPHAS JAPAN / ラパス ジャパン',
    },
    {
      title: 'JAN',
      content: '4573161350131',
    },
    {
      title: '容量',
      content: '1箱 6パッチ',
    },
    {
      title: '全成分',
      content:
        '加水分解ヒアルロン酸、ナイアシンアミド、オリゴペプチド-76、水酸化Ｎａ',
    },
    {
      title: '製造国',
      content: '韓国',
    },
    {
      title: '使用の目安',
      content:
        '1日1回（最低2時間以上貼ることを推奨しています。一晩中貼ったままの状態でお休みいただけます）',
    },
    {
      title: '使用上のご注意',
      content:
        '・水に触れると表面のヒアルロン酸が溶けてしまいますので、必ず水分をふき取ったところに乾いた手でお貼りください。\n・使用中または使用後に、赤み・はれ・かゆみや刺激などがあらわれた時はすぐにご使用を中止してください。',
    },
  ],
  price: {
    amount: '¥ 1,870',
    tax: '（税込）',
  },
}

import React from 'react'
import PropTypes from 'prop-types'
// import { Link } from 'react-router-dom'
import images from 'assets/images'
// import { path } from 'utils/const'
import styles from './homeCategory.module.scss'

const categories = [
  {
    id: 1,
    title: '今月のおすすめ',
    desc: 'マンスリースペシャル',
    // link: `${path.shop}?section=1`,
    link: `https://shop.acropass.jp/`,
    image: {
      src: images.homeCategorySkinCare,
    },
  },
  {
    id: 2,
    title: '毎日のケアに',
    desc: '予防美容ケア',
    // link: `${path.shop}?section=2`,
    link: `https://shop.acropass.jp/`,
    image: {
      src: images.homeCategoryWhiteningCare,
    },
  },
  {
    id: 3,
    title: '困ったときは',
    desc: 'SOS応急美容ケア',
    // link: `${path.shop}?section=3`,
    link: `https://shop.acropass.jp/`,
    image: {
      src: images.homeCategoryWrinkleCare,
    },
  },
  {
    id: 4,
    title: 'お肌のトラブルを改善',
    desc: 'アフター美容ケア',
    // link: `${path.shop}?section=4`,
    link: `https://shop.acropass.jp/`,
    image: {
      src: images.homeCategoryBroma,
    },
  },
]

const HomeCategoryItem = ({ id, title, desc, link, image }) => (
  <li key={id} className={styles.item}>
    <a href={link}>
      {title && <span className={styles.itemCaption}>{title}</span>}
      {desc && <h3 className={styles.itemTitle}>{desc}</h3>}
      {image.src && (
        <div className={styles.itemImg}>
          <img src={image.src} alt={title} />
        </div>
      )}
    </a>
  </li>
)

const HomeCategory = () => (
  <section className={styles.root}>
    <div className="container">
      <h2 className={styles.title}>CATEGORY</h2>
      <ul className={styles.content}>
        {categories.map((category) => (
          <HomeCategoryItem key={category.id} {...category} />
        ))}
      </ul>
    </div>
  </section>
)

HomeCategoryItem.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  desc: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.shape({}),
}

HomeCategoryItem.defaultProps = {
  id: null,
  title: '',
  desc: '',
  link: '',
  image: {},
}

export default HomeCategory

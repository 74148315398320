import React from 'react'
import PageLayout from 'pageComponents/PageLayout'
import images from 'assets/images'
import styles from './about.module.scss'

const data = [
  {
    title: '会社名',
    content: '株式会社RAPHAS JAPAN',
  },
  {
    title: '本社',
    content: (
      <span>
        {'東京都港区芝4-5-11 '}
        <br />
        芝プラザビル8F
      </span>
    ),
  },
  {
    title: 'ショールーム',
    content: (
      <span>
        {'東京都港区芝4-5-11 '}
        <br />
        芝プラザビル8F
      </span>
    ),
  },
  {
    title: '工場住所',
    content: '静岡県静岡市葵区辰起町10-15',
  },
  {
    title: '代表者',
    content: '権　有利',
  },
  {
    title: '設立',
    content: '2014年4月',
  },
  {
    title: '資本金',
    content: '5,000万円',
  },
  {
    title: '事業内容',
    content:
      '化粧品の輸入及び販売・化粧品製造業・乳酸菌\nマイクロニードル化粧品の製造・販売、\n乳酸菌製品の製造・販売',
  },
  {
    title: '沿革',
    content:
      '2014年　株式会社RAPHASの日本法人として設立\n2015年　化粧品製造業・化粧品製造販売業取得\n2019年　静岡工場設立\n                   静岡工場にて化粧品製造業取得\n2020年　静岡工場内に研究所設立',
  },
]

const About = () => (
  <PageLayout
    title="紹介"
    description="人々の健康に貢献するため、治癒への道を拓くグローバル技術企業｢RAPHAS｣をご紹介します。"
    backgroundImage={images.about}
  >
    <div className={styles.content}>
      <table className={styles.table}>
        <tbody>
          {data.map(({ title, content }, i) => (
            <tr key={i.toString()}>
              <th>{title}</th>
              <td>{content}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </PageLayout>
)

export default About

import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import useMe from 'hooks/useMe'
import { PropTypes } from 'prop-types'
import PageHeader from 'pageComponents/PageHeader'
import Table from 'components/Table'
import { actions } from 'slices/review.slice'
import products from 'data/products'
import { path } from 'utils/const'
import options, { findOption } from 'utils/options'
import styles from './reviewsPerProduct.module.scss'

const columns = [
  {
    Header: '名前',
    accessor: (row) => row.name,
  },
  {
    Header: 'スコア',
    accessor: (row) => findOption(options.scores, row.score).label,
  },
  {
    Header: 'コメント',
    accessor: (row) => row.content,
  },
]

const Reviews = ({ className, style }) => {
  const history = useHistory()
  const location = useLocation()
  const productId = location?.state?.id
  const product = products.find(({ id }) => id === productId)
  const { isReadonly } = useMe()

  const [reviews, setReviews] = useState([])
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    actions
      .getReviews(productId)
      .then((res) => {
        setReviews(res.reverse())
        setLoading(false)
      })
      .catch((err) => {
        console.log('[##] err', err)
        setLoading(false)
      })
  }, [])

  return (
    <div className={`${styles.root} ${className}`} style={style}>
      <PageHeader
        title={product?.name}
        isLoading={isLoading}
        buttons={
          !isReadonly && [
            {
              text: '新しいレビューを追加',
              icon: 'plus',
              onClick: () => {
                history.push({
                  pathname: path.reviewUpdate,
                  state: { productId },
                })
              },
            },
          ]
        }
      />
      <div className={styles.tableContainer}>
        <Table
          data={reviews || []}
          columns={columns}
          onClick={({ original }) => {
            history.push({
              pathname: path.reviewUpdate,
              state: { id: original?.id, productId },
            })
          }}
        />
        {!isLoading && Array.isArray(reviews) && reviews.length === 0 && (
          <b style={{ marginTop: 24, textAlign: 'center' }}>
            💬 レビューはありません。
          </b>
        )}
      </div>
    </div>
  )
}

Reviews.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
}

Reviews.defaultProps = {
  className: '',
  style: {},
}

export default Reviews

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Scroll from 'react-scroll'
import { useSelector } from 'react-redux'
// import { Link } from 'react-router-dom'
import Icon from 'components/Icon'
import images from 'assets/images'
import prodImages from 'assets/images/products'
// import { path } from 'utils/const'
import styles from './karadaArrangeLacticAcidBacteria.module.scss'

const ANIMATION_SPEED = 3
const NUMBER_OF_SLIDES = 5

const products = [
  {
    id: 6,
    title: 'カラダアレンジ\nリカバリー乳酸菌顆粒',
    category: '予防美容ケア',
    // link: `${path.shop}/6`,
    link: `https://shop.acropass.jp/items/56477302`,
    image: {
      src: prodImages.recovery.top,
    },
  },
  {
    id: 7,
    title: 'カラダアレンジ\nリラクシー入浴料',
    category: '予防美容ケア',
    // link: `${path.shop}/7`,
    link: `https://shop.acropass.jp/items/56477478`,
    image: {
      src: prodImages.relaxy.top,
    },
  },
  // {
  //   id: 13,
  //   title: 'カラダアレンジ\nスタビリティー乳酸菌顆粒\n（30包入り）',
  //   category: 'アフター美容ケア',
  //   link: `${path.shop}/13`,
  //   image: {
  //     src: prodImages.stability.top,
  //   },
  // },
  {
    id: 12,
    title: 'カラダアレンジ\nスムースリー乳酸菌シロップ\n（７包入り）',
    category: 'SOS応急美容ケア',
    // link: `${path.shop}/12`,
    link: `https://shop.acropass.jp/items/56191602`,
    image: {
      src: prodImages.smoothly_7.top,
    },
  },
  {
    id: 11,
    title: 'カラダアレンジ\nスムースリー乳酸菌シロップ\n（20包入り）',
    category: 'SOS応急美容ケア',
    // link: `${path.shop}/11`,
    link: `https://shop.acropass.jp/items/56189553`,
    image: {
      src: prodImages.smoothly_20.top,
    },
  },
  // {
  //   id: 27,
  //   title: 'カラダアレンジ\n強力乳酸菌あめ',
  //   category: '予防美容ケア',
  //   link: `${path.shop}/27`,
  //   image: {
  //     src: prodImages.lacticCandy.slides[1],
  //   },
  // },
  {
    id: 26,
    title: 'カラダアレンジ\nインフルバリアのど飴',
    category: 'SOS応急美容ケア',
    // link: `${path.shop}/26`,
    link: `https://shop.acropass.jp/items/59350299`,
    image: {
      src: prodImages.fluCandy.top,
    },
  },
]

const Product = ({ title, category, link, image }) => (
  <li>
    <a href={link} className={styles.productContent}>
      {category && <span className={styles.productCategory}>{category}</span>}
      {image.src && (
        <div className={styles.productImg}>
          <img src={image.src} alt={title} />
        </div>
      )}
    </a>
    {title && (
      <h3 className={styles.productTitle}>
        <a href={link}>{title}</a>
      </h3>
    )}
  </li>
)

const KaradaArrangeLacticAcidBacteria = () => {
  const { headerHeight } = useSelector((state) => state.status)
  const minHeight = headerHeight ? `calc(100vh - ${headerHeight}px)` : ''
  const [isComplete, setComplete] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setComplete(true)
      Scroll.scroller.scrollTo('product-list', { offset: -headerHeight || -66 })
    }, ANIMATION_SPEED * NUMBER_OF_SLIDES * 1000)
  }, [])

  return (
    <>
      {/* Animation slides */}
      <div className={`${styles.slideWrapper} ${isComplete && styles.showAll}`}>
        <div
          id="product-list"
          className={`${styles.slide} ${styles.slideBg03} ${
            isComplete && styles.showAll
          }`}
          style={{ minHeight }}
        >
          <div
            className={`${styles.slideContainerGutterSm} container mt-auto mt-md-0`}
          >
            <div className={styles.slideContentHalf}>
              <div className={`${styles.slideSubtitle} ${styles.textColored}`}>
                <Icon name="logo-karada-arrange" />
                <span>の主要成分</span>
              </div>
              <h2 className={styles.slideTitleSm}>
                安全性・機能性に優れる乳酸菌 ｢加熱処理菌体
                <br />
                エンテロコッカスフェカリス菌｣
              </h2>
              <p className={styles.slideDescription}>
                エンテロコッカス・フェカリス菌は、安全性および機能性について
                <br />
                30年以上の研究歴史がある乳酸菌で、他の乳酸菌に比べて免疫調整作用が
                強いといわれています。
              </p>
            </div>
          </div>
        </div>

        <div className={styles.slide}>
          <div className={`${styles.slideContainer} container`}>
            <div className="text-center">
              <h2 className={styles.slideTitleLg}>
                健康な
                <br className="d-sm-none" />
                ヒト由来の乳酸菌
              </h2>
            </div>
          </div>
        </div>

        <div className={`${styles.slide} ${styles.slideBg04}`}>
          <div className={`${styles.circle} ${styles.position03}`} />
        </div>

        <div
          className={`${styles.slide} ${styles.slideBg04} ${styles.blur} ${
            !isComplete && styles.overlay
          } ${isComplete && styles.showAll}`}
          style={{ minHeight }}
        >
          <div className={`${styles.slideContainer} container`}>
            <div className={styles.slideContent}>
              <div className={styles.slideImg}>
                <img
                  src={images.KaradaArrangeLacticAcidBacteria}
                  alt="乳酸菌"
                />
              </div>
              <div>
                <span className={styles.slideCaption}>
                  体内に吸収するために最適な乳酸菌
                </span>
                <h2 className={styles.slideTitleSm}>
                  粉末1g当たり
                  <br />
                  菌体5兆個以上につくりあげる
                  <br className="d-none d-lg-inline" />
                  <span className={styles.textColored}>高密度培養技術</span>
                </h2>
                <p className={styles.slideDescription}>
                  カラダアレンジの主要成分である乳酸菌は、
                  <br className="d-none d-lg-inline" />
                  健康なヒト由来「エンテロコッカス・フェカリス菌」を高密度培養し、
                  <br className="d-none d-lg-inline" />
                  加熱処理した安定型乳酸菌です。
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`${styles.slide} ${styles.slideBg04} ${styles.blur} ${
            !isComplete && styles.overlay
          } ${isComplete && styles.showAll}`}
          style={{ minHeight }}
        >
          <div className={`${styles.slideContainer} container`}>
            <div className={styles.slideContent}>
              <div className={styles.slideImg}>
                <img
                  src={images.KaradaArrangeLacticAcidBacteria}
                  alt="乳酸菌"
                />
              </div>
              <div className="mx-auto mx-md-0">
                <h2 className={styles.slideTitleSm}>
                  丸い形をした小さな
                  <br className="d-none d-md-inline d-lg-none" />
                  乳酸菌
                </h2>
                <p className={styles.slideDescription}>
                  加熱処理した安定型乳酸菌は、
                  <br />
                  生菌特有の「一塊」になる習性を防ぎます。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h1 className="sr-only">カラダアレンジ　乳酸菌</h1>
      <section className={styles.section}>
        <div className="container">
          <h2 className={`${styles.sectionTitle} mb-xl`}>
            カラダの中から
            <br className="d-sm-none" />
            健康的に美しく整える
          </h2>
          <ul className={styles.products}>
            {products.map((product) => (
              <Product key={product.id} {...product} />
            ))}
          </ul>
        </div>
      </section>
    </>
  )
}

Product.propTypes = {
  title: PropTypes.string,
  category: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.shape({}),
}

Product.defaultProps = {
  title: '',
  category: '',
  link: '',
  image: {},
}

export default KaradaArrangeLacticAcidBacteria

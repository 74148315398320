import React from 'react'
import styles from './commercialTransactions.module.scss'

const CommercialTransaction = () => (
  <div className={styles.root}>
    <div className="container">
      <div className="container-inner">
        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>会社概要</h2>
          <ul className={styles.ul}>
            <li>会社名　　　　：　株式会社RAPHAS JAPAN</li>
            <li>
              所在地　　　　：　〒108-0014 東京都港区芝4-5-11 芝プラザビル8F
            </li>
            <li>電話　　　　　：　03-6806-0513（代）FAX：03-6806-0574</li>
            <li>工場・研究所　：　〒420-0874</li>
            <p>
              　　　　　　　　　静岡県静岡市葵区辰起町10-5
              <br />
              　　　　　　　　　電話 : 054-204-0260FAX:054-204-0261
            </p>
            <li>代表者　　　　：　権有利</li>
            <li>設立　　　　　：　2014年4月</li>
            <li>資本金　　　　：　5,000万円</li>
            <li>事業内容　　　：　化粧品の輸入及び販売・化粧品製造業</li>
          </ul>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>
            特定商取引に関する法律に基づく表記
          </h2>
          <ul className={styles.ul}>
            <li>販売業者　　　　　　：　株式会社RAPHAS JAPAN</li>
            <li>運営責任者　　　　　：　権 有利</li>
            <li>
              住所　　　　　　　　：　〒108-0014 東京都港区芝4-5-11
              芝プラザビル8F
            </li>
            <li>電話番号　　　　　　：　03-6806-0513</li>
            <li>ＦＡＸ番号　　　　　：　03-6806-0574</li>
            <li>メールアドレス　　　：　info@raphas.jp</li>
            <li>ＵＲＬ　　　　　　　：　http://www.acropass.jp/</li>
            <li>
              商品以外の必要代金　：　■送料　北海道・沖縄1,080円（税込）、その他540円（税込）です。
            </li>
            <p>
              　　　　　　　　　　　　　※商品代金10,000円（税込）以上お買上げいただきますと、送料無料となります。
              <br />
              　　　　　　　　　　　　■代金引換手数料　一律324円（税込）
              <br />
              　　　　　　　　　　　　■銀行振込の際の振込手数料
            </p>
            <li>
              注文方法　　　　　　：　インターネットでショッピングカートによるご注文
            </li>
            <li>支払方法　　　　　　：　【クレジットカード決済】</li>
            <p>
              　　　　　　　　　　　　VISA, MasterCard, JCB, AMERICAN EXPRESS,
              Diners Club International
              <br />
              　　　　　　　　　　　　【代引決済】
              <br />
              　　　　　　　　　　　　佐川急便（e-コレクト対応）
              <br />
              　　　　　　　　　　　　・代引手数料は一律324円（税込）です。
              <br />
              　　　　　　　　　　　　・お支払は、現金又はクレジットカードがご利用いただけます。
              <br />
              　　　　　　　　　　　　【銀行振込】
              <br />
              　　　　　　　　　　　　振込口座：三菱東京UFJ銀行　大久保支店
              <br />
              　　　　　　　　　　　　普通：0193850　名義：カ）ラパスジャパン
              <br />
              　　　　　　　　　　　　・お客様にお振込み手数料をご負担いただいております。
            </p>
            <li>支払期限　　　　　　：　【クレジットカード決済】</li>
            <p>
              　　　　　　　　　　　　ご利用のカード会社ごとに異なります。
              <br />
              　　　　　　　　　　　　【代金引換】
              <br />
              　　　　　　　　　　　　商品受取時に配送業者へお支払い下さい。
              <br />
              　　　　　　　　　　　　【銀行振込】
              <br />
              　　　　　　　　　　　　ご注文後7日以内にご入金ください。
              <br />
              　　　　　　　　　　　　7日以内にご入金が確認できない場合は、ご注文をキャンセル扱いとさせていただきます。
            </p>
            <li>
              引渡時期　　　　　　：　在庫のある商品はご注文確認後、翌日に発送いたします。
            </li>
            <p>
              　　　　　　　　　　　　在庫切れの場合は別途ご連絡いたします。
            </p>
            <li>
              返品・交換について　：　1.次の商品は返品をお受けできません。
            </li>
            <p>
              　　　　　　　　　　　　　　イ.お届け後11日以上経過した商品
              <br />
              　　　　　　　　　　　　　　ロ.一度ご使用になった商品
              <br />
              　　　　　　　　　　　　　　ハ.お客様が汚損または破損された商品
              <br />
              　　　　　　　　　　　　2.商品到着後、10日以内に返品できます。不良品等を除き返品の送料はお客様がご負担ください。
              <br />
              　　　　　　　　　　　　3.良品以外の商品(箱を開けたら商品が壊れていた等)や注文した商品と異なった商品が届いた場合の交換に要する送料は弊社が負担いたします。
            </p>
          </ul>
        </section>
      </div>
    </div>
  </div>
)

CommercialTransaction.propTypes = {}
CommercialTransaction.defaultProps = {}

export default CommercialTransaction

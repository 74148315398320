import React from 'react'
import { Link } from 'react-router-dom'
import { path } from 'utils/const'
import styles from './userGuide.module.scss'

const UserGuide = () => (
  <div className={styles.root}>
    <div className="container">
      <div className="container-inner">
        <h1 className={styles.title}>ご利用ガイド</h1>
        <p className={styles.description}>
          弊社ショッピングサイトをご利用いただき誠にありがとうございます。
          <br />
          こちらは会員制となっております。初めての方は、会員登録をお願いいたします。
          <br />
          また、会員登録をなさらなくてもご利用は可能です。
        </p>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>商品注文方法</h2>
          <ol className={styles.ol}>
            <li data-number="１．">
              Shopページの注文したい商品の写真をクリックしてください。
            </li>
            <li data-number="２．">
              商品詳細ページの「カートを入れる」をクリックしてください。
              <br />
              {'　　'}
              「カートに入れる」をクリックするとカート内容の確認へ移動します。
            </li>
            <li data-number="３．">
              数量を変更する場合は数量を入力し「変更」ボタンをクリックしてください。
              <br />
              {'　　'}
              買い物を続けたいお客様は、「続けて商品を見る」をクリックしてください。
            </li>
            <li data-number="４．">
              商品の確認が終わりましたら、購入者情報入力をクリックしてください。
            </li>
            <li data-number="５．">
              ご購入者情報・配送先の入力、配送方法の選択、お支払い方法の選択を入力してください。
            </li>
            <li data-number="６．">
              ご購入内容の確認画面で入力情報に誤りがないかの確認をし「注文確定」をクリックしてください。
            </li>
            <li data-number="７．">
              ご注文の完了です。
              <br />
              {'　　'}
              注文完了後、登録メールアドレス宛に注文確認メールをお送りいたします。
            </li>
          </ol>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>連絡先</h2>
          <ul className={styles.ul}>
            <li>住所　　：〒108-0014 東京都港区芝4-5-11 芝プラザビル8F</li>
            <li>企業名　：株式会社RAPHAS JAPAN</li>
            <li>電話番号：03-6806-0513</li>
            <li>E-mailアドレス：info@raphas.jp</li>
          </ul>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>配送について</h2>
          <p>
            宅急便でお届けします。
            <br />
            ご注文後、1～4日以内に発送させていただきます。
            <br />
            オンライン決済の場合は、ご入金確認がとれてからの発送となります。
          </p>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>返品のご案内</h2>
          <p>
            お客様のご都合による交換及び返品の場合、送料はお客様負担となりますのでご了承ください。
            <br />
            商品に問題があった場合の交換及び返品の場合、送料は弊社で負担させていただきます。
          </p>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>個人情報の取り扱いについて</h2>
          <Link
            to={path.privacy}
            className={`${styles.btnLink} btn btn-secondary`}
          >
            個人情報の取り扱いについて
          </Link>
          <ul className={styles.ul}>
            <li>担当　　：マーケティングチーム</li>
            <li>連絡先　：03-6806-0513</li>
            <li>E-mailアドレス：info@raphas.jp</li>
          </ul>
        </section>
      </div>
    </div>
  </div>
)

export default UserGuide

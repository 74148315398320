import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Card from 'pageComponents/Card'
import styles from './homeInformation.module.scss'

const HomeInformation = () => {
  const { notices } = useSelector((state) => state.notice)
  return (
    <section className={styles.root}>
      <div className="container">
        <div className={styles.header}>
          <h2 className={styles.title}>INFORMATION</h2>
          <Link to="/" className={`${styles.link} btn btn-secondary`}>
            お知らせ
          </Link>
        </div>
        <div className={styles.content}>
          {notices.map((notice) => (
            <Card key={notice.id} {...notice} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default HomeInformation

import React, { forwardRef } from 'react'
import DatePicker from 'react-datepicker'
import { PropTypes } from 'prop-types'
import 'react-datepicker/dist/react-datepicker.css'

const DateTimePicker = ({ date, renderInput, onChange, ...others }) => {
  const CustomTrigger = forwardRef(({ value, onClick }, ref) =>
    renderInput(value, onClick, ref),
  )
  return (
    <DatePicker
      selected={date}
      onChange={onChange}
      dateFormat="yyyy・M・d"
      showTimeSelect
      customInput={<CustomTrigger />}
      {...others}
    />
  )
}

DateTimePicker.propTypes = {
  date: PropTypes.shape({}),
  renderInput: PropTypes.func,
  onChange: PropTypes.func,
}

DateTimePicker.defaultProps = {
  date: null,
  renderInput: () => null,
  onChange: () => null,
}

export default DateTimePicker

/* eslint-disable react/no-danger */
import React from 'react'
import PropTypes from 'prop-types'
import styles from './productTable.module.scss'

const ProductTable = ({ intro, details, style, className }) => (
  <table style={style} className={`${styles.table} ${className}`}>
    <tbody>
      {intro && (
        <span
          dangerouslySetInnerHTML={{
            __html: intro,
          }}
        />
      )}
      {details.map((item, i) => (
        <tr key={i.toString()}>
          <th>{item.title}</th>
          <td>{item.content}</td>
        </tr>
      ))}
    </tbody>
  </table>
)

ProductTable.propTypes = {
  intro: PropTypes.string,
  details: PropTypes.arrayOf(PropTypes.shape({})),
  style: PropTypes.shape({}),
  className: PropTypes.string,
}

ProductTable.defaultProps = {
  intro: '',
  details: [],
  style: {},
  className: '',
}

export default ProductTable

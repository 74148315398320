import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Collapse } from 'reactstrap'
import Button from 'components/Button'
import styles from './faq.module.scss'

const categories = [
  { id: 1, title: '効果・持続性' },
  { id: 2, title: 'マイクロニードル (お肌の刺激・安全性)' },
  { id: 3, title: 'マイクロニードル (使用方法)' },
  { id: 4, title: '乳酸菌' },
  { id: 5, title: '定期便について' },
  { id: 6, title: 'KARADA ARRANGE' },
]

const faqs = [
  {
    id: 1,
    question: '使用するタイミングは？どれくらい使い続けるといいの？',
    answer:
      '最初の１～２か月は週２回または、３日に１回の割合でお使いいただくことをおすすめします。\nそのあとは、継続的に週１～２回お使いいただくと効果が維持されます。',
    category: {
      id: 1,
      name: '効果・持続性',
    },
  },
  {
    id: 2,
    question:
      '１回使うと効果があると書いてあるけれど、それはどのくらい続くの？',
    answer:
      '美容成分だけで形成されたマイクロニードルは、効率よくお肌に成分を届ける為、１回のご使用でも効果は感じられます。効果をより実感していただくためには、気になる部分に集中して継続いただくことをおすすめします。',
    category: {
      id: 1,
      name: '効果・持続性',
    },
  },
  {
    id: 3,
    question: 'ACROPASSの効能は？',
    answer:
      '主成分となっているヒアルロン酸は、人の肌に元々あり、お肌の潤いを保ち、ハリや弾力を与える成分として知られています。お肌悩みに応じた製品をご用意しておりますので、ぜひお試しください。',
    category: {
      id: 1,
      name: '効果・持続性',
    },
  },
  {
    id: 4,
    question: '敏感な目元に貼った時、赤くなったり刺激が残ったりしない？',
    answer:
      'アクロパスのマイクニードルは髪の毛の1/4の細さでお肌に浸透します。貼ってから30分以内に剥がすと 赤い斑点が見えるかもしれませんが、これはまさにマイクロ構造体がお肌に届いたことを示すものです。 2時間以上経ってから剥がしていただくと、赤い斑点は消えているはずです。 また、睡眠習慣による枕の跡のような跡がつくことがありますが、すぐに消えますのでご安心ください。',
    category: {
      id: 2,
      name: 'マイクロニードル (お肌の刺激・安全性)',
    },
  },
  {
    id: 5,
    question: 'パッチを貼ったときに痛みはないですか？',
    answer:
      'パッチを真上から押すように貼っていただきますと若干チクチクと感じますが、痛みと言う程ではございません。美容成分をしっかり届けるので、乾燥による小じわが気になる目じりやほうれい線、眉間や首などにお使いください。 ※痛みについては個人差がございます。',
    category: {
      id: 2,
      name: 'マイクロニードル (お肌の刺激・安全性)',
    },
  },
  {
    id: 6,
    question: '肌に赤みやかゆみなどがあった場合はどうしたらいいでしょうか？',
    answer:
      'まれに美容成分が合わないまたは、お肌のバリア機能低下により症状が出た場合は、速やかにパッチを剥がし、ぬるま湯で洗い流し、ご自身でいつも使用されていてお肌に合った化粧品等で保湿をしてください。それでも改善しない場合は、皮膚科への受診をお薦め致します。',
    category: {
      id: 2,
      name: 'マイクロニードル (お肌の刺激・安全性)',
    },
  },
  {
    id: 7,
    question: 'どのタイミングで使うのが効果的？',
    answer:
      '当社のマイクロニードルは、水分に触れると溶ける性質を持っています。洗顔後、水気を十分にふき取ってからご使用頂く事をおすすめ致します。また、軽くお肌を整えた後（化粧水・乳液程度）にもお使い頂けますが、この時、化粧水等の水分がお肌にきちんと吸収されて乾いた状態でご使用下さい。 状況によっては、ティッシュオフしてからお使いいただくこともよろしいかと存じます。',
    category: {
      id: 3,
      title: 'マイクロニードル (使用方法)',
    },
  },
  {
    id: 8,
    question: 'マイクロニードルの上手は貼り方は？',
    answer:
      'ACROPASSは有効成分自体がとても微細なマイクロ構造体に配合されています。その為、貼った後何度も上から押すことでマイクロニードルの美容成分がお肌に届きます。 この時に、パッチの上から指で横になぞるように押さえてしまうと、マイクロニードルがお肌に届く前に曲がってしまい、効果が正しく得られないことがあります。押さえるときは上からまっすぐ押すようにしてください。',
    category: {
      id: 3,
      title: 'マイクロニードル (使用方法)',
    },
  },
  {
    id: 9,
    question: '昼間でも使えますか？',
    answer:
      '昼夜いつでもご使用いただけます。 ただ、美容成分をしっかりと届け、効果を十分に発揮するのに約２時間かかるため、就寝時にお使いいただくことをお勧めしております。 昨今のマスク着用が当たり前の毎日ですので、マスク中に貼っていただくこともお薦めしております。',
    category: {
      id: 3,
      title: 'マイクロニードル (使用方法)',
    },
  },
  {
    id: 10,
    question:
      'パッチを貼った後、顔のそのほかの部分にエッセンスやクリームなどのスキンケアをしても大丈夫？',
    answer:
      'はい、大丈夫です。 当社マイクロニードルパッチは、集中ケアが必要な部分にお使いいただき、それ以外の部分はパッチを貼ったまま通常通りのお手入れをしてください。',
    category: {
      id: 3,
      title: 'マイクロニードル (使用方法)',
    },
  },
  {
    id: 11,
    question:
      'パッチの有効期間はどれくらい？パウチ袋を開封後いつまで使えるの？',
    answer:
      'パッチの有効期間は３年間ですが、パウチを開封した後はその日のうちにお使いください。 マイクロニードルは水分保湿因子であるヒアルロン酸を基本に作られているので、開封後時間が経つと空気中の水分を吸収して、溶けたり、ニードルの強度が弱くなってしまい、十分は効果が発揮できない可能性があります。',
    category: {
      id: 3,
      title: 'マイクロニードル (使用方法)',
    },
  },
  {
    id: 12,
    question: '睡眠中に貼ったパッチが剥がれることは？',
    answer:
      '貼った場所や睡眠習慣によっては剥がれることも考えられます。一般的には睡眠中も剥がれずしっかり付着しています。',
    category: {
      id: 3,
      title: 'マイクロニードル (使用方法)',
    },
  },
  {
    id: 13,
    question: 'エンテロコッカス・フェカリス菌って何ですか？',
    answer:
      '人の腸管に生息している球形をした腸内細菌の一種で、腸内細菌叢の改善、アトピー性皮膚炎や花粉症改善効果、内臓脂肪低減効果、カンジダ菌抑制など、人の健康維持に大きく関与していることが確認できている乳酸菌です。',
    category: {
      id: 4,
      title: '乳酸菌',
    },
  },
  {
    id: 14,
    question:
      '乳酸菌（エンテロコッカス・フェカリス菌）は健康のためにたくさん摂ったほうがいいですか？',
    answer:
      'ヒト臨床試験では、1日当たり乳酸菌1兆個～２兆個を2週間以上の連続摂取で効果が確認できています。 乳酸菌をたくさん摂りすぎても排泄されてしまうため、毎日１兆個～2兆個を目安として摂取することをお勧めいたします。',
    category: {
      id: 4,
      title: '乳酸菌',
    },
  },
  {
    id: 15,
    question:
      '乳酸菌が含まれた「カラダアレンジ」商品は子供も食べていいですか？',
    answer:
      '成人（18歳以上）向けに開発した商品ですので、子供の摂取はお控えください。',
    category: {
      id: 4,
      title: '乳酸菌',
    },
  },
  {
    id: 16,
    question:
      '乳酸菌が含まれた「カラダアレンジ」商品は妊娠中や授乳中に食べてもいいですか？',
    answer: '妊娠中・授乳中の方のご利用はお控えください。',
    category: {
      id: 4,
      title: '乳酸菌',
    },
  },
  {
    id: 17,
    question:
      '乳酸菌が含まれた「カラダアレンジ」商品はいつ食べるのが効果的ですか？',
    answer:
      '腸が活発に活動する夜に合わせて就寝２、３時間前にお召し上がりいただくことをお勧めいたします。',
    category: {
      id: 4,
      title: '乳酸菌',
    },
  },
  {
    id: 18,
    question:
      '乳酸菌が含まれた「カラダアレンジ」商品はペットに食べさせてもいいですか？',
    answer:
      'ペット用サプリメントではないのでペットに与えることはお控えください。',
    category: {
      id: 4,
      title: '乳酸菌',
    },
  },
  {
    id: 19,
    question: '次回定期便の購入の停止はいつまでですか？',
    answer:
      'お届け予定日の10日前までにメールにて「解約希望」をご連絡をお願い致します。',
    category: {
      id: 5,
      title: '定期便について',
    },
  },
  {
    id: 20,
    question: '定期便の場合、最長どのくらいのお休みできますか？',
    answer:
      '現在のところ、一時休止を承っておりません。誠に恐れ入りますが、購入停止のお手続きと同様に、 お届け予定日の10日前までにメールにて「解約」をご連絡をお願い致します。',
    category: {
      id: 5,
      title: '定期便について',
    },
  },
  {
    id: 21,
    question: '退会したい場合、どうしたらよいでしょうか？',
    answer:
      'お届け予定日の10日前までにメールにて「退会希望」の旨をご連絡お願い致します。',
    category: {
      id: 5,
      title: '定期便について',
    },
  },
  {
    id: 22,
    question: '回数の縛りはありますか？',
    answer: '現在のところ定期便のお受け取り回数の縛りはございません。',
    category: {
      id: 5,
      title: '定期便について',
    },
  },
  {
    id: 23,
    question: 'いつ食べるのが効果的ですか？',
    answer:
      '弊社の商品は医薬品ではなく食品ですので、摂取に関する特別な決まりはありませんが、空腹時を避けて就寝２、３時間までに召し上がりいただくことをお勧めいたします。',
    category: {
      id: 6,
      title: 'KARADA ARRANGE',
    },
  },
  {
    id: 24,
    question: '一日の目安量はどのくらいですか？',
    answer:
      '商品によって一日の摂取目安量が異なります。一度にたくさん摂取されても、働きが高まったり、より健康が増進したりするものではございませんので、商品に記載している一日の目安量内での摂取をお願いします。',
    category: {
      id: 6,
      title: 'KARADA ARRANGE',
    },
  },
  {
    id: 30,
    question: 'どのくらいの期間摂取するとよいですか?',
    answer:
      'サプリメントは、おだやかなはたらきかけであり、相性により実感には個人差があります。日々の体調管理のサポートとしてまずは3カ月程度を目安に継続し、様子をみることをおすすめします。また、継続する期間に制限はなく、実感のある商品は長期継続いただき差し支えありません。',
    category: {
      id: 6,
      title: 'KARADA ARRANGE',
    },
  },
  {
    id: 25,
    question: '子供が食べても良いですか？',
    answer:
      '弊社の商品は成人（18歳以上）向けに開発した商品ですので、子供の摂取はお控えください。',
    category: {
      id: 6,
      title: 'KARADA ARRANGE',
    },
  },
  {
    id: 26,
    question: '妊娠中や授乳中に食べてもいいですか？',
    answer: '妊娠中・授乳中の方は、主治医に必ずご相談ください。',
    category: {
      id: 6,
      title: 'KARADA ARRANGE',
    },
  },
  {
    id: 27,
    question: '薬と一緒に飲んでも大丈夫ですか？',
    answer:
      '薬との併用については、事前に必ず医師または薬剤師にご相談ください。',
    category: {
      id: 6,
      title: 'KARADA ARRANGE',
    },
  },
  {
    id: 28,
    question: '	食品アレルギーの心配がある成分はありますか？',
    answer:
      '食品アレルギーの心配がある方は、各商品パッケージや弊社公式ホームページに記載しております原材料名をご確認ください。',
    category: {
      id: 6,
      title: 'KARADA ARRANGE',
    },
  },
  {
    id: 29,
    question: 'ペットに食べさせてもいいですか？',
    answer:
      '弊社の商品はヒト用であり、ペット用サプリメントではないのでペットに与えることはお控えください。',
    category: {
      id: 6,
      title: 'KARADA ARRANGE',
    },
  },
]

const Category = ({ title, isActive, onClick }) => (
  <li>
    <Button
      className={`${styles.btnCategory} btn ${
        isActive ? 'btn-secondary' : 'btn-outline-secondary'
      }`}
      onClick={onClick}
    >
      {title}
    </Button>
  </li>
)

const FaqItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = () => setIsOpen((prevState) => !prevState)

  return (
    <li className={styles.faqItem}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        role="button"
        tabIndex={0}
        className={`${styles.question} ${isOpen ? styles.active : ''}`}
        onClick={handleClick}
      >
        <h2 className={styles.questionText}>{question}</h2>
        <div className={styles.toggle} />
      </div>

      <Collapse isOpen={isOpen} className={styles.answer}>
        <p>{answer}</p>
      </Collapse>
    </li>
  )
}

const Faq = () => {
  const [currentCategory, setCurrentCategory] = useState(categories[0].id)

  return (
    <>
      <div className={styles.section}>
        <div className="container">
          <h1 className={styles.title}>よくあるご質問</h1>
          {/* Categories */}
          <div className={styles.categoriesWrap}>
            <ul className={styles.categories}>
              {categories.map((category) => (
                <Category
                  key={category.id}
                  title={category.title}
                  isActive={category.id === currentCategory}
                  onClick={() => setCurrentCategory(category.id)}
                />
              ))}
            </ul>
          </div>
          {/* FAQs */}
          <ul className={styles.content}>
            {faqs
              .filter(({ category }) => category.id === currentCategory)
              .map((faq) => (
                <FaqItem key={faq.id} {...faq} />
              ))}
          </ul>
        </div>
      </div>
    </>
  )
}

Category.propTypes = {
  title: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
}

Category.defaultProps = {
  title: '',
  isActive: false,
  onClick: () => {},
}

FaqItem.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.string,
}

FaqItem.defaultProps = {
  question: '',
  answer: '',
}

export default Faq

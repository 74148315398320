import React from 'react'
import PropTypes from 'prop-types'
import './textarea.scss'

const Textarea = ({
  label,
  name,
  value,
  required,
  badge,
  placeholder,
  onChange,
  onKeyDown,
  maxLength,
  className,
  style,
  error,
  disabled,
  ...others
}) => (
  /* eslint-disable jsx-a11y/label-has-associated-control */
  <label className={className} style={style}>
    {(label || badge) && (
      <span className="input-label">
        {badge && required && (
          <span className="input-badge input-badge-required">必須</span>
        )}
        {badge && !required && (
          <span className="input-badge input-badge-optional">任意</span>
        )}
        {label}
      </span>
    )}
    <div>
      <textarea
        value={value}
        name={name}
        required
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        maxLength={maxLength}
        // className={`textarea ${
        //   error ? 'form-control is-invalid' : 'form-control'
        // }`}
        className="textarea form-control"
        disabled={disabled}
        {...others}
      />
      {/* {error && <span className="invalid-feedback">{error}</span>} */}
      <span className="invalid-feedback">{error}</span>
    </div>
  </label>
)

Textarea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  badge: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  maxLength: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  error: PropTypes.string,
  disabled: PropTypes.bool,
}

Textarea.defaultProps = {
  label: '',
  name: '',
  value: '',
  required: false,
  badge: false,
  placeholder: '',
  onChange: () => {},
  onKeyDown: () => {},
  maxLength: '',
  className: '',
  style: {},
  error: '',
  disabled: false,
}

export default Textarea

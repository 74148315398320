import prodImages from 'assets/images/products'
import images from 'assets/images'

export default {
  id: 26,
  name: 'インフルバリアのど飴',
  images: prodImages.fluCandy.slides.map((image, i) => ({
    id: i + 1,
    src: image,
  })),
  illustration: images.karadaArrangeProductDetails,
  lpImage: null,
  intro:
    'インフルバリア®のど飴は、現在市販されている飴類とは異なり、特許製法によりのどから侵入するウイルスの感染を抑える層と\n 栄養機能成分であるビタミンCの２層の組み合わせによる機能性食品素材を含有したのど飴です。\n 1粒中に栄養機能成分として1日の摂取表示基準値46%相当のビタミンC37mgを配合しております。\n お子様からお年寄りまで、通勤通学、外出時の健康管理にお役立てください。',
  details: [
    {
      title: '商品名',
      content: 'インフルバリアのど飴',
    },
    {
      title: 'メーカー',
      content: '株式会社 RAPHAS JAPAN / ラパス ジャパン',
    },
    {
      title: '用途',
      content:
        '・のどから侵入するウイルスの感染を抑える部分（コロカリア）と栄養機能成分（ビタミンＣ）を含んでいる部分の2色の組み合わせからなるサプリメント機能を有したのど飴\n ・加齢と共に唾液の分泌量が減少したり、ストレスがかかった状態や緊急時には、のどがカラカラになり、悪玉菌に感染し易くなりますが、コロカリアにはローヤルゼリーの200倍のシアル酸が含まれているので、ウイルスの抑制が期待できます\n ・インフルバリアのど飴の有効成分コロカリアは、アナツバメの巣に含まれるシアル酸を独自の酵素処理技術により、体内への吸収性と有効性を高めた素材です ',
    },
    {
      title: 'JAN',
      content: '4562272610210',
    },
    {
      title: '容量',
      content: '50g （5g × 10粒）（個包装込）',
    },
    {
      title: '成分',
      content:
        '砂糖、水飴、酵素処理燕窩（デキストリン・燕窩）、オレンジ濃縮果汁・ビタミンC、香料、パプリカ色素',
    },
    {
      title: '製造国',
      content: '日本',
    },
    {
      title: '使用の目安',
      content:
        'かまずに口の中でゆっくりと唾液に溶かしながらなめてください。有効成分をのどに付着させるようになめていただくとより効果的です。',
    },
    {
      title: '使用上のご注意',
      content: '開封後はなるべく早くお召し上がりください。',
    },
  ],
  price: {
    amount: '¥ 648',
    tax: '（税込）',
  },
}

import React, { useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import useMe from 'hooks/useMe'
import { useHistory, useLocation } from 'react-router-dom'
import PageHeader from 'pageComponents/PageHeader'
import Select from 'components/Select'
import Dropzone from 'components/Dropzone'
import Input from 'components/Input'
import RichTextEditor from 'components/RichTextEditor'
import DatePicker from 'components/DatePicker'
import { actions } from 'slices/notice.slice'
import options, { findOption } from 'utils/options'
import styles from './noticeUpdate.module.scss'

// ------------------------------------
// Constants
// ------------------------------------

const FORM_ID = 'NOTICE_UPDATE_FORM'
const DELETE_CONFIRM_MESSAGE =
  'お知らせを削除してもよろしいですか? 削除後、2度と復元できません'

// ------------------------------------
// Page
// ------------------------------------

const NoticeUpdate = ({ className, style }) => {
  const history = useHistory()
  const location = useLocation()
  const id = location?.state?.id
  const { isReadonly } = useMe()

  // ------------------------------------
  // Stats
  // ------------------------------------

  const [inputs, setInputs] = useState({
    image_url: '',
    file: null,
    category: options.categories[0],
    status: options.statuses[0],
    title: '',
    content: '',
    published_at: new Date(),
  })
  const [errors, setErrors] = useState({})
  const [isLoading, setLoading] = useState(false)
  const [isValidated, setIsValidated] = useState(false)

  // ------------------------------------
  // Handlers
  // ------------------------------------

  const handleInputChange = ({ target: { name, value } }) => {
    setInputs((prev) => ({ ...prev, [name]: value }))
    setErrors((prev) => ({ ...prev, [name]: '' }))
  }

  const handleSubmit = async (e) => {
    let hasError = false
    setErrors({})
    e.preventDefault()
    setIsValidated(true)

    // check thumbnail
    if (!id && !inputs?.file) {
      setErrors((prev) => ({ ...prev, file: 'サブネイルを指定してください' }))
      hasError = true
    }

    // check content
    if (!inputs?.content.replace(/<\/?[^>]+(>|$)/g, '')) {
      setErrors((prev) => ({ ...prev, content: '本文を追加してください' }))
      hasError = true
    }

    const form = e.currentTarget
    // eslint-disable-next-line no-useless-return
    if (!form.checkValidity() || hasError) return

    try {
      setLoading(true)
      if (id) await actions.updateNotice({ id, ...inputs })
      else await actions.createNotice(inputs)
      history.goBack()
      setLoading(false)
    } catch (_) {
      setLoading(false)
    }
  }

  const handleDelete = () => {
    if (window.confirm(DELETE_CONFIRM_MESSAGE)) {
      setLoading(true)
      actions
        .deleteNotice(id)
        .then(() => {
          history.goBack()
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }

  // ------------------------------------
  // Dependencies
  // ------------------------------------

  useEffect(() => {
    if (id) {
      setLoading(true)
      actions
        .getNotice(id)
        .then((res) => {
          setInputs({
            image_url: res?.image_url,
            file: null,
            category: findOption(options.categories, res?.category),
            status: findOption(options.statuses, res?.status),
            title: res?.title,
            content: res?.content,
            published_at: new Date(res?.published_at),
          })
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }, [id])

  // ------------------------------------
  // Renderings
  // ------------------------------------

  const operation = id
    ? { title: '更新', icon: 'pen' }
    : { title: '保存', icon: 'plus' }
  const buttons = id
    ? [
        {
          text: '削除',
          icon: 'trash',
          onClick: handleDelete,
          style: { background: 'red', border: 'none' },
        },
        {
          form: FORM_ID,
          text: operation.title,
          icon: operation.icon,
        },
      ]
    : [
        {
          form: FORM_ID,
          text: operation.title,
          icon: operation.icon,
        },
      ]

  return (
    <div className={`${styles.root} ${className}`} style={style}>
      <PageHeader
        title={`お知らせの${operation.title}`}
        isLoading={isLoading}
        buttons={!isReadonly && buttons}
      />

      <form
        id={FORM_ID}
        noValidate
        className={`${styles.form} ${isValidated ? 'was-validated' : ''}`}
        onSubmit={handleSubmit}
      >
        <div className={styles.container}>
          <Dropzone
            label="サブネイル"
            file={inputs.file}
            imageUrl={inputs.image_url}
            setFile={(file) =>
              handleInputChange({ target: { name: 'file', value: file } })
            }
            error={errors?.file}
            disabled={isReadonly}
          />
        </div>
        <div className={styles.container}>
          <div className={styles.rowContainer}>
            <Select
              label="カテゴリー"
              name="category"
              options={options.categories}
              value={inputs.category}
              onChange={handleInputChange}
              className={styles.select}
              disabled={isReadonly}
              required
            />
            <Select
              label="ステータス"
              name="status"
              options={options.statuses}
              value={inputs.status}
              onChange={handleInputChange}
              className={styles.select}
              disabled={isReadonly}
              required
            />
          </div>
          <DatePicker
            date={inputs.published_at}
            onChange={(value) =>
              handleInputChange({ target: { name: 'published_at', value } })
            }
            renderInput={(value, onClick, ref) => (
              <Input
                label="日付"
                ref={ref}
                key="published_at"
                name="published_at"
                value={value}
                onClick={onClick}
                className={styles.input}
                pattern="^(.|\s)*\S(.|\s)*$" // non-blank and non-empty string
                error="日付を入力してください。"
                disabled={isReadonly}
                required
              />
            )}
          />
          <Input
            label="タイトル"
            name="title"
            placeholder="記事タイトル"
            value={inputs.title}
            onChange={handleInputChange}
            className={`${styles.input} ${styles.title}`}
            pattern="^(.|\s)*\S(.|\s)*$" // non-blank and non-empty string
            error="タイトルを入力してください。"
            disabled={isReadonly}
            required
          />
          <RichTextEditor
            label="本文"
            name="content"
            value={inputs.content}
            defaultValue={inputs.content}
            className={styles.richEditor}
            onChange={handleInputChange}
            error={errors.content}
            disabled={isReadonly}
            required
          />
        </div>
      </form>
    </div>
  )
}

NoticeUpdate.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
}

NoticeUpdate.defaultProps = {
  className: '',
  style: {},
}

export default NoticeUpdate

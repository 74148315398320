import prodImages from 'assets/images/products'
import images from 'assets/images'

export default {
  id: 5,
  name: 'うるおいチャージ Line Care',
  images: prodImages.lineCare.slides.map((image, i) => ({
    id: i + 1,
    src: image,
  })),
  illustration: images.acropassProductDetails,
  lpImage: (
    <>
      <img
        src={prodImages.lineCare.lps?.[0]}
        style={{ width: '100%' }}
        alt="lp1"
      />
      <img
        src={prodImages.lineCare.lps?.[1]}
        style={{ width: '100%' }}
        alt="lp2"
      />
      <img
        src={prodImages.lineCare.lps?.[2]}
        style={{ width: '100%' }}
        alt="lp3"
      />
      <img
        src={prodImages.lineCare.lps?.[3]}
        style={{ width: '100%' }}
        alt="lp4"
      />
    </>
  ),
  intro:
    '韓国でも大人気のアクロパス 「ライン ケア」パッチがついにパワーアップリニューアル！！！\n DENマイクロニードル技術から誕生したパッチ型化粧品「アクロパス ラインケアパッチ」は、\n 保湿成分の高濃度プレミアムヒアルロン酸を2種類配合したマイクロニードルがお肌の角質層まで美肌成分を届けます。\n 化粧が崩れやすい目元や鼻回り、口元を高濃度ヒアルロン酸で水分を埋め尽くし、\n アスコルビルグルコシドとコラーゲン配合によりお肌にハリとつやを与え輝きに満ちた健やかな肌に導きます\n 化粧のノリが気になる時！特別な日の前日に！貼って寝るだけの集中ケア！',
  details: [
    {
      title: '商品名',
      content: 'アクロうるおいチャージLCケア',
    },
    {
      title: 'メーカー',
      content: '株式会社 RAPHAS JAPAN / ラパス ジャパン',
    },
    {
      title: '用途',
      content:
        '目元・目尻・ほうれい線・おでこなどにご使用いただけるマルチなパッチです。',
    },
    {
      title: 'JAN',
      content: '4573161350353',
    },
    {
      title: '容量',
      content: '１箱２パウチ入り　※1パウチ１回分（2枚）',
    },
    {
      title: '成分',
      content:
        'ヒアルロン酸Ｎａ、PVP、アスコルビルグルコシド、加水分解コラーゲン、アセチルオクタペプチド-３',
    },
    {
      title: '製造国',
      content: '韓国',
    },
    {
      title: '使用の目安',
      content:
        '週１～２回（使い始めの２ヶ月は週２回、３ヵ月目以降は週１回）　※2時間以上の付着をお薦めします。\n※ご使用感には個人差がございます。',
    },
    {
      title: '使用上のご注意',
      content:
        '・水分に触れると表面のヒアルロン酸が溶けてしまいますので、必ず水分や油分をふき取った部位に、乾いた手でお貼りください。 \n・傷、はれもの、湿疹などがある部位にはお使いにならないでください。 \n・使用中、または使用後に赤み、はれ、かゆみや刺激などがあらわれた時は、すぐにご使用を中止してください。',
    },
  ],
  price: {
    amount: '¥ 2,420',
    tax: '（税込）',
  },
}

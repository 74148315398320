import React from 'react'
import PropTypes from 'prop-types'
import './input.scss'

const Input = ({
  label,
  type,
  name,
  value,
  required,
  badge,
  placeholder,
  onChange,
  onKeyDown,
  maxLength,
  size,
  className,
  style,
  error,
  disabled,
  ...others
}) => (
  /* eslint-disable jsx-a11y/label-has-associated-control */
  <label className={className} style={style}>
    {(label || badge) && (
      <span className={`input-label ${size && `input-label-${size}`}`}>
        {badge && required && (
          <span className="input-badge input-badge-required">必須</span>
        )}
        {badge && !required && (
          <span className="input-badge input-badge-optional">任意</span>
        )}
        {label}
      </span>
    )}
    <div>
      <input
        value={value}
        type={type}
        name={name}
        required={required}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        maxLength={maxLength}
        className={`input form-control ${size && `form-control-${size}`}`}
        disabled={disabled}
        {...others}
      />
      <span className="invalid-feedback">{error}</span>
    </div>
  </label>
)

Input.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  badge: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  maxLength: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  error: PropTypes.string,
  disabled: PropTypes.bool,
}

Input.defaultProps = {
  type: 'text',
  label: '',
  name: '',
  value: '',
  required: false,
  badge: false,
  placeholder: '',
  onChange: () => {},
  onKeyDown: () => {},
  maxLength: '150',
  size: '',
  className: '',
  style: {},
  error: '',
  disabled: false,
}

export default Input

import prodImages from 'assets/images/products'
import images from 'assets/images'

export default {
  id: 27,
  name: '強力乳酸菌あめ',
  images: prodImages.lacticCandy.slides.map((image, i) => ({
    id: i + 1,
    src: image,
  })),
  illustration: images.karadaArrangeProductDetails,
  lpImage: null,
  intro:
    '1粒に1000億個のnanoECF-9乳酸菌とオリゴ糖180㎎を配合したキャンディーです。\n サプリメントに引けを取らない菌数を配合し、顆粒や錠剤が苦手な方でも乳酸菌を手軽にとっていただけます。\n 飽きの来ないほんのり爽やかいちごヨーグルト味で便利で清潔な個包装タイプ。\n ご家族やご友人、みんなでお楽しみください。',
  details: [
    {
      title: '商品名',
      content: '強力乳酸菌あめ',
    },
    {
      title: 'メーカー',
      content: '株式会社 RAPHAS JAPAN / ラパス ジャパン',
    },
    {
      title: '用途',
      content:
        '・１粒に乳酸菌1000億個配合したサプリメントキャンディー\n ・サプリメントに引けを取らない菌数を配合\n ・便利で清潔な個包装タイプで家族みんなで携帯\n ・キャンディーなので錠剤や顆粒が苦手な方も安心\n ・飽きの来ない、ほんのり爽やかな、いちごヨーグルト味\n ・整腸作用が認められているガラクトオリゴ糖を使用',
    },
    {
      title: 'JAN',
      content: '4562272610241',
    },
    {
      title: '容量',
      content: '37g（個装紙込み）',
    },
    {
      title: '成分',
      content: '乳酸菌末(国内製造)、オリゴ糖',
    },
    {
      title: '製造国',
      content: '日本',
    },
    {
      title: '使用の目安',
      content: '1日２～３粒を目安にお召し上がりください。',
    },
    {
      title: '使用上のご注意',
      content: '開封後はなるべく早くお召し上がりください。',
    },
  ],
  price: {
    amount: '¥ 724',
    tax: '（税込）',
  },
}

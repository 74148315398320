import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Input from 'components/Input'
import Textarea from 'components/Textarea'
import Button from 'components/Button'
import InquiryComplete from 'pageComponents/InquiryComplete'
import { actions } from 'slices/contact.slice'
import styles from './contact.module.scss'

const FOR_INDIVIDUAL = 'FOR_INDIVIDUAL'
const FOR_COMPANY = 'FOR_COMPANY'
const initialState = {
  name: '',
  companyName: '',
  phone: '',
  email: '',
  subject: '',
  orderId: '',
  message: '',
}

const Contact = () => {
  const dispatch = useDispatch()
  const [type, setType] = useState(FOR_INDIVIDUAL)
  const [inputs, setInputs] = useState(initialState)
  const [isValidated, setIsValidated] = useState(false)
  const [isCompleted, setCompleted] = useState(false)
  const [isProcessing, setProcessing] = useState(false)
  const [resError, setResError] = useState(null)

  const toggleType = () =>
    setType((prev) => (prev === FOR_INDIVIDUAL ? FOR_COMPANY : FOR_INDIVIDUAL))
  const toggleCompleted = () => setCompleted((prev) => !prev)

  const handleChange = ({ target: { name, value } }) =>
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }))

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsValidated(true)

    const form = e.currentTarget
    // eslint-disable-next-line no-useless-return
    if (!form.checkValidity()) return

    try {
      setResError(null)
      setProcessing(true)
      await dispatch(
        actions.sendInquiry({
          type,
          ...inputs,
        }),
      )
      toggleCompleted(true)
      setIsValidated(false)
      setProcessing(false)
      setInputs(initialState)
    } catch (err) {
      setProcessing(false)
      setResError(
        '問い合わせ情報の送信に失敗しました。お手数ですが、お時間を置いて再度お試しください。',
      )
    }
  }

  return (
    <div className={styles.root}>
      <div className="container">
        <div className="container-inner">
          <h1 className={styles.title}>お問い合わせ</h1>
          <p className={styles.description}>
            お問い合わせ内容をご入力ください。
            {type === FOR_COMPANY ? '個人' : '法人'}のお客様は、
            <Button className={styles.button} onClick={toggleType}>
              こちら
            </Button>
            からお問い合わせください。
          </p>
          <form
            noValidate
            className={`${styles.form} ${isValidated ? 'was-validated' : ''}`}
            onSubmit={handleSubmit}
          >
            <Input
              label="お名前"
              name="name"
              type="text"
              pattern="^(.|\s)*\S(.|\s)*$" // non-blank and non-empty string
              value={inputs.name}
              required
              badge
              error="お名前を入力してください。"
              className={styles.inputWrap}
              onChange={handleChange}
            />
            {type === FOR_COMPANY && (
              <Input
                label="法人名"
                name="companyName"
                type="text"
                pattern="^(.|\s)*\S(.|\s)*$" // non-blank and non-empty string
                value={inputs.companyName}
                required
                badge
                error="お名前を入力してください。"
                className={styles.inputWrap}
                onChange={handleChange}
              />
            )}
            <Input
              label="電話番号"
              name="phone"
              type="tel"
              pattern="^[\+]?([0-9]{1,3}[-\s\.]?)?[(]?[0-9]{1,5}[)]?[-\s\.]?[0-9]{1,4}[-\s\.]?[0-9]{4}$"
              value={inputs.phone}
              badge
              error="有効な電話番号を入力してください。"
              className={styles.inputWrap}
              onChange={handleChange}
            />
            <Input
              label="メールアドレス"
              name="email"
              type="email"
              value={inputs.email}
              required
              badge
              error="有効なメールアドレスを入力してください。"
              className={styles.inputWrap}
              onChange={handleChange}
            />
            <Input
              label="件名"
              name="subject"
              type="text"
              pattern="^(.|\s)*\S(.|\s)*$" // non-blank and non-empty string
              value={inputs.subject}
              required
              badge
              error="件名を入力してください。"
              className={styles.inputWrap}
              onChange={handleChange}
            />
            {type === FOR_INDIVIDUAL && (
              <Input
                label="注文ID"
                name="orderId"
                type="text"
                value={inputs.orderId}
                badge
                error="有効な注文IDを入力してください。"
                className={styles.inputWrap}
                onChange={handleChange}
              />
            )}
            <Textarea
              label="お問い合わせ内容"
              name="message"
              value={inputs.message}
              required
              badge
              error="お問い合わせ内容を入力してください。"
              className={styles.inputWrap}
              onChange={handleChange}
            />
            <div className={styles.btnSubmitWrap}>
              <Button
                type="submit"
                label="送信する"
                className="btn btn-lg btn-secondary"
                isLoading={isProcessing}
              />
            </div>
            {resError && <p className={styles.error}>{resError}</p>}
          </form>
        </div>
      </div>
      <InquiryComplete isOpen={isCompleted} toggle={toggleCompleted} />
    </div>
  )
}

export default Contact

import React, { useRef, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getHeaderHeight } from 'slices/status.slice'
import { Link } from 'react-router-dom'
import { path } from 'utils/const'
import Button from 'components/Button'
import Icon from 'components/Icon'
// import config from 'utils/config'
import styles from './header.module.scss'
import MainNav from './MainNav'
import SearchForm from './SearchForm'

const Header = () => {
  // hide header if in the admin pages
  if (window.location.href.includes(path.admin)) return null

  const dispatch = useDispatch()
  const ref = useRef(null)

  // const notification =
  //   '緊急事態宣言に伴うお知らせ ― この帯は重要なお知らせがあるときのみ表示します。'
  const notification = ''

  const [isOpen, setIsOpen] = useState(false)

  const handleClick = () => setIsOpen((prevState) => !prevState)

  useEffect(() => {
    // Get header height on load & on header resize (without ResizeObserver, offsetHeight returns 0)
    const resizeObserver = new ResizeObserver((entries) =>
      dispatch(getHeaderHeight(entries[0].target.offsetHeight)),
    )
    resizeObserver.observe(ref.current)
  }, [])

  return (
    <header ref={ref} className={styles.root}>
      {notification && (
        <div className={styles.notification}>
          <Link to="/">{notification}</Link>
        </div>
      )}

      <nav className={styles.navbar}>
        {/* Logo */}
        <Link
          to={path.home}
          className={styles.logo}
          onClick={() => setIsOpen(false)}
        >
          <Icon name="logo-bihada-factory" className="fill-reset" />
        </Link>

        {/* Main Navigation */}
        <MainNav isOpen={isOpen} onClick={handleClick} />

        <div className={styles.navItems}>
          {/* Search form */}
          <SearchForm className={styles.searchForm} />

          {/* <Button
            className={styles.btnAction}
            onClick={() => {
              window.location.href = `${config.app.shopUrl}/mypage/login.php`
            }}
          >
            <Icon name="user" />
          </Button> */}
          <Button
            className={styles.btnAction}
            onClick={() => {
              window.location.href = `https://shop.acropass.jp/shops/acropass/checkout/edit`
            }}
          >
            <Icon name="bag" />
          </Button>

          {/* Toggle button */}
          <Button
            className={`${styles.btnToggle} ${isOpen ? styles.active : ''}`}
            onClick={handleClick}
          />
        </div>

        {/* <MainNav isOpen={isOpen} onClick={handleClick} /> */}
      </nav>
    </header>
  )
}

export default Header

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { path } from 'utils/const'
import Icon from 'components/Icon'
import styles from './mainNav.module.scss'
import SearchForm from '../SearchForm'

const navMenu = [
  {
    id: 1,
    title: '美肌factoryストーリー',
    titleLink: path.bihadaFactory,
    icon: 'cosmetic',
    menu: [],
  },
  {
    id: 2,
    title: 'ACROPASS',
    titleLink: '',
    icon: 'gem',
    menu: [
      {
        subtitle: '',
        subtitleLink: '',
        content: [
          { text: 'ブランドストーリー', link: path.acropassBrandStory },
          { text: 'マイクロニードル', link: path.acropassMicroneedle },
        ],
      },
    ],
  },
  {
    id: 3,
    title: 'KARADA ARRANGE',
    titleLink: '',
    icon: 'bubbles',
    menu: [
      {
        subtitle: '',
        subtitleLink: '',
        content: [
          { text: 'ブランドストーリー', link: path.karadaArrangeBrandStory },
          { text: '乳酸菌', link: path.karadaArrangeLacticAcidBacteria },
        ],
      },
    ],
  },
  {
    id: 4,
    title: 'オンラインモール',
    titleLink: '',
    icon: 'bag',
    externalLink: 'https://shop.acropass.jp/',
    menu: [
      {
        subtitle: '',
        subtitleLink: '',
        content: [
          {
            text: '予防美容ケア',
            externalLink: 'https://shop.bihadafactory.co.jp/product/list/22',
            inNewTab: true,
          },
          {
            text: 'SOS応急美容ケア',
            externalLink: 'https://shop.bihadafactory.co.jp/product/list/23',
            inNewTab: true,
          },
          {
            text: 'アフター美容ケア',
            externalLink: 'https://shop.bihadafactory.co.jp/product/list/24',
            inNewTab: true,
          },
        ],
      },
    ],
  },
  {
    id: 5,
    title: '情報配信',
    titleLink: '',
    icon: 'youtube',
    menu: [
      {
        subtitle: '',
        subtitleLink: '',
        content: [
          {
            text: 'マイクロニードル大学',
            externalLink:
              'https://www.youtube.com/channel/UCy4CCDcaraD8zu4y7cPFAag',
          },
          // { text: '乳酸菌大学', link: '/' },
        ],
      },
    ],
  },
  {
    id: 6,
    title: 'サポート',
    titleLink: '',
    icon: 'headphones',
    menu: [
      {
        subtitle: '',
        subtitleLink: '',
        content: [
          { text: 'お知らせ', link: path.blog },
          { text: 'FAQ', link: path.faq },
          { text: 'お問い合わせ', link: path.contact },
        ],
      },
    ],
  },
]

const MainNavMenu = ({ subtitle, subtitleLink, content, onClose, onClick }) => {
  const handleClick = () => {
    onClose()
    onClick()
  }

  let menuSubtitle
  if (subtitle && subtitleLink) {
    menuSubtitle = (
      <Link
        to={subtitleLink}
        className={styles.menuSubtitle}
        onClick={handleClick}
      >
        {subtitle}
      </Link>
    )
  } else if (subtitle && !subtitleLink) {
    menuSubtitle = <span className={styles.menuSubtitle}>{subtitle}</span>
  }

  return (
    <div>
      {menuSubtitle}
      <ul className={styles.menuList}>
        {content.map(({ text, link, externalLink, inNewTab }) => (
          <li key={text}>
            {externalLink && inNewTab && (
              <a href={externalLink} target="_blank" rel="noopener noreferrer">
                {text}
              </a>
            )}
            {externalLink && !inNewTab && <a href={externalLink}>{text}</a>}
            {!externalLink && (
              <Link to={link} onClick={handleClick}>
                {text}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

const MainNav = ({ isOpen, onClick }) => {
  const { headerHeight } = useSelector((state) => state.status)

  // State
  const [activeId, setActiveId] = useState(null)

  const items = navMenu.map((item) => (
    <li
      key={item.id}
      className={`${styles.navItem} ${
        activeId === item.id ? styles.active : ''
      }`}
      onMouseEnter={() => setActiveId(item.id)}
      onMouseLeave={() => setActiveId(null)}
    >
      {item.externalLink && (
        <a href={item.externalLink} className={styles.menuTitle}>
          <Icon name={item.icon} />
          {item.title}
        </a>
      )}
      {item.titleLink && (
        <Link
          to={item.titleLink}
          className={styles.menuTitle}
          onClick={onClick}
        >
          <Icon name={item.icon} />
          {item.title}
        </Link>
      )}
      {!item.externalLink && !item.titleLink && (
        <span className={styles.menuTitle}>
          <Icon name={item.icon} />
          {item.title}
        </span>
      )}

      {item.menu.length > 0 && !item.externalLink && (
        <div className={styles.menuWrap}>
          {item.menu.map((subItem, i) => (
            <MainNavMenu
              key={i.toString()}
              {...subItem}
              onClose={() => setActiveId(null)}
              onClick={onClick}
            />
          ))}
        </div>
      )}
    </li>
  ))

  return (
    <div
      style={{ height: `calc(100vh - ${headerHeight}px)` }}
      className={`${styles.root} ${isOpen ? styles.active : ''}`}
    >
      <SearchForm className={styles.searchForm} />
      <ul className={styles.navContent}>{items}</ul>
    </div>
  )
}

MainNavMenu.propTypes = {
  subtitle: PropTypes.string,
  subtitleLink: PropTypes.string,
  content: PropTypes.arrayOf(PropTypes.shape([])),
  onClose: PropTypes.func,
  onClick: PropTypes.func,
}

MainNavMenu.defaultProps = {
  subtitle: '',
  subtitleLink: '',
  content: [],
  onClose: () => {},
  onClick: () => {},
}

MainNav.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
}

MainNav.defaultProps = {
  isOpen: false,
  onClick: () => {},
}

export default MainNav

import React from 'react'
import { Link } from 'react-router-dom'
import Scroll from 'react-scroll'
import { path } from 'utils/const'
import Icon from 'components/Icon'
import Button from 'components/Button'
import images from 'assets/images'
import styles from './footer.module.scss'

const navLinks = [
  { text: 'ご利用ガイド', link: path.userGuide },
  {
    text: '個人情報の取扱について',
    link: path.privacy,
  },
  {
    text: '特定商取引に関する表示',
    link: path.commercialTransactions,
  },
  { text: '企業概要', link: path.about },
  { text: '沿革', link: path.history },
]

const socialLinks = [
  { name: 'line', link: 'https://lin.ee/MqH6Maz' },
  { name: 'twitter', link: 'https://twitter.com/bihadafactory' },
  { name: 'facebook', link: 'https://www.facebook.com/bihadafactory.co.jp/' },
  { name: 'instagram', link: 'https://www.instagram.com/bihadafactory/' },
]

const Footer = () => {
  // hide footer if in the admin pages
  if (window.location.href.includes(path.admin)) return null
  return (
    <footer className={styles.root}>
      {/* Campaign */}
      <div className={styles.campaign}>
        <div className={styles.campaignContainer}>
          <div className={styles.campaignTitleWrap}>
            <span className={styles.campaignSubtitle}>
              SAVE THE CHILDREN CAMPAIGN
            </span>
            <span className={styles.campaignTitle}>GIVING VACCINE</span>
          </div>
          <div>
            <Icon name="logo-vaccine" className={styles.logoVaccine} />
            <span className={styles.campaignDescription}>
              収益の１％を国際ワクチン研究所に寄付しております。
            </span>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        {/* Nav links */}
        <ul className={styles.navLinks}>
          {navLinks.map(({ text, link: navLink, externalLink }) => (
            <li key={text}>
              {externalLink ? (
                <a href={externalLink}>{text}</a>
              ) : (
                <Link to={navLink}>{text}</Link>
              )}
            </li>
          ))}
        </ul>

        <div className={styles.block}>
          {/* Logo */}
          <Link to={path.home} className={styles.logo}>
            <img src={images.logoRaphas} alt="RAPHAS logo" />
          </Link>

          {/* Social links */}
          <ul className={styles.socialLinks}>
            {socialLinks.map(({ name, link: socialLink }) => (
              <li key={name}>
                <a href={socialLink} target="_blank" rel="noopener noreferrer">
                  <Icon name={name} />
                </a>
              </li>
            ))}
          </ul>
        </div>

        {/* Copyright */}
        <span className={styles.copyright}>
          © Raphas Co., Ltd. All Rights Reserved.
        </span>

        {/* Back to top button */}
        <Button
          className={styles.btnToTop}
          onClick={() => Scroll.animateScroll.scrollToTop()}
        >
          <Icon name="arrow-up" />
        </Button>
      </div>
    </footer>
  )
}

export default Footer

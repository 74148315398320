import React from 'react'
import { useHistory } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import PageHeader from 'pageComponents/PageHeader'
import FontIcon from 'components/FontIcon'
import Table from 'components/Table'
import { path } from 'utils/const'
import products from 'data/products'
import styles from './reviews.module.scss'

const columns = [
  {
    Header: '商品ID',
    accessor: (row) => (
      <div className={styles.rowId}>
        <span>{row?.id}</span>
        <img src={row?.images[0].src} alt={row?.name} style={{ width: 160 }} />
      </div>
    ),
    style: { width: 200 },
  },
  {
    Header: '商品名',
    accessor: (row) => (
      <div className={styles.rowStatus}>
        {row?.name}
        <FontIcon name="angle-right" className={styles.arrowIcon} />
      </div>
    ),
  },
]

const Reviews = ({ className, style }) => {
  const history = useHistory()
  return (
    <div className={`${styles.root} ${className}`} style={style}>
      <PageHeader title="商品レビュー" />
      <div className={styles.tableContainer}>
        <Table
          data={products || []}
          columns={columns}
          onClick={({ original }) => {
            history.push({
              pathname: path.reviewsPerProduct,
              state: { id: original?.id },
            })
          }}
        />
      </div>
    </div>
  )
}

Reviews.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
}

Reviews.defaultProps = {
  className: '',
  style: {},
}

export default Reviews

import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import FontIcon from 'components/FontIcon'
import { path } from 'utils/const'
import styles from './sidebar.module.scss'

const MENUS = [
  {
    text: 'お知らせ',
    link: path.notice,
    icon: 'newspaper',
  },
  {
    text: '商品レビュー',
    link: path.reviews,
    icon: 'star',
  },
  {
    text: '管理者',
    link: path.members,
    icon: 'users',
  },
]

const Sidebar = () => {
  const location = useLocation()

  // ------------------------------------
  // Renderings
  // ------------------------------------

  const navItems = MENUS.map(({ text, link, icon }) => (
    <li key={text} className={styles.navItem}>
      <NavLink
        to={link}
        activeClassName={location.pathname === link ? styles.active : ''}
      >
        <FontIcon name={icon} />
        <span>{text}</span>
      </NavLink>
    </li>
  ))

  return (
    <nav className={styles.root}>
      <ul className={styles.navItems}>{navItems}</ul>
    </nav>
  )
}

export default Sidebar

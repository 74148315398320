import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as Search } from 'assets/icons/Search.svg'
import { ReactComponent as User } from 'assets/icons/my-page.svg'
import { ReactComponent as Bag } from 'assets/icons/bag.svg'
import { ReactComponent as Building } from 'assets/icons/Building.svg'
import { ReactComponent as Gem } from 'assets/icons/Gem.svg'
import { ReactComponent as Cosmetic } from 'assets/icons/Cosmetic.svg'
import { ReactComponent as Youtube } from 'assets/icons/Youtube.svg'
import { ReactComponent as Headphones } from 'assets/icons/Headphones.svg'
import { ReactComponent as Bubbles } from 'assets/icons/bubbles.svg'
import { ReactComponent as Line } from 'assets/icons/Line.svg'
import { ReactComponent as Twitter } from 'assets/icons/Twitter.svg'
import { ReactComponent as Facebook } from 'assets/icons/Facebook.svg'
import { ReactComponent as Instagram } from 'assets/icons/Instagram.svg'
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg'
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg'
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg'
import { ReactComponent as Heart } from 'assets/icons/01-icon-love.svg'
import { ReactComponent as LogoVaccine } from 'assets/images/logo-international-vaccine-institute.svg'
import { ReactComponent as LogoAcropass } from 'assets/images/logo-acropass.svg'
import { ReactComponent as LogoKarada } from 'assets/images/logo-karada.svg'
import { ReactComponent as LogoArrange } from 'assets/images/logo-arrange.svg'
import { ReactComponent as LogoKaradaArrange } from 'assets/images/logo-karada-arrange.svg'
import { ReactComponent as LogoBihadaFactory } from 'assets/images/logo-bf.svg'
import { ReactComponent as BihadaFactoryHeroText } from 'assets/images/bihada-factory-top-text.svg'
import { ReactComponent as BihadaFactoryMissionTitle } from 'assets/images/bihada-factory-mission-title.svg'
import { ReactComponent as BihadaFactoryProfileTitle } from 'assets/images/bihada-factory-profile-title.svg'
import { ReactComponent as LogoAcropassByBhihadaFactory } from 'assets/images/logo-acropass-by-bihada-factory.svg'
import { ReactComponent as LogoKaradaArrangeByBhihadaFactory } from 'assets/images/logo-karada-arrange-by-bihada-factory.svg'
import { ReactComponent as TextBeautyForce } from 'assets/images/text-beauty-force.svg'
import './icon.module.scss'

/**
 * Renders a SVG icon
 * @param {string} props.name Icon name
 * @param {object} props.attr Attributes for a SVG element. (All props other than props.name)
 * @returns Icon component to display a SVG
 * @usage e.g. <Icon name="cart" width="30" height="30" className="shopping-cart"/>
 */

const Icon = ({ name, ...attr }) => {
  switch (name) {
    case 'search':
      return <Search {...attr} />
    case 'user':
      return <User {...attr} />
    case 'bag':
      return <Bag {...attr} />
    case 'building':
      return <Building {...attr} />
    case 'gem':
      return <Gem {...attr} />
    case 'cosmetic':
      return <Cosmetic {...attr} />
    case 'youtube':
      return <Youtube {...attr} />
    case 'headphones':
      return <Headphones {...attr} />
    case 'bubbles':
      return <Bubbles {...attr} />
    case 'line':
      return <Line {...attr} />
    case 'twitter':
      return <Twitter {...attr} />
    case 'facebook':
      return <Facebook {...attr} />
    case 'instagram':
      return <Instagram {...attr} />
    case 'arrow-up':
      return <ArrowUp {...attr} />
    case 'arrow-left':
      return <ArrowLeft {...attr} />
    case 'arrow-right':
      return <ArrowRight {...attr} />
    case 'heart':
      return <Heart {...attr} />
    case 'logo-vaccine':
      return <LogoVaccine {...attr} />
    case 'logo-acropass':
      return <LogoAcropass {...attr} />
    case 'logo-karada':
      return <LogoKarada {...attr} />
    case 'logo-arrange':
      return <LogoArrange {...attr} />
    case 'logo-karada-arrange':
      return <LogoKaradaArrange {...attr} />
    case 'logo-bihada-factory':
      return <LogoBihadaFactory {...attr} />
    case 'bihada-factory-hero-text':
      return <BihadaFactoryHeroText {...attr} />
    case 'bihada-factory-mission-title':
      return <BihadaFactoryMissionTitle {...attr} />
    case 'bihada-factory-profile-title':
      return <BihadaFactoryProfileTitle {...attr} />
    case 'logo-acropass-by-bihada-factory':
      return <LogoAcropassByBhihadaFactory {...attr} />
    case 'logo-karada-arrange-by-bihada-factory':
      return <LogoKaradaArrangeByBhihadaFactory {...attr} />
    case 'text-beauty-force':
      return <TextBeautyForce {...attr} />
    default:
      return null
  }
}

Icon.propTypes = {
  name: PropTypes.string,
}

Icon.defaultProps = {
  name: '',
}

export default Icon

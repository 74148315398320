import React, { useEffect } from 'react'
// import { useDispatch } from 'react-redux'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
// import { actions as authActions } from 'slices/app.slice'
// import { actions as noticeActions } from 'slices/notice.slice'
import { path } from 'utils/const'
import ScrollToTop from 'components/ScrollToTop'
import Header from 'pageComponents/Header'
import Footer from 'pageComponents/Footer'
import Home from 'pages/home'
import BihadaFactory from 'pages/bihadaFactory'
import History from 'pages/history'
import About from 'pages/about'
import AcropassBrandStory from 'pages/acropassBrandStory'
import AcropassMicroneedle from 'pages/acropassMicroneedle'
import KaradaArrangeBrandStory from 'pages/karadaArrangeBrandStory'
import KaradaArrangeLacticAcidBacteria from 'pages/karadaArrangeLacticAcidBacteria'
import Category from 'pages/category'
import Product from 'pages/product'
import Archive from 'pages/archive'
import Post from 'pages/post'
import Faq from 'pages/faq'
import Contact from 'pages/contact'
import UserGuide from 'pages/userGuide'
import Admin from 'pages/admin'
import CommercialTransactions from 'pages/commercialTransactions'
import Privacy from 'pages/privacy'

function Router() {
  // const dispatch = useDispatch()

  useEffect(() => {
    // dispatch(authActions.authenticate())
    // dispatch(noticeActions.setPublicNotices())
  }, [])

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <main>
        <Switch>
          <Route exact path={path.home}>
            <Home />
          </Route>
          <Route exact path={path.bihadaFactory}>
            <BihadaFactory />
          </Route>
          <Route path={path.history}>
            <History />
          </Route>
          <Route path={path.about}>
            <About />
          </Route>
          <Route path={path.acropassBrandStory}>
            <AcropassBrandStory />
          </Route>
          <Route path={path.acropassMicroneedle}>
            <AcropassMicroneedle />
          </Route>
          <Route path={path.karadaArrangeBrandStory}>
            <KaradaArrangeBrandStory />
          </Route>
          <Route path={path.karadaArrangeLacticAcidBacteria}>
            <KaradaArrangeLacticAcidBacteria />
          </Route>
          <Route exact path={path.shop}>
            <Category />
          </Route>
          <Route path={path.shopDetail}>
            <Product />
          </Route>
          <Route exact path={path.blog}>
            <Archive />
          </Route>
          <Route path={path.blogDetail}>
            <Post />
          </Route>
          <Route path={path.faq}>
            <Faq />
          </Route>
          <Route path={path.contact}>
            <Contact />
          </Route>
          <Route path={path.userGuide}>
            <UserGuide />
          </Route>
          <Route path={path.admin}>
            <Admin />
          </Route>
          <Route path={path.privacy}>
            <Privacy />
          </Route>
          <Route path={path.commercialTransactions}>
            <CommercialTransactions />
          </Route>
          <Redirect to={path.home} />
        </Switch>
      </main>
      <Footer />
    </BrowserRouter>
  )
}

export default Router

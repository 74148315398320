import React, { useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import PageHeader from 'pageComponents/PageHeader'
import useMe from 'hooks/useMe'
import Select from 'components/Select'
import Input from 'components/Input'
import { actions } from 'slices/review.slice'
import options, { findOption } from 'utils/options'
import products from 'data/products'
import styles from './reviewUpdate.module.scss'

// ------------------------------------
// Constants
// ------------------------------------

const DELETE_CONFIRM_MESSAGE =
  'レビューを削除してもよろしいですか? 削除後、2度と復元できません'
const FORM_ID = 'REVIEW_UPDATE_FORM'

// ------------------------------------
// Page
// ------------------------------------

const ReviewUpdate = ({ className, style }) => {
  const history = useHistory()
  const location = useLocation()
  const { isReadonly } = useMe()
  const id = location?.state?.id
  const product_id = location?.state?.productId
  const product = products.find((p) => p?.id === product_id)

  // ------------------------------------
  // Stats
  // ------------------------------------

  const [inputs, setInputs] = useState({
    score: options.scores[0],
    name: '',
    content: '',
  })
  const [isValidated, setIsValidated] = useState(false)

  // ------------------------------------
  // Handlers
  // ------------------------------------

  const handleInputChange = ({ target: { name, value } }) => {
    setInputs((prev) => ({ ...prev, [name]: value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    setIsValidated(true)

    const form = e.currentTarget
    // eslint-disable-next-line no-useless-return
    if (!form.checkValidity()) return

    const formattedInput = {
      score: inputs?.score?.value,
      name: inputs?.name,
      content: inputs?.content,
    }

    try {
      if (id) await actions.updateReview({ id, product_id, ...formattedInput })
      else await actions.createReview({ product_id, ...formattedInput })
      history.goBack()
    } catch (err) {
      console.log('[##] err', err)
    }
  }

  const handleDelete = () => {
    if (window.confirm(DELETE_CONFIRM_MESSAGE)) {
      actions.deleteReview(id).then(() => {
        history.goBack()
      })
    }
  }

  // ------------------------------------
  // Dependencies
  // ------------------------------------

  useEffect(() => {
    if (id) {
      actions.getReview(id).then((res) => {
        setInputs({
          score: findOption(options.scores, res?.score),
          name: res?.name,
          content: res?.content,
        })
      })
    }
  }, [id])

  // ------------------------------------
  // Renderings
  // ------------------------------------

  const operation = id
    ? { title: '更新', icon: 'pen' }
    : { title: '作成', icon: 'plus' }
  const buttons = id
    ? [
        {
          text: '削除',
          icon: 'trash',
          onClick: handleDelete,
          style: { background: 'red', border: 'none' },
        },
        {
          form: FORM_ID,
          text: operation.title,
          icon: operation.icon,
        },
      ]
    : [
        {
          form: FORM_ID,
          text: operation.title,
          icon: operation.icon,
        },
      ]
  return (
    <div className={`${styles.root} ${className}`} style={style}>
      <PageHeader
        title={`レビューの${operation.title} - ${product?.name}`}
        buttons={!isReadonly && buttons}
      />
      <form
        id={FORM_ID}
        noValidate
        className={`${styles.form} ${isValidated ? 'was-validated' : ''}`}
        onSubmit={handleSubmit}
      >
        <Input
          label="名前"
          name="name"
          placeholder="ユーザー名"
          value={inputs.name}
          onChange={handleInputChange}
          className={`${styles.input} ${styles.content}`}
          pattern="^(.|\s)*\S(.|\s)*$" // non-blank and non-empty string
          error="ユーザー名を入力してください"
          disabled={isReadonly}
          required
        />
        <Select
          label="スコア"
          name="score"
          options={options.scores}
          value={inputs.score}
          onChange={handleInputChange}
          className={styles.select}
          disabled={isReadonly}
          required
        />
        <Input
          label="コメント"
          name="content"
          placeholder="コメントを入力してください"
          value={inputs.content}
          onChange={handleInputChange}
          className={`${styles.input} ${styles.contentInput}`}
          pattern="^(.|\s)*\S(.|\s)*$" // non-blank and non-empty string
          error="コメントを入力してください。"
          disabled={isReadonly}
          required
        />
      </form>
    </div>
  )
}

ReviewUpdate.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
}

ReviewUpdate.defaultProps = {
  className: '',
  style: {},
}

export default ReviewUpdate

import top from './top.jpg'

import lp1 from './lps/1.jpg'

import slide1 from './slides/1.jpg'
import slide2 from './slides/2.jpg'
import slide3 from './slides/3.jpg'
import slide4 from './slides/4.jpg'
import slide5 from './slides/5.jpg'
import slide6 from './slides/6.jpg'
import slide7 from './slides/7.jpg'

export default {
  top,
  lps: [lp1],
  slides: [slide1, slide2, slide3, slide4, slide5, slide6, slide7],
}

import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { path } from 'utils/const'
import images from 'assets/images'
import options, { findOption } from 'utils/options'
import styles from './card.module.scss'

const Card = ({ id, title, category, published_at, image_url }) => {
  const link = `${path.blog}/${id}`
  const categoryName = findOption(options.categories, category)?.label2
  return (
    <div>
      {/* Card image */}
      <div className={styles.img}>
        <Link to={link}>
          <img src={image_url || images.placeholder} alt={title} />
        </Link>
      </div>
      <div className="px-3">
        <div className={styles.meta}>
          {/* Category */}
          {categoryName && (
            <Link to={category.link} className="btn btn-sm btn-outline-primary">
              {categoryName}
            </Link>
          )}
          {/* Date */}
          <span className={styles.date}>
            {moment(published_at).format('YYYY・M・D')}
          </span>
        </div>
        {/* Card title */}
        {title && (
          <h3 className={styles.title}>
            <Link to={link}>{title}</Link>
          </h3>
        )}
      </div>
    </div>
  )
}

Card.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string,
  category: PropTypes.shape({}),
  published_at: PropTypes.string,
  link: PropTypes.string,
  img: PropTypes.shape({}),
}

Card.defaultProps = {
  title: '',
  category: {},
  published_at: '',
  link: '',
  img: {},
}

export default Card

import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styles from './bihadaFactorySlider.module.scss'

const BihadaFactorySlider = ({ slides }) => {
  const settings = { autoplay: false, autoplaySpeed: 4000 }

  return (
    <div className={styles.sliderWrapper}>
      <Slider {...settings}>
        {slides.map((slide, i) => (
          <div key={i.toString()} className={styles.slide}>
            <div className={styles.card}>
              <img src={slide} alt={i.toString} style={{ width: '100%' }} />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

BihadaFactorySlider.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default BihadaFactorySlider

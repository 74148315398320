import accare from './accare'
import accarePlus from './accare_plus'
import beautySmile from './beauty_smile'
import beautyEye from './beauty_eye'
import bug from './bug'
import coolLip from './cool_lip'
import fluCandy from './flu_candy'
import lineCare from './line_care'
import poreCare from './pore_care'
import relaxy from './relaxy'
import smoothly_7 from './smoothly_7'
import smoothly_20 from './smoothly_20'
import stability from './stability_30'
import lacticCandy from './lactic_candy'
import vita from './vita'
import recovery from './recovery'

export default {
  accarePlus,
  accare,
  beautySmile,
  beautyEye,
  bug,
  coolLip,
  fluCandy,
  lineCare,
  poreCare,
  relaxy,
  smoothly_7,
  smoothly_20,
  stability,
  lacticCandy,
  vita,
  recovery,
}

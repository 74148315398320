import prodImages from 'assets/images/products'
import images from 'assets/images'

export default {
  id: 20,
  name: 'アクロパス ビューティアイ',
  images: prodImages.beautyEye.slides.map((image, i) => ({
    id: i + 1,
    src: image,
  })),
  illustration: images.acropassProductDetails,
  lpImage: (
    <img
      src={prodImages.beautyEye.lps?.[0]}
      style={{ width: '100%' }}
      alt="lp"
    />
  ),
  intro:
    '目もとケア用のマイクロニードルパッチがついに新しくなりました！！！\n DEN溶解性マイクロニードル技術から誕生したパッチ型化粧品「アクロパスシリーズ」は、\n 保湿成分のヒアルロン酸にベースに、エイジングケア*に優れた”安定型レチノール”を配合することに成功いたしました。\n 美肌成分がたっぷり配合されたマイクロニードルが角質層のすみずみまで届けます。\n *年齢に応じたお手入れ方法\n 本来、加齢とともに低下していく成分を直接お肌に注入してあげることですこやかでハリのあるお肌に導きます。\n 「ビューティアイ」は「アクロパスシリーズ」の中でも「目元専用」の製品となります。',
  details: [
    {
      title: '商品名',
      content: 'アクロパス ビューティアイ',
    },
    {
      title: 'メーカー',
      content: '株式会社 RAPHAS JAPAN / ラパス ジャパン',
    },
    {
      title: '用途',
      content: '気になる目元ケア',
    },
    {
      title: 'JAN',
      content: '4573161350377',
    },
    {
      title: '容量',
      content: '１箱２パウチ入り　※１パウチ１回分',
    },
    {
      title: '成分',
      content:
        'ヒアルロン酸Ｎａ、水酸化Na、シクロデキストリン、水、ダイズ油、アラントイン、レチノール、パンテノール、アセチルオクタペプチド-3、BHT、スクロース',
    },
    {
      title: '製造国',
      content: '韓国',
    },
    {
      title: '使用の目安',
      content:
        '週１～２回（使い始めの２か月は週２回、３か月目以降は週１回）　※2時間以上の付着をお薦めします。\n※ご使用感には個人差がございます。',
    },
    {
      title: '使用上のご注意',
      content:
        '・水分に触れると表面のヒアルロン酸が溶けてしまいますので、必ず水分や油分をふき取った目元に、乾いた手でお貼りください。\n・傷、はれもの、湿疹などがある部位にはお使いにならないでください。\n・使用中、または使用後に赤み、はれ、かゆみや刺激などがあらわれた時は、すぐにご使用を中止してください。',
    },
  ],
  price: {
    amount: '¥ 3,960',
    tax: '（税込）',
  },
}

import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Button from 'components/Button'
import FontIcon from 'components/FontIcon'
import Avatar from 'components/Avatar'
import options, { findOption } from 'utils/options'
import styles from './memberList.module.scss'

const ADMIN = 1

const MemberList = ({ onEdit, onDelete }) => {
  const { me } = useSelector((state) => state.app)
  const { members } = useSelector((state) => state.member)
  const rows = members.map((member) => (
    <tr key={member?.id}>
      <td>
        <div className={styles.user}>
          <Avatar src={member?.user?.image_url} size="2.5rem" />
          <span>{member?.name}</span>
        </div>
      </td>
      <td>
        <span className="font-weight-bold">
          {findOption(options.roles, member?.role).label}
        </span>
      </td>
      <td>
        <div className={styles.btns}>
          {me?.role === ADMIN && me?.id !== member?.id && (
            <Button className={styles.btnEdit} onClick={() => onEdit(member)}>
              <FontIcon name="pen" />
            </Button>
          )}
          {me?.role === ADMIN && me?.id !== member?.id && (
            <Button
              className={styles.btnDelete}
              onClick={() => onDelete(member)}
            >
              <FontIcon name="trash" />
            </Button>
          )}
        </div>
      </td>
    </tr>
  ))

  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>管理者</th>
            <th>権限</th>
            <th>
              <span className="sr-only">編集</span>
            </th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  )
}

MemberList.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
}

MemberList.defaultProps = {
  onEdit: () => null,
  onDelete: () => null,
}

export default MemberList

import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Scroll from 'react-scroll'
import qs from 'query-string'
import prodImages from 'assets/images/products'
import styles from './category.module.scss'

// ------------------------------------
// Page Components
// ------------------------------------

import ProductTopItem from './ProductTopItem'
import CategoryLayout from './CategoryLayout'
import ProductTag from './ProductTag'
import ProductItem from './ProductItem'

// ------------------------------------
// Constants
// ------------------------------------

// category
const ACROPASS = '1'
const KARADA_ARRANGE = '2'

// sections
const MONTHLY_SPECIAL = '1'
const BEFORE_CARE = '2'
const SOS_CARE = '3'
const AFTER_CARE = '4'

// ------------------------------------
// Data
// ------------------------------------

const categoryTags = [
  { title: 'ALL', value: undefined },
  { title: 'ACROPASS', value: ACROPASS },
  { title: 'KARADA ARRANGE', value: KARADA_ARRANGE },
]

// products
const items = [
  {
    id: MONTHLY_SPECIAL,
    title: 'マンスリースペシャル',
    image: prodImages.stability.top,
    products: [
      {
        id: 18,
        name: 'Cool Lip',
        reading: 'クールリップ',
        slogan: '日本初!\nアスタキサンチン配合成分',
        image: prodImages.coolLip.top,
        category: ACROPASS,
        tag: 'マイクロニードル',
        link: `https://shop.acropass.jp/items/56479415`,
      },
      {
        id: 7,
        name: 'Relaxy',
        reading: 'リラクシー',
        slogan: 'デリケートゾーン\nにもやさしい',
        image: prodImages.relaxy.top,
        category: KARADA_ARRANGE,
        tag: '乳酸菌',
        link: `https://shop.acropass.jp/items/56477478`,
      },
      {
        id: 6,
        name: 'Recovery',
        reading: 'リカバリー',
        slogan: '美容ケアに☆',
        image: prodImages.recovery.top,
        category: KARADA_ARRANGE,
        tag: '乳酸菌',
        link: `https://shop.acropass.jp/items/56477302`,
      },
    ],
  },
  {
    id: BEFORE_CARE,
    title: '予防美容ケア',
    image: prodImages.coolLip.top,
    products: [
      {
        id: 20,
        name: 'Beauty Eye',
        reading: 'ビューティーアイ',
        slogan: '年齢にふさわしく\n目もと素肌ケア',
        image: prodImages.beautyEye.top,
        category: ACROPASS,
        tag: 'マイクロニードル',
        link: `https://shop.acropass.jp/items/56478911`,
      },
      {
        id: 21,
        name: 'Beauty Smile',
        reading: 'ビューティースマイル',
        slogan: '年齢相応の\n口元マストケア！',
        image: prodImages.beautySmile.top,
        category: ACROPASS,
        tag: 'マイクロニードル',
        link: `https://shop.acropass.jp/items/56479118`,
      },
      {
        id: 5,
        name: 'Line Care',
        reading: 'ラインケア',
        slogan: '韓国でも大人気\nパッチPOWER UP！',
        image: prodImages.lineCare.top,
        category: ACROPASS,
        tag: 'マイクロニードル',
        link: `https://shop.acropass.jp/items/56479261`,
      },
      {
        id: 18,
        name: 'Cool Lip',
        reading: 'クールリップ',
        slogan: '日本初!\nアスタキサンチン配合成分',
        image: prodImages.coolLip.top,
        category: ACROPASS,
        tag: 'マイクロニードル',
        link: `https://shop.acropass.jp/items/56479415`,
      },
      {
        id: 7,
        name: 'Relaxy',
        reading: 'リラクシー',
        slogan: 'デリケートゾーン\nにもやさしい',
        image: prodImages.relaxy.top,
        category: KARADA_ARRANGE,
        tag: '乳酸菌',
        link: `https://shop.acropass.jp/items/56477478`,
      },
      {
        id: 6,
        name: 'Recovery',
        reading: 'リカバリー',
        slogan: '美容ケアに☆',
        image: prodImages.recovery.top,
        category: KARADA_ARRANGE,
        tag: '乳酸菌',
        link: `https://shop.acropass.jp/items/56477302`,
      },
      // {
      //   id: 27,
      //   name: '強力乳酸菌あめ',
      //   reading: '',
      //   slogan: 'お出かけ前に一粒！ ',
      //   image: prodImages.lacticCandy.top,
      //   category: KARADA_ARRANGE,
      //   tag: '乳酸菌',
      // },
    ],
  },
  {
    id: SOS_CARE,
    title: 'SOS応急美容ケア',
    image: prodImages.poreCare.top,
    products: [
      {
        id: 23,
        name: 'ACCare',
        reading: 'エーシーケア',
        slogan: 'できはじめの\nプツンには今すぐコレ！',
        image: prodImages.accare.top,
        category: ACROPASS,
        tag: 'マイクロニードル',
        link: `https://shop.acropass.jp/items/25143273`,
      },
      {
        id: 22,
        name: 'ACCare Plus',
        reading: 'エーシーケア プラス',
        slogan: 'お得な\nDAY&NIGHTケアセット',
        image: prodImages.accarePlus.top,
        category: ACROPASS,
        tag: 'マイクロニードル',
        link: `https://shop.acropass.jp/items/23183626`,
      },
      {
        id: 25,
        name: 'Bug Patch',
        reading: 'バグパッチ',
        slogan: '季節の変わり目に！\nアウトドアに！',
        image: prodImages.bug.top,
        category: ACROPASS,
        tag: 'マイクロニードル',
        link: `https://shop.acropass.jp/items/17882362`,
      },
      {
        id: 12,
        name: 'Smoothly',
        reading: 'スムースリー',
        slogan: 'まずは一週間☆\n乳酸菌５千億個 ！',
        image: prodImages.smoothly_7.top,
        category: KARADA_ARRANGE,
        tag: '乳酸菌',
        link: `https://shop.acropass.jp/items/56191602`,
      },
      {
        id: 11,
        name: 'Smoothly',
        reading: 'スムースリー',
        slogan: '続けよう☆\n乳酸菌５千億個 ！',
        image: prodImages.smoothly_20.top,
        category: KARADA_ARRANGE,
        tag: '乳酸菌',
        link: `https://shop.acropass.jp/items/56189553`,
      },
      {
        id: 26,
        name: 'インフルバリア\nのど飴',
        reading: '',
        slogan: '燕窩素材とビタミンC\nを配合したキャンディーです。',
        image: prodImages.fluCandy.top,
        category: KARADA_ARRANGE,
        tag: '乳酸菌',
        link: `https://shop.acropass.jp/items/59350299`,
      },
    ],
  },
  {
    id: AFTER_CARE,
    title: 'アフター美容ケア',
    image: prodImages.beautySmile.top,
    products: [
      {
        id: 19,
        name: 'Pore Care',
        reading: 'ポアケア',
        slogan: '10年前のなめらか素肌、\n取り戻そう！',
        image: prodImages.poreCare.top,
        category: ACROPASS,
        tag: 'マイクロニードル',
        link: `https://shop.acropass.jp/items/56479648`,
      },
      {
        id: 24,
        name: 'VITA',
        reading: 'ビタ',
        slogan: 'どんより肌には\nビタミンCのパワー！',
        image: prodImages.vita.top,
        category: ACROPASS,
        tag: 'マイクロニードル',
        link: `https://shop.acropass.jp/items/17863447`,
      },
      // {
      //   id: 13,
      //   name: 'Stability',
      //   reading: 'スタビリティー',
      //   slogan: '高濃度乳酸菌一兆個！',
      //   image: prodImages.stability.top,
      //   category: KARADA_ARRANGE,
      //   tag: '乳酸菌',
      // },
    ],
  },
]

// ------------------------------------
// Helpers
// ------------------------------------

const Scroller = Scroll.scroller

const getFilteredItems = (category) => {
  if (!category) return items
  return items.map((item) => {
    // filter by category id
    const filteredProducts = item.products.filter(
      (product) => product.category === category,
    )
    return {
      ...item,
      products: filteredProducts,
    }
  })
}

const getSearchedProducts = (search) => {
  const keyword = search.toLowerCase()
  return items.reduce((sum, item) => {
    if (item.title.toLowerCase().includes(keyword)) {
      return [...sum, ...item.products]
    }

    const filteredProducts = item.products.filter((product) => {
      const { name, reading, slogan, category, tag } = product
      const categoryName =
        category === ACROPASS
          ? 'あくろぱす アクロパス ACROPASS'
          : 'からだあれんじ カラダアレンジ KARADA ARRANGE'
      return [name, reading, slogan, categoryName, tag].some((text) =>
        text.toLowerCase().includes(keyword),
      )
    })

    return [...sum, ...filteredProducts]
  }, [])
}

// ------------------------------------
// Pages
// ------------------------------------

const Category = () => {
  const { search } = useLocation()
  const { searchText } = useSelector((state) => state.app)
  const { category, section } = qs.parse(search)
  const [activeTag, setActiveTag] = useState(category)
  const filteredItems = getFilteredItems(activeTag)

  useEffect(() => {
    if (section)
      Scroller.scrollTo(section, {
        smooth: true,
        offset: -100,
      })
    setActiveTag(category)
  }, [search])

  if (searchText) {
    const filteredProducts = getSearchedProducts(searchText)
    return (
      <div className={styles.root}>
        <div className="container">
          <CategoryLayout
            title={`検索結果: ${searchText}`}
            style={{ border: 'none' }}
          >
            {filteredProducts.map((product) => (
              <a href={product.link}>
                <ProductItem
                  key={product.title}
                  image={product.image}
                  slogan={product.slogan}
                  name={product.name}
                  reading={product.reading}
                  tag={product.tag}
                />
              </a>
            ))}
            {filteredProducts.length === 0 && '検索結果はありません'}
          </CategoryLayout>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.root}>
      <div className="container">
        <h1 className="sr-only">オンラインモール</h1>
        <div className={styles.top}>
          {filteredItems.map((item) => (
            <ProductTopItem
              id={item.id}
              key={`${item.title}-${item.id}`}
              title={item.title}
              image={item.image}
            />
          ))}
        </div>
        <div className={styles.tag}>
          {categoryTags.map((tag) => (
            <ProductTag
              key={tag.title}
              title={tag.title}
              isActive={tag.value === activeTag}
              onClick={() => {
                setActiveTag(tag.value)
              }}
            />
          ))}
        </div>
        {filteredItems.map((item) => (
          <CategoryLayout
            id={item.id}
            key={`${item.title}-${item.id}`}
            title={item.title}
          >
            {item.products.map((product) => (
              <a href={product.link}>
                <ProductItem
                  key={`${item.title}-${item.id}-${product.title}`}
                  image={product.image}
                  slogan={product.slogan}
                  name={product.name}
                  reading={product.reading}
                  tag={product.tag}
                />
              </a>
            ))}
          </CategoryLayout>
        ))}
      </div>
    </div>
  )
}

Category.propTypes = {}
Category.defaultProps = {}

export default Category

import apollo from 'utils/apollo'
import { gql } from '@apollo/client'

import { createSlice } from '@reduxjs/toolkit'
import { auth } from 'utils/firebase'

// ------------------------------------
// State
// ------------------------------------

const initialState = {
  checked: false,
  loggedIn: false,
  searchText: '',
  me: {},
}

// ------------------------------------
// Slices
// -----------------------------------

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setMe: (state, action) => ({
      ...state,
      me: action.payload.me,
      loggedIn: action.payload.loggedIn,
      checked: true,
    }),
    setLoggedIn: (state, action) => ({
      ...state,
      loggedIn: action.payload,
    }),
    setSearch: (state, action) => ({
      ...state,
      searchText: action.payload,
    }),
  },
})

// ------------------------------------
// Actions
// -----------------------------------

export const authenticate = () => (dispatch) => {
  auth.onAuthStateChanged(async (me) => {
    if (!me) {
      return dispatch(
        slice.actions.setMe({
          loggedIn: false,
          checked: true,
          me: {},
        }),
      )
    }

    // get user
    const { data } = await apollo.query({
      query: gql`
        query me {
          me {
            id
            name
            email
            role
            created_at
            updated_at
          }
        }
      `,
    })

    // login
    return dispatch(
      slice.actions.setMe({
        loggedIn: me?.emailVerified && data?.me,
        me: data?.me
          ? { id: me?.uid, emailVerified: me?.emailVerified, ...data?.me }
          : {},
        checked: true,
      }),
    )
  })
}

const login =
  ({ email, password }) =>
  (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        const { user } = await auth.signInWithEmailAndPassword(email, password)
        if (!user)
          reject(new Error('Failed to login. please try it again later'))
        if (!user.emailVerified) await user.sendEmailVerification()
        dispatch(authenticate())
        resolve(user)
      } catch (err) {
        reject(err)
      }
    })

const logout = () => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      await auth.signOut()
      dispatch(
        slice.actions.setMe({
          checked: true,
          loggedIn: false,
          me: {},
        }),
      )
      resolve()
    } catch (err) {
      reject(err)
    }
  })

const resetPassword = (email) => () => auth.sendPasswordResetEmail(email)

// ------------------------------------
// Exports
// ------------------------------------

export const actions = {
  ...slice.actions,
  authenticate,
  login,
  logout,
  resetPassword,
}

export default slice.reducer

import React, { useState, useEffect, useMemo } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import { actions } from 'slices/notice.slice'
import PageHeader from 'pageComponents/PageHeader'
import FontIcon from 'components/FontIcon'
import Table from 'components/Table'
import options, { findOption } from 'utils/options'
import { path } from 'utils/const'
import styles from './notices.module.scss'

// ------------------------------------
// Constants
// ------------------------------------

const ADMIN = 1

// ------------------------------------
// Pages
// ------------------------------------

const Notices = ({ className, style }) => {
  const history = useHistory()
  const { me } = useSelector((state) => state.app)

  // ------------------------------------
  // States
  // ------------------------------------
  const [notices, setNotices] = useState(null)
  const [isLoading, setLoading] = useState(true)

  // ------------------------------------
  // Subscriptions
  // ------------------------------------

  useEffect(() => {
    setLoading(true)
    actions
      .getNotices()
      .then((res) => {
        setNotices(res.reverse())
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  // ------------------------------------
  // Renderings
  // ------------------------------------

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: (row, i) => (
          <div className={styles.rowId}>
            <span>{i + 1}</span>
            <img src={row?.image_url} alt={`サブネイル${row?.id}`} />
          </div>
        ),
        style: { width: 200 },
      },
      {
        Header: 'タイトル',
        accessor: (row) => (
          <div className={styles.rowTitle}>
            <div>
              <button type="button" className="btn btn-sm btn-outline-primary">
                {findOption(options.categories, row?.category)?.label2}
              </button>
              {moment(row?.published_at).format('YYYY・M・D')}
            </div>
            {row?.title}
          </div>
        ),
      },
      {
        Header: '作成者',
        accessor: 'published_by.name',
        style: { width: 160 },
      },
      {
        Header: 'ステータス',
        accessor: (row) => (
          <div className={styles.rowStatus}>
            {findOption(options.statuses, row?.status)?.label}
            <FontIcon name="angle-right" className={styles.arrowIcon} />
          </div>
        ),
        style: { width: 200 },
      },
    ],
    [notices],
  )

  return (
    <div className={`${styles.root} ${className}`} style={style}>
      <PageHeader
        title="お知らせ一覧"
        isLoading={isLoading}
        buttons={
          me?.role === ADMIN && [
            {
              text: '新しいお知らせを追加',
              icon: 'plus',
              onClick: () => history.push(path.noticeUpdate),
            },
          ]
        }
      />
      <div className={styles.tableContainer}>
        <Table
          data={notices || []}
          columns={columns}
          onClick={({ original }) => {
            history.push({
              pathname: path.noticeUpdate,
              state: { id: original?.id },
            })
          }}
        />
        {!isLoading && Array.isArray(notices) && notices.length === 0 && (
          <b style={{ marginTop: 24, textAlign: 'center' }}>
            📢 作成したお知らせはありません。
          </b>
        )}
      </div>
    </div>
  )
}

Notices.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
}

Notices.defaultProps = {
  className: '',
  style: {},
}

export default Notices

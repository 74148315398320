import React from 'react'
import PropTypes from 'prop-types'
import PageLayout from 'pageComponents/PageLayout'
import images from 'assets/images'
import styles from './history.module.scss'

const histories = [
  {
    year: 2019,
    items: [
      {
        month: '12',
        content: '天安に新工場を移転',
      },
      {
        month: '11',
        content: 'KOSDAQ(コスダック)上場法人に登録',
      },
      {
        month: '05',
        content: '天安工場、CGMP（優秀化粧品製造及び品質管理基準）を取得',
      },
      {
        month: '04',
        content: '2019年グローバル強小企業に指定',
      },
      {
        month: '02',
        content: '成果共有企業に指定',
      },
    ],
  },
  {
    year: 2018,
    items: [
      {
        month: '12',
        content: '天安工場、ISO13485の認証を取得\n「青年親和強小企業」に選定',
      },
      {
        month: '02',
        content: '麻谷社屋に本社移転',
      },
    ],
  },
  {
    year: 2017,
    items: [
      {
        month: '12',
        content: '人材育成型中小企業に指定',
      },
      {
        month: '11',
        content:
          'ベンチャー活性化有功者部門で｢長官賞｣を受賞\n素材部品産業発展有功者部門で｢大統領賞｣を受賞',
      },
      {
        month: '08',
        content: '技術革新優秀企業｢チャン・ヨンシル記念賞｣を受賞',
      },
      {
        month: '03',
        content: '食品事業の本格化、「FitKong」の立ち上げ',
      },
    ],
  },
  {
    year: 2006,
    items: [
      {
        month: '03',
        content: '㈱RAPHASの設立',
      },
    ],
  },
]

const Timeline = ({ year, items }) => (
  <>
    <ul className={styles.timeline}>
      {items.map(({ month, content }) => (
        <li key={content} className={styles.timelineItem}>
          <span className={styles.timelineMonth}>
            {year}.{month}
          </span>
          <span className={styles.timelineContent}>{content}</span>
        </li>
      ))}
    </ul>
    <div className={styles.timelineYear}>
      <div className={styles.timelineYearInner}>{year}</div>
    </div>
  </>
)

const History = () => (
  <PageLayout
    title="沿革"
    description={`RAPHASの誕生は、 小さい研究室での１つのアイデアがきっかけでした。\n長年生命工学を勉強してきた研究者たちが、 多くの人々に役立つ何かを作りたいという夢からスタートしました。`}
    backgroundImage={images.history}
  >
    <div className={styles.content}>
      {histories.map((history) => (
        <Timeline key={history.year} {...history} />
      ))}
    </div>
  </PageLayout>
)

Timeline.propTypes = {
  year: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})),
}

Timeline.defaultProps = {
  items: [],
}

export default History

/**
 * extract label from value
 * @param {array} options
 * @param {any} value
 * @returns
 */
export const findOption = (options, value) => {
  if (!Array.isArray(options)) return null
  return options.find((x) => x.value === value)
}

/**
 * designed option values for react-select
 */
export default {
  categories: [
    { value: 1, label: 'ニュース', label2: 'news' },
    { value: 2, label: 'メディア', label2: 'media' },
    { value: 3, label: 'キャンペーン', label2: 'campaign' },
    { value: 4, label: 'その他', label2: 'others' },
  ],
  statuses: [
    { value: 1, label: '公開' },
    { value: 0, label: '非公開' },
  ],
  roles: [
    { label: '管理者', value: 1 },
    { label: '閲覧者', value: 0 },
  ],
  scores: [
    { value: 5, label: '❤️❤️❤️❤️❤️' },
    { value: 4, label: '❤️❤️❤️❤️' },
    { value: 3, label: '❤️❤️❤️' },
    { value: 2, label: '❤️❤️' },
    { value: 1, label: '❤️' },
  ],
}

import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import Avatar from 'components/Avatar'
import Button from 'components/Button'
import styles from './header.module.scss'
import UserPopup from '../UserPopup'

const Header = () => {
  const ref = useRef(null)
  const { me, loggedIn } = useSelector((state) => state.app)

  // ------------------------------------
  // States
  // ------------------------------------
  const [isOpen, setIsOpen] = useState({ notification: false, user: false })

  // ------------------------------------
  // Handlers
  // ------------------------------------
  const handleClick = (e) => {
    const { popup } = e?.currentTarget?.dataset || {}

    // 1. notification icon pressed -> toggle notification popup
    // 2. profile icon pressed -> toggle profile popup
    // 3. outside pressed -> close all popup
    setIsOpen((prev) => ({
      notification: popup === 'notification' && !prev.notification,
      user: popup === 'user' && !prev.user,
    }))
  }

  const handleClickOutside = (e) => {
    if (e.target.closest('[data-popup]')) return

    if (ref.current && !ref.current.contains(e.target)) {
      handleClick(e)
    }
  }

  // ------------------------------------
  // Subscriptions
  // ------------------------------------
  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  // ------------------------------------
  // Renderings
  // ------------------------------------
  return (
    <header className={`${styles.root} shadow`}>
      <nav className={styles.navbar}>
        <span />

        <ul className={styles.navItems}>
          <li>
            <Button
              data-popup="user"
              onClick={handleClick}
              disabled={!loggedIn}
            >
              <Avatar src={me?.image_url} size="2.25rem" />
            </Button>
            {/* User popup */}
            {isOpen.user && <UserPopup ref={ref} onClick={handleClick} />}
          </li>
        </ul>
      </nav>
    </header>
  )
}

Header.propTypes = {}
Header.defaultProps = {}

export default Header

import apollo from 'utils/apollo'
import { gql } from '@apollo/client'
import { createSlice } from '@reduxjs/toolkit'

// ------------------------------------
// State
// ------------------------------------

const initialState = {}

// ------------------------------------
// Slices
// -----------------------------------

const slice = createSlice({
  name: 'review',
  initialState,
  reducers: {},
})

// ------------------------------------
// Actions
// -----------------------------------

export const getReview = async (id) => {
  try {
    const { data } = await apollo.query({
      query: gql`
        query review($id: String!) {
          review(id: $id) {
            id
            product_id
            name
            score
            content
            created_at
            updated_at
          }
        }
      `,
      variables: {
        id,
      },
    })

    return data?.review
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

export const getReviews = async (product_id) => {
  try {
    const { data } = await apollo.query({
      query: gql`
        query reviews($product_id: Int!) {
          reviews(product_id: $product_id) {
            id
            product_id
            name
            score
            content
            created_at
            updated_at
          }
        }
      `,
      variables: {
        product_id,
      },
    })

    return data?.reviews
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

export const createReview = async (input) => {
  try {
    const { data } = await apollo.mutate({
      mutation: gql`
        mutation createReview($input: CreateReviewInput!) {
          createReview(input: $input) {
            id
          }
        }
      `,
      variables: {
        input,
      },
    })
    return data?.createReview
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

const updateReview = async (input) => {
  try {
    const { data } = await apollo.mutate({
      mutation: gql`
        mutation updateReview($input: UpdateReviewInput!) {
          updateReview(input: $input) {
            id
          }
        }
      `,
      variables: {
        input,
      },
    })
    return data?.updateReview
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

const deleteReview = async (id) => {
  try {
    const { data } = await apollo.mutate({
      mutation: gql`
        mutation deleteReview($id: String!) {
          deleteReview(id: $id)
        }
      `,
      variables: {
        id,
      },
    })
    return data
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

// ------------------------------------
// Exports
// ------------------------------------

export const actions = {
  ...slice.actions,
  getReview,
  getReviews,
  createReview,
  updateReview,
  deleteReview,
}

export default slice.reducer

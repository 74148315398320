// import React, { useState } from 'react'
import React from 'react'
// import React, { useState } from 'react'
import { useSelector } from 'react-redux'
// import Button from 'components/Button'
import images from 'assets/images'
import Icon from 'components/Icon'
import styles from './acropassBrandStory.module.scss'

const visionData = [
  {
    title: '予防美容',
  },
  {
    title: 'SOS応急ケア',
  },
  {
    title: 'アフターケア',
  },
]

const identityData = [
  {
    title: 'ACRO',
    description: '高い、頂上の、極上の',
    img: images.brandStoryAcro,
  },
  {
    title: 'PASS',
    description: '過ぎる、Path（通路、道）',
    img: images.brandStoryPass,
  },
]

// const policies = [
//   {
//     id: 1,
//     title: 'Dream',
//     subtitle:
//       '人体に効果的な有効成分を痛みを感じること無く、最も効率的に伝達できる方法は無いかな？',
//     description:
//       'アクロパスは、生命工学を勉強してきた研究者の小さな夢から始まりました。',
//   },
//   {
//     id: 2,
//     title: 'Imagine',
//     subtitle: 'Imagine Subtitle',
//     description: 'Imagine Description',
//   },
//   {
//     id: 3,
//     title: 'Think\n Different',
//     subtitle: 'Think Different Subtitle',
//     description: 'Think Different Description',
//   },
//   {
//     id: 4,
//     title: 'Create\n Technology',
//     subtitle: 'Create Technology Subtitle',
//     description: 'Create Technology Description',
//   },
// ]

const essenceData = [
  {
    title: 'はやめはやめの\nデイリーケア！',
    description:
      '季節や体調によって日々変化する肌。毎日少しづつの努力で、過酷な環境にも負けない美肌作りを。さあ今からはじめよう！',
    // keyword: '専門家グループ',
  },
  {
    title: '「NO!MAKE 時代」\n瞬時に、時短ケア！',
    description:
      '肌悩みは突然に。溶解性マイクロニードルで必要な場所*に美肌成分を届け、瞬時に理想へと導きます。\n*角質層まで',
    // keyword: 'New Beauty Solution',
  },
  {
    title: 'できてしまった\n肌悩みに！',
    description:
      '肌悩みは蓄積されるてしまうもの。あきらめずに続けることでゆるぎない美肌を手に入れよう！',
    // keyword: '誠実な販売',
  },
]

const AcropassBrandStory = () => {
  const { headerHeight } = useSelector((state) => state.status)
  const minHeight = headerHeight && `calc(100vh - ${headerHeight}px)`

  // State
  // const [activeId, setActiveId] = useState(1)

  // Handlers
  // const handleClick = (id) => setActiveId(id)

  return (
    <>
      {/* Hero */}
      <div style={{ minHeight }} className={styles.hero}>
        <div className={`${styles.heroContainer} container`}>
          <h1 className="sr-only">ACROPASS by 美肌factory</h1>
          <Icon
            name="logo-acropass-by-bihada-factory"
            className={styles.heroTitle}
          />
          <p className={styles.heroDescription}>
            肌健康の本質に
            <br />
            アプローチし、
            <br />
            肌悩みを根本的に
            <br />
            解決するブランド
          </p>
          <Icon name="text-beauty-force" className={styles.heroText} />
        </div>
      </div>

      {/* Brand Identity */}
      <section
        style={{ minHeight }}
        className={`${styles.section} overflow-hidden`}
      >
        <div className={`${styles.container} container`}>
          <h2 className={styles.sectionTitle}>Brand Identity</h2>
          <div className={styles.identityContent}>
            {identityData.map((item, i, arr) => (
              <React.Fragment key={i.toString()}>
                <div className={styles.identityItem}>
                  <div className={styles.identityItemImg}>
                    <img src={item.img} alt={item.title} />
                  </div>
                  <span className={styles.identityItemTitle}>{item.title}</span>
                  <span className={styles.identityItemDescription}>
                    {item.description}
                  </span>
                </div>
                {/* Add plus icon if item is not last child */}
                {i !== arr.length - 1 && (
                  <div className={styles.identityPlus}>
                    <img src={images.brandStoryPlus} alt="Plus" />
                  </div>
                )}
              </React.Fragment>
            ))}

            <img
              src={images.brandStoryHiaru}
              alt="ヒアル"
              className={`${styles.identityCharacter} ${styles.identityHiaru}`}
            />
            <img
              src={images.brandStoryNidoru}
              alt="ニドル"
              className={`${styles.identityCharacter} ${styles.identityNidoru}`}
            />
          </div>
          <div>
            <span className={styles.identityDescription}>
              究極の頂点に到達する新しい道
            </span>
          </div>
        </div>
      </section>

      {/* Our Vision */}
      <section
        style={{ minHeight }}
        className={`${styles.section} ${styles.visionSection}`}
      >
        <div className={`${styles.container} container`}>
          <h2 className={styles.sectionTitle}>Our Vision</h2>
          <span className={styles.visionSubtitle}>
            クリアで健やかな肌へと導くコスメ
          </span>
          <div className={styles.visionContent}>
            <ul className={styles.visionContentInner}>
              {visionData.map((item, i) => (
                <li key={i.toString()} className={styles.visionItem}>
                  <div className={styles.visionItemTitle}>
                    <span>{item.title}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <span className={styles.visionDescription}>
              肌栄養成分をダイレクトに届ける素肌ケア
            </span>
          </div>
          <ol className={styles.visionIndicators}>
            <li className={styles.active} />
            <li />
            <li />
          </ol>
        </div>
      </section>

      {/* Our Policy */}
      {/* <section
        style={{ minHeight }}
        className={`${styles.section} ${styles.policySection}`}
      >
        <div className={`${styles.container} container`}>
          <h2 className={styles.sectionTitle}>Our Policy</h2>
          <ul className={styles.policyContent}>
            {policies.map((policy) => (
              <li key={policy.id} className={styles.policyItem}>
                <Button
                  className={policy.id === activeId ? styles.active : ''}
                  onClick={() => handleClick(policy.id)}
                >
                  {policy.title}
                </Button>
              </li>
            ))}
          </ul>
          {policies.map((policy) =>
            policy.id === activeId ? (
              <div key={policy.id} className={styles.policyText}>
                <span className={styles.policySubtitle}>{policy.subtitle}</span>
                <span className={styles.policyDescription}>
                  {policy.description}
                </span>
              </div>
            ) : null,
          )}
        </div>
      </section> */}

      {/* Essence */}
      <section style={{ minHeight }} className={styles.section}>
        <div className={`${styles.container} container`}>
          <div className={styles.essenceHeader}>
            <h2 className={`${styles.sectionTitle} mb-3`}>Essence</h2>
            {/* <span className={styles.essenceSubtitle}>
              Home Derma filling System
            </span> */}
          </div>
          <div className={styles.essenceContent}>
            {essenceData.map((item, i) => (
              <React.Fragment key={i.toString()}>
                <h3 className={styles.essenceItemTitle}>{item.title}</h3>
                <p className={styles.essenceItemContent}>
                  <span className={styles.essenceItemDescription}>
                    {item.description}
                  </span>
                  {/* <span className={styles.essenceItemKeyword}>
                    {item.keyword}
                  </span> */}
                </p>
              </React.Fragment>
            ))}
          </div>
          <div>
            <span className={styles.essenceDescription}>
              あらゆる変化にも負けない素肌ケアしましょう
            </span>
          </div>
        </div>
      </section>
    </>
  )
}

export default AcropassBrandStory

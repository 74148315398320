import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
// import { Link } from 'react-router-dom'
import Scroll from 'react-scroll'
import images from 'assets/images'
import prodImages from 'assets/images/products'
// import { path } from 'utils/const'
import styles from './acropassMicroneedle.module.scss'

const ANIMATION_SPEED = 3
const NUMBER_OF_SLIDES = 8

const products = [
  {
    id: 23,
    title: 'アクロパス ACケア',
    category: 'SOS応急美容ケア',
    // link: `${path.shop}/23`,
    link: `https://shop.acropass.jp/items/25143273`,
    image: {
      src: prodImages.accare.top,
    },
  },
  {
    id: 22,
    title: 'アクロパス ACケア プラス',
    category: 'SOS応急美容ケア',
    // link: `${path.shop}/22`,
    link: `https://shop.acropass.jp/items/23183626`,
    image: {
      src: prodImages.accarePlus.top,
    },
  },
  {
    id: 24,
    title: 'アクロパス 素肌美人VITA',
    category: 'アフター美容ケア',
    // link: `${path.shop}/24`,
    link: `https://shop.acropass.jp/items/17863447`,
    image: {
      src: prodImages.vita.top,
    },
  },
  {
    id: 25,
    title: 'アクロパス BUGパッチ',
    category: 'SOS応急美容ケア',
    // link: `${path.shop}/25`,
    link: `https://shop.acropass.jp/items/17882362`,
    image: {
      src: prodImages.bug.top,
    },
  },
  {
    id: 20,
    title: 'アクロパス ビューティアイ',
    category: '予防美容ケア',
    // link: `${path.shop}/20`,
    link: `https://shop.acropass.jp/items/56478911`,
    image: {
      src: prodImages.beautyEye.top,
    },
  },
  {
    id: 21,
    title: 'アクロパス ビューティスマイル',
    category: '予防美容ケア',
    // link: `${path.shop}/21`,
    link: `https://shop.acropass.jp/items/56479118`,
    image: {
      src: prodImages.beautySmile.top,
    },
  },
  {
    id: 5,
    title: 'うるおいチャージ Line Care',
    category: '予防美容ケア',
    // link: `${path.shop}/5`,
    link: `https://shop.acropass.jp/items/56479261`,
    image: {
      src: prodImages.lineCare.top,
    },
  },
  {
    id: 18,
    title: 'うるおいチャージ CooL Lip',
    category: '予防美容ケア',
    // link: `${path.shop}/18`,
    link: `https://shop.acropass.jp/items/56479415`,
    image: {
      src: prodImages.coolLip.top,
    },
  },
  {
    id: 19,
    title: '素肌美人ポアケア',
    category: 'アフター美容ケア',
    // link: `${path.shop}/19`,
    link: `https://shop.acropass.jp/items/56479648`,
    image: {
      src: prodImages.poreCare.top,
    },
  },
]

const Product = ({ title, category, link, image }) => (
  <li>
    <a href={link} className={styles.productContent}>
      {category && <span className={styles.productCategory}>{category}</span>}
      {image.src && (
        <div className={styles.productImg}>
          <img src={image.src} alt={title} />
        </div>
      )}
    </a>
    {title && (
      <h3 className={styles.productTitle}>
        <a href={link}>{title}</a>
      </h3>
    )}
  </li>
)

const AcropassMicroneedle = () => {
  const { headerHeight } = useSelector((state) => state.status)
  const minHeight = headerHeight ? `calc(100vh - ${headerHeight}px)` : ''
  const [isComplete, setComplete] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setComplete(true)
      Scroll.scroller.scrollTo(headerHeight)
    }, ANIMATION_SPEED * NUMBER_OF_SLIDES * 1000)
  }, [])

  return (
    <>
      {/* Animation slides */}
      <div
        id="product-list"
        className={`${styles.slideWrapper} ${isComplete && styles.showAll}`}
      >
        <div
          className={`${styles.slide} ${styles.slideBg01} ${
            isComplete && styles.showAll
          }`}
          style={{ minHeight }}
        >
          <div className={`${styles.slideContainer} container`}>
            <div className={styles.slideContentHalf}>
              <h2 className={styles.slideTitleSm}>
                肌は本来、私たちの体を守るバリア機能を備えています。
              </h2>
              <p className={styles.slideDescription}>
                肌バリア機能によって肌は守られますが、必要な成分を肌の中に届けることは、この「バリア機能」によって限界が生じます。この限界を乗り越えて肌の隅々まで良い成分を届けたい思いから生まれました。
              </p>
            </div>
          </div>
        </div>

        <div className={styles.slide}>
          <div className={`${styles.slideContainer} container`}>
            <div className="text-center">
              <h2 className={styles.slideTitleLg}>
                コスメの最前線
                <br />
                アクロパスは違います。
              </h2>
            </div>
          </div>
        </div>

        <div className={`${styles.slide} ${styles.slideBg02}`}>
          <div className={`${styles.circle} ${styles.position01}`} />
        </div>

        <div
          className={`${styles.slide} ${styles.slideBg02} ${
            !isComplete && styles.overlay
          } ${isComplete && styles.showAll}`}
          style={{ minHeight }}
        >
          <div className={`${styles.slideContainer} container`}>
            <div className={styles.slideContent}>
              <div className={styles.slideImg}>
                <img src={images.acropassTech} alt="マイクロ構造体技術" />
              </div>
              <div>
                <span className={styles.slideCaption}>
                  肌バリア機能を克服した新技術
                </span>
                <h2 className={`${styles.slideTitleSm} ${styles.mrSpacer}`}>
                  美容成分を効率よくお肌に届ける
                  <br className="d-none d-lg-inline" />
                  <span className={styles.textColored}>
                    溶解性マイクロニードル
                  </span>
                  技術。
                </h2>
                <p className={styles.slideDescription}>
                  コスメの最前線「アクロパス」
                  <br />
                  効率よく肌の内側に美容成分を届ける「溶解性マイクロニードル」技術を用いた革新的な美容ツールであります。
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`${styles.slide} ${styles.slideBg02} ${
            !isComplete && styles.overlay
          } ${isComplete && styles.showAll}`}
          style={{ minHeight }}
        >
          <div className={`${styles.slideContainer} container`}>
            <div className={styles.slideContent}>
              {isComplete ? (
                <div className={styles.slideVideo}>
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/2Gq6LdusfHM?autoplay=1&mute=1&playlist=2Gq6LdusfHM&loop=1&rel=0"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              ) : (
                <div className={styles.slideImg}>
                  <img src={images.ytThumb1} alt="マイクロ構造体技術" />
                </div>
              )}
              <div>
                <span className={styles.slideCaption}>
                  肌バリア機能を克服した新技術
                </span>
                <h2 className={`${styles.slideTitleSm} ${styles.mrSpacer}`}>
                  独自の
                  <span className={styles.textColored}>DEN方式</span>
                  を採用。
                </h2>
                <p className={styles.slideDescription}>
                  アクロパスには、溶解性マイクロニードルの独自製法「DEN方式
                  (送風引張方式) は、
                  <br className="d-none d-lg-inline" />
                  温度変化のない常温乾燥・ニードル成形を実現、肌への浸透力をもつ
                  <br className="d-none d-lg-inline" />
                  「ニードルの強度」を高めた新技術が適応されました。
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={`${styles.slide} ${styles.slideBg01}`}>
          <div className={`${styles.circle} ${styles.position02}`} />
        </div>

        <div
          className={`${styles.slide} ${styles.slideBg01} ${
            !isComplete && styles.overlay
          } ${isComplete && styles.showAll}`}
          style={{ minHeight }}
        >
          <div className={`${styles.slideContainer} container`}>
            <div className={styles.slideContent}>
              {isComplete ? (
                <div className={`${styles.slideVideo} ${styles.border}`}>
                  <iframe
                    width="560"
                    height="315"
                    // src="https://www.youtube.com/embed/gwsrMT4YFms?autoplay=1&mute=1&playlist=gwsrMT4YFms&loop=1&rel=0"
                    src="https://www.youtube.com/embed/m_Yn3G1YnqU?autoplay=1&mute=1&playlist=m_Yn3G1YnqU&loop=1&rel=0"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              ) : (
                <div className={styles.slideImg}>
                  <img src={images.ytThumb2} alt="マイクロ構造体技術" />
                </div>
              )}

              <div>
                <span className={styles.slideCaption}>
                  アクロパスだけの特許技術
                </span>

                <h2 className={`${styles.slideTitleSm} ${styles.widthLg}`}>
                  <span className={styles.textColored}>
                    溶解性マイクロニードル
                  </span>
                  が
                  <br />
                  気になる場所に潤いを届けます。
                </h2>

                <p className={styles.slideDescription}>
                  アクロパスは、美容成分だけを溶解性マイクロニードルにした「シート状美容液」です。
                  <br className="d-none d-lg-inline" />
                  肌に必要な美容成分の塊を肌の奥深くまで届けることで潤いとハリのある健やかな肌に導きます。
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.slide}>
          <div className={`${styles.slideContainer} container`}>
            <div className="text-center">
              <h2 className={styles.slideTitleMd}>
                さあ、革新的な
                <br className="d-sm-none" />
                美のツールで
                <br />
                お肌本来の美しさを
                <br className="d-sm-none" />
                取り戻そう。
              </h2>
            </div>
          </div>
        </div>
      </div>

      <h1 className="sr-only">アクロパス　マクロニードル</h1>
      <section className={styles.section}>
        <div className="container">
          <h2 className={`${styles.sectionTitle} mb-xl`}>
            世界初、革新の効果を
            <br className="d-sm-none" />
            実感してください。
          </h2>
          <ul className={styles.products}>
            {products.map((product) => (
              <Product key={product.id} {...product} />
            ))}
          </ul>
        </div>
      </section>
    </>
  )
}

Product.propTypes = {
  title: PropTypes.string,
  category: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.shape({}),
}

Product.defaultProps = {
  title: '',
  category: '',
  link: '',
  image: {},
}

export default AcropassMicroneedle

import React from 'react'
import Scroll from 'react-scroll'
import { PropTypes } from 'prop-types'
import styles from './categoryLayout.module.scss'

const { Element } = Scroll

const CategoryLayout = ({ title, id, children, className, style }) => (
  <Element name={id} className={`${styles.root} ${className}`} style={style}>
    <h2 className={styles.title}>{title}</h2>
    <div className={styles.container}>{children}</div>
  </Element>
)

CategoryLayout.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  style: PropTypes.shape({}),
}

CategoryLayout.defaultProps = {
  id: '',
  title: '',
  children: null,
  className: '',
  style: {},
}

export default CategoryLayout

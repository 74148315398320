import React from 'react'
import { PropTypes } from 'prop-types'
import Button from 'components/Button'
import styles from './productTag.module.scss'

const ProductTag = ({ title, isActive, onClick, className, style }) => (
  <Button
    onClick={onClick}
    className={`${styles.root} ${isActive && styles.active} ${className}`}
    style={style}
  >
    {`# ${title}`}
  </Button>
)

ProductTag.propTypes = {
  title: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.shape({}),
}

ProductTag.defaultProps = {
  title: '',
  isActive: false,
  onClick: () => null,
  className: '',
  style: {},
}

export default ProductTag

import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/Modal'
import FontIcon from 'components/FontIcon'
import Button from 'components/Button'
import styles from './sentResetPassword.module.scss'

const SentResetPassword = ({ email, isOpen, toggle, onSubmit }) => (
  <Modal isOpen={isOpen} toggle={toggle} size="md" centered>
    <div className={styles.root}>
      <FontIcon name="paper-plane" className={styles.icon} />
      <h2 className={styles.title}>確認メールを送信しました</h2>
      <p className={styles.desc}>
        <a href={`mailto:${email}`}>{email}</a>
        へ確認メールを送信しました。メール内のリンクよりパスワードをリセットしてください。
      </p>
      <Button
        label="確認"
        className={`btn btn-lg btn-secondary ${styles.submitButton}`}
        onClick={onSubmit}
      />
    </div>
  </Modal>
)

SentResetPassword.propTypes = {
  email: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
}

SentResetPassword.defaultProps = {
  email: '',
  isOpen: false,
  toggle: () => null,
  onSubmit: () => null,
}

export default SentResetPassword

import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { path } from 'utils/const'
import PageWrapper from 'pageComponents/PageWrapper'
import Members from './members'
import Notices from './notices'
import NoticeUpdate from './noticeUpdate'
import Reviews from './reviews'
import ReviewsPerProduct from './reviewsPerProduct'
import ReviewUpdate from './reviewUpdate'

const Dashboard = () => (
  <PageWrapper>
    <Route exact path={path.members}>
      <Members />
    </Route>
    <Route exact path={path.notice}>
      <Notices />
    </Route>
    <Route exact path={path.noticeUpdate}>
      <NoticeUpdate />
    </Route>
    <Route exact path={path.reviews}>
      <Reviews />
    </Route>
    <Route exact path={path.reviewsPerProduct}>
      <ReviewsPerProduct />
    </Route>
    <Route exact path={path.reviewUpdate}>
      <ReviewUpdate />
    </Route>
    <Redirect exact from={path.admin} to={path.notice} />
  </PageWrapper>
)

export default Dashboard

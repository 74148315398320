import prodImages from 'assets/images/products'
import images from 'assets/images'

export default {
  id: 24,
  name: 'アクロパス　素肌美人VITA',
  images: prodImages.vita.slides.map((image, i) => ({
    id: i + 1,
    src: image,
  })),
  illustration: images.acropassProductDetails,
  lpImage: (
    <>
      <img src={prodImages.vita.lps?.[0]} style={{ width: '100%' }} alt="lp" />
      <img src={prodImages.vita.lps?.[1]} style={{ width: '100%' }} alt="lp" />
    </>
  ),
  intro:
    'クリアで健やかなお肌へと導く貼る美容液\n お肌にハリとツヤを与えるビタミンＣ*やヒアルロン酸配合* (*1うるおい成分）\n 日焼けによるシミやそばかすを防ぐ美肌成分がたっぷり含まれており、目元のどんよりにもアプローチ。\n マスク跡の素肌ケアにお勧めです。',
  details: [
    {
      title: '商品名',
      content: 'アクロパス　素肌美人VITA',
    },
    {
      title: 'メーカー',
      content: '株式会社 RAPHAS JAPAN / ラパス ジャパン',
    },
    {
      title: '用途',
      content:
        '日焼けによるシミやそばかす。マスクの跡やくすみ（乾燥による）の気になる気になるところへ',
    },
    {
      title: 'JAN',
      content: '4573161350193',
    },
    {
      title: '容量',
      content: '1箱6パッチ+クレンザー6個入り',
    },
    {
      title: '成分',
      content:
        'ヒアルロン酸Na、ナイアシンアミド、アスコルビン酸、アルブチン、水、マカデミア種子油、クエン酸、カンゾウ根エキス',
    },
    {
      title: '製造国',
      content: '韓国',
    },
    {
      title: '使用の目安',
      content:
        '週１～２回（使い始めの２ヶ月は週２回、３ヵ月目以降は週１回）\n※個人差があります。',
    },
    {
      title: '使用上のご注意',
      content:
        '・水に触れると表面のヒアルロン酸が溶けてしまいますので、必ず水分をふき取ったところに乾いた手でお貼りください。\n・傷、はれもの、湿疹などがある部位にはお使いにならないでください。\n・使用中、または使用後に赤み、はれ、かゆみや刺激などがあらわれた時は、すぐにご使用を中止してください。・使用中、または使用後に赤み、はれ、かゆみや刺激などがあらわれた時は、すぐにご使用を中止してください。',
    },
  ],
  price: {
    amount: '¥ 1,870',
    tax: '（税込）',
  },
}

import prodImages from 'assets/images/products'
import images from 'assets/images'

export default {
  id: 6,
  name: 'カラダアレンジ リカバリー乳酸菌顆粒',
  images: prodImages.recovery.slides.map((image, i) => ({
    id: i + 1,
    src: image,
  })),
  illustration: images.karadaArrangeProductDetails,
  lpImage: (
    <img
      src={prodImages.recovery.lps?.[0]}
      style={{ width: '100%' }}
      alt="lp"
    />
  ),
  intro:
    '「毎日の美容ケアに乳酸菌と美容成分の力を借りて」\n\n カラダに必要な成分を届け、健やかな体を目指す。こだわりの成分が、マスクを外した時に輝けるよう、あなたをお手伝い。\n\n KARADA ARRANGEに含まれている乳酸菌は、\n 健やかさをサポートする『エンテロコッカス・フェカリス菌』なのです。\n そして、\n 本品には、１包あたり乳酸菌がヨーグルト50個分（※）含まれているんです！\n ※ヨーグルト一個（100グラム）に100億個の乳酸菌が含まれるとした場合"',
  details: [
    {
      title: '商品名',
      content: 'カラダアレンジ リカバリー乳酸菌顆粒',
    },
    {
      title: 'メーカー',
      content: '株式会社 RAPHAS JAPAN / ラパス ジャパン',
    },
    {
      title: '用途',
      content:
        '・デリケートな肌のにおいが気になる方\n ・デリケートな肌の健やかさを気にする方\n ・生理中や普段もお腹がよく重くなる方\n ・乾燥しやすく柔軟性を失いやすい方\n ・デリケートな肌のバリアを整えたい方',
    },
    {
      title: 'JAN',
      content: '4573161350391',
    },
    {
      title: '容量',
      content: '14ｇ（1ｇ×14包）',
    },
    {
      title: '成分',
      content:
        'コラーゲンペプチド（国内製造）（ゼラチンを含む）、難消化性デキストリン、乳酸菌末、はっ酵乳パウダー(はっ酵乳、デキストリン、ぶどう糖)（乳成分を含む）、米胚芽抽出物 / ビタミンC、クエン酸、香料、甘味料（ステビア）、乳酸',
    },
    {
      title: '製造国',
      content: '日本',
    },
    {
      title: '使用の目安',
      content:
        '健康補助食品として１日１包を目安にそのまま、または水などでお召し上がりください。',
    },
    {
      title: '使用上のご注意',
      content:
        '【保存方法】\n 直射日光、高温多湿を避けて保存してください。\n \n 【使用上の注意】\n ※食品アレルギーがある方は、原材料をご確認ください。\n ※開封後はお早めにお召し上がりください。\n ※体質・体調に合わない場合は、ご利用を中止してください。\n※小さなお子様の手の届かない所に保管してください。\n※本品は、多量摂取により疾病が治癒したり、より健康が増進するものではありません。',
    },
  ],
  price: {
    amount: '¥ 3,218 ',
    tax: '（税込）',
  },
}

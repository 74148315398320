import React from 'react'
import { path } from 'utils/const'
import images from 'assets/images'
import Icon from 'components/Icon'
import HeroPrimary from 'pageComponents/HeroPrimary'
import BihadaFactorySlider from './BihadaFactorySlider'
import styles from './bihadaFactory.module.scss'

const slides = [
  images.BihadaFactoryMission01,
  images.BihadaFactoryMission02,
  images.BihadaFactoryMission03,
  images.BihadaFactoryMission04,
  images.BihadaFactoryMission05,
  images.BihadaFactoryMission06,
  images.BihadaFactoryMission07,
  images.BihadaFactoryMission08,
  images.BihadaFactoryMission09,
  images.BihadaFactoryMission10,
  images.BihadaFactoryMission11,
  images.BihadaFactoryMission12,
  images.BihadaFactoryMission13,
]

const profiles = [
  {
    id: 1,
    caption: 'HIARU',
    title: 'ヒアルちゃん',
    description:
      '主に肌の中にいながら皮膚組織に水分を供給したり乾燥してしわになっていたりする部分サポートする復元作業したりと肌の弾力！ぷりぷりさを担当している。',
    image: {
      src: images.bihadaFactoryProfileHiaru,
      class: 'hiaru',
    },
    color: 'blue',
  },
  {
    id: 2,
    caption: 'NIDORU',
    title: 'ニドルちゃん',
    description:
      '普段はひあるちゃんを繊細な帽子にして肌が必要とするところに運ぶ役割を担当する。\n独特なのは自分の体もその日肌が必要とする成分でつくりあげて肌に分けてあげる。このため、肌組織はにどるちゃんが来なければ有効成分が届かなくて困るので毎日にどるちゃんを待っている！',
    image: {
      src: images.bihadaFactoryProfileNidoru,
      class: 'nidoru',
    },
    color: 'green',
  },
  {
    id: 3,
    caption: 'IKUMI',
    title: '育美（いくみ）ちゃん',
    description:
      'いくみちゃんは女の人に必要な乳酸菌と有効成分を上手く混合し特別な花束にする。\nこの乳酸菌花束は女性の肌健康と美しさを育てる特別な能力がある。',
    image: {
      src: images.bihadaFactoryProfileIkumi,
      class: 'ikumi',
    },
    color: 'pink',
  },
  {
    id: 4,
    caption: 'MAMORU',
    title: '守（まもる）くん',
    description:
      'まもるくんは、免疫乳酸菌を培養して持ち歩きながら腸内の有益菌に食べさせて私たちの腸を守る役割をする。',
    image: {
      src: images.bihadaFactoryProfileMamoru,
      class: 'mamoru',
    },
    color: 'yellow',
  },
]

const BihadaFactory = () => (
  <>
    {/* Hero */}
    <HeroPrimary />

    {/* Patent section */}
    <section className={styles.brandStorySection}>
      <div className="container">
        <img
          src={images.bihadaFactoryBrandstory}
          style={{ width: '100%' }}
          alt="brand-story"
        />
      </div>
    </section>

    {/* Our mission */}
    <section className={styles.missionSection}>
      {/* <div className="container"> */}
      <h2 className="sr-only">美肌factoryのお仕事</h2>
      <Icon
        name="bihada-factory-mission-title"
        className={`${styles.missionTitle} fill-reset`}
      />
      <span className={styles.caption}>OUR MISSION</span>
      <BihadaFactorySlider slides={slides} />
      {/* </div> */}
    </section>

    {/* Profile */}
    <section className={styles.profileSection}>
      <div className="container">
        <h2 className="sr-only">私達が働いています</h2>
        <Icon
          name="bihada-factory-profile-title"
          className={`${styles.profileTitle} fill-reset`}
        />
        <span className={styles.caption}>PROFILE</span>

        <ul className={styles.profileContent}>
          {profiles.map((profile) => (
            <li
              key={profile.id}
              className={`${styles.profileItem} ${styles[profile.color]}`}
            >
              <div className={styles.profileItemText}>
                <span className={styles.profileItemCaption}>
                  {profile.caption}
                </span>
                <h3 className={styles.profileItemTitle}>{profile.title}</h3>
                <p className={styles.profileItemDescription}>
                  {profile.description}
                </p>
              </div>
              <div
                className={`${styles.profileItemImg} ${
                  styles[profile.image.class]
                }`}
              >
                <img src={profile.image.src} alt={profile.title} />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>

    <div className={styles.pageLinks}>
      <div className={`${styles.pageLinksBlock} ${styles.bgGradientPurple}`}>
        <div className={styles.pageLinksBlockInner}>
          <Icon name="logo-acropass-by-bihada-factory" />
          <a
            href={path.acropassBrandStory}
            className="btn btn-lg btn-outline-light"
          >
            ブランドストーリー
          </a>
          <a
            href={path.acropassMicroneedle}
            className="btn btn-lg btn-outline-light"
          >
            マイクロニードル
          </a>
        </div>
      </div>
      <div
        className={`${styles.pageLinksBlock} ${styles.bgGradientPacificBlue}`}
      >
        <div className={styles.pageLinksBlockInner}>
          <Icon name="logo-karada-arrange-by-bihada-factory" />
          <a
            href={path.karadaArrangeBrandStory}
            className="btn btn-lg btn-outline-light"
          >
            ブランドストーリー
          </a>
          <a
            href={path.karadaArrangeLacticAcidBacteria}
            className="btn btn-lg btn-outline-light"
          >
            乳酸菌
          </a>
        </div>
      </div>
    </div>
  </>
)

export default BihadaFactory

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import options from 'utils/options'
import Button from 'components/Button'
import Card from 'pageComponents/Card'
import styles from './archive.module.scss'

const Category = ({ title, isActive, onClick }) => (
  <li>
    <Button
      className={`${styles.btnCategory} btn ${
        isActive ? 'btn-secondary' : 'btn-outline-secondary'
      }`}
      onClick={onClick}
    >
      {title}
    </Button>
  </li>
)

const Archive = () => {
  const { notices } = useSelector((state) => state.notice)
  const [currentCategory, setCurrentCategory] = useState(
    options.categories[0].value,
  )
  return (
    <>
      <div className={styles.section}>
        <div className="container">
          <h1 className={styles.title}>お知らせ</h1>
          {/* Categories */}
          <div className={styles.categoriesWrap}>
            <ul className={styles.categories}>
              {options.categories.map(({ label, value }) => (
                <Category
                  key={value}
                  title={label}
                  isActive={value === currentCategory}
                  onClick={() => setCurrentCategory(value)}
                />
              ))}
            </ul>
          </div>
          {/* Posts */}
          <div className={styles.content}>
            {notices
              .filter(({ category }) =>
                currentCategory ? category === currentCategory : true,
              )
              .map((notice) => (
                <Card key={notice.id} {...notice} />
              ))}
          </div>
        </div>
      </div>
      {/* Pagination */}
      {/* <nav className={styles.pageNavigation}>
        <div className="container">
          <ul className={styles.pagination}>
            {[1, 2, 3, 4, 5, 6].map((number) => (
              <li key={number}>
                <Button className={styles.active}>{number}</Button>
              </li>
            ))}
          </ul>
        </div>
      </nav> */}
    </>
  )
}

Category.propTypes = {
  title: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
}

Category.defaultProps = {
  title: '',
  isActive: false,
  onClick: () => {},
}

export default Archive

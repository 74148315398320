import prodImages from 'assets/images/products'
import images from 'assets/images'

export default {
  id: 25,
  name: 'アクロパス　BUGパッチ',
  images: prodImages.bug.slides.map((image, i) => ({
    id: i + 1,
    src: image,
  })),
  illustration: images.acropassProductDetails,
  lpImage: (
    <>
      <img src={prodImages.bug.lps?.[0]} style={{ width: '100%' }} alt="lp" />
      <img src={prodImages.bug.lps?.[1]} style={{ width: '100%' }} alt="lp" />
    </>
  ),
  intro:
    'かゆい！と思ったその時に、パッと貼れるパッチです。\n ステロイドと同様の効果があると言われているグリチルリチン酸２Kが配合されており、\n 抗炎症・抗アレルギー効果があります。かゆみを抑える役割を持ち、皮膚を掻きむしって傷を作ることからお肌を守ります。\n お子様にも安心してお使いいただけます。\n アレルギー、アトピー、虫刺され等の様々なかゆみは、私たちの身体に 免疫反応を通して現れます。\n 免疫反応を通して起こる炎症、ヒスタミン等によりお肌が腫れたりかゆみが起こったりするため、かゆみを改善するためには炎症緩和とヒスタミン成分の分泌を抑制することがより重要です。\n 炎症緩和のナイアシンアミド、かゆみ緩和のグリチルリチン酸２ｋ、お肌の鎮静のティーツリー葉油の配合により、かゆみを抑えお肌をすこやかに整えます。',
  details: [
    {
      title: '商品名',
      content: 'アクロパス　BUGパッチ',
    },
    {
      title: 'メーカー',
      content: '株式会社 RAPHAS JAPAN / ラパス ジャパン',
    },
    {
      title: '用途',
      content: 'アレルギー、肌荒れ、赤み、かゆみ、BUG対応にも',
    },
    {
      title: 'JAN',
      content: '4573161350186',
    },
    {
      title: '容量',
      content: '1箱6回分',
    },
    {
      title: '成分',
      content:
        '加水分解ヒアルロン酸、グリチルリチン酸２Ｋ、ナイアシンアミド、ティーツリー葉油\nカフェオイル合成ヒトデカペプチド－９',
    },
    {
      title: '製造国',
      content: '韓国',
    },
    {
      title: '使用の目安',
      content:
        '1回2時間以上（最低2時間以上貼ることを推奨しています。一晩中貼ったままの状態でお休みいただけます）',
    },
    {
      title: '使用上のご注意',
      content:
        '・水に触れると表面のヒアルロン酸が溶けてしまいますので、必ず水分をふき取ったところに乾いた手でお貼りください。\n・使用中、または使用後に赤み、はれ、かゆみや刺激などがあらわれた時は、すぐにご使用を中止してください。',
    },
  ],
  price: {
    amount: '¥ 1,518',
    tax: '（税込）',
  },
}

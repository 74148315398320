import React from 'react'
import Modal from 'components/Modal'
import Button from 'components/Button'
import FontIcon from 'components/FontIcon'
import { PropTypes } from 'prop-types'
import styles from './confirmEmail.module.scss'

const ConfirmEmail = ({ email, isOpen, toggle, onSubmit }) => (
  <Modal
    isOpen={isOpen}
    toggle={toggle}
    size="md"
    centered
    className={styles.modal}
  >
    <div className={styles.root}>
      <div className={styles.container}>
        <FontIcon name="envelope-open-text" className={styles.icon} />
        <h2 className={styles.title}>確認メールを送信しました</h2>
        <p className={styles.desc}>
          <a href={`mailto:${email}`}>{email}</a>
          {'\t'}
          へ確認メールを送信しました。ご確認の上、メールに記載の手順に従って手続きを完了してください。
        </p>
        <Button
          label="ログインページへ戻る"
          className={`btn btn-lg btn-secondary  ${styles.backButton}`}
          onClick={onSubmit}
        />
      </div>
    </div>
  </Modal>
)

ConfirmEmail.propTypes = {
  email: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
}

ConfirmEmail.defaultProps = {
  email: '',
  isOpen: false,
  toggle: () => null,
  onSubmit: () => null,
}

export default ConfirmEmail

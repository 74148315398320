import prodImages from 'assets/images/products'
import images from 'assets/images'

export default {
  id: 19,
  name: '素肌美人ポアケア',
  images: prodImages.poreCare.slides.map((image, i) => ({
    id: i + 1,
    src: image,
  })),
  illustration: images.acropassProductDetails,
  lpImage: (
    <img
      src={prodImages.poreCare.lps?.[0]}
      style={{ width: '100%' }}
      alt="lp"
    />
  ),
  intro:
    '【長時間のマスク着用の「毛穴のひろがり」に!!!】\n 気になる部分に貼るだけの簡単肌悩みケア\n DENマイクロニードル技術から誕生したパッチ型化粧品「アクロパス ポアケア パッチ」は、\n ハマメリスエキスをはじめ、エプロビウムフレイスケリエキス、パンテノールを配合。\n 世界13か国で特許技術を誇るアクロパスならではの高濃度美肌成分配合\n マスクが外せない時代に、新たな悩みが発生！\n マスクの中はうるおっていると思いがちですが、それは大間違い！肌表面が蒸れて、毛穴が広がり、肌荒れの原因に！\n また、マスクを外したときに、一気に肌の内側の水分まで蒸発し、ひどい乾燥状態に陥ります。\n 肌に水分と潤いを与え、内側から弾力とハリが出てきて、毛穴が気にならない状態へ\n 使い続けるほど、キメの整った自信のある素肌に！',
  details: [
    {
      title: '商品名',
      content: '素肌美人ポアケア',
    },
    {
      title: 'メーカー',
      content: '株式会社 RAPHAS JAPAN / ラパス ジャパン',
    },
    {
      title: '用途',
      content: '毛穴の引きしめ、弾力、水分量のアップ、皮脂コントロール',
    },
    {
      title: 'JAN',
      content: '4573161350346 ',
    },
    {
      title: '容量',
      content: '１箱２パウチ入り　※1パウチ１回分（上下1枚づつ）',
    },
    {
      title: '成分',
      content:
        'ヒアルロン酸Ｎａ、BG、水、1,2-ヘキサンジオール、ハマメリスエキス、パンテノール、エプロビウムフレイスケリエキス、アデノシン、セイヨウタンポポエキス葉エキス、アセチルオクタペプチド-3、ザクロ果実エキス',
    },
    {
      title: '製造国',
      content: '韓国',
    },
    {
      title: '使用の目安',
      content:
        '週２～３回（使い始めの２ヶ月は週２回、３ヵ月目以降は週１回）　※2時間以上の付着をお勧めします\n※ご使用感に個人差があります。',
    },
    {
      title: '使用上のご注意',
      content:
        '・水分に触れると表面のヒアルロン酸が溶けてしまいますので、必ず水分や油分をふき取った部位に、乾いた手でお貼りください。\n・傷、はれもの、湿疹などがある部位にはお使いにならないでください。\n・使用中、または使用後に赤み、はれ、かゆみや刺激などがあらわれた時は、すぐにご使用を中止してください。',
    },
  ],
  price: {
    amount: '¥1,936',
    tax: '（税込）',
  },
}

import React, { useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import Button from 'components/Button'
import FontIcon from 'components/FontIcon'
import styles from './dropzone.module.scss'

const Dropzone = ({
  label,
  file,
  setFile,
  imageUrl,
  className,
  maxSize,
  disabled,
  error,
}) => {
  // States
  const [err, setErr] = useState('')

  // Handlers
  const handleDrop = (acceptedFiles, rejectedFiles) => {
    if (disabled) return

    // Handle error
    setErr('')

    if (rejectedFiles.length) {
      setErr(
        'ファイルが読み込めませんでした。500KB以下のjpgまたはpngファイルで再度お試しください。',
      )
    }

    const acceptedFile = acceptedFiles[0]

    // Return if no file accepted
    if (!acceptedFile) return

    // Set acceptedFile with image url for preview
    setFile(
      Object.assign(acceptedFile, {
        preview: URL.createObjectURL(acceptedFile),
      }),
    )
  }

  const handleRemove = () => setFile({})

  // Dropzone settings
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: 'image/jpeg, image/png',
    multiple: false,
    maxSize, // 500kb as default
    onDrop: handleDrop,
  })

  // Styles
  const dropzoneClassName = useMemo(
    () =>
      `${styles.dropzone} ${isDragActive ? styles.dragActive : ''} ${
        isDragAccept ? styles.dragAccept : ''
      } ${isDragReject ? styles.dragReject : ''} ${
        error ? styles.dropzoneInvalid : ''
      }`,
    [isDragActive, isDragAccept, isDragReject, error],
  )

  useEffect(() => {
    setErr(error)
  }, [error])

  return (
    <div className={className}>
      {label && <span className="input-label">{label}</span>}

      {!file || (file && Object.keys(file).length === 0) ? (
        <div
          {...getRootProps({ className: dropzoneClassName })}
          style={
            imageUrl
              ? {
                  backgroundImage: `url("${imageUrl}")`,
                  backgroundPosition: 'center',
                  opacity: 0.4,
                }
              : {}
          }
        >
          <input {...getInputProps()} />
          <FontIcon name="image" className={styles.imgIcon} />
        </div>
      ) : (
        <>
          <div className={styles.preview}>
            <img src={file.preview} alt="preview" />
          </div>
          <div className={styles.actionBtns}>
            <Button onClick={handleRemove}>削除</Button>
            <Button onClick={handleRemove}>変更</Button>
          </div>
        </>
      )}

      {err && <span className="invalid-feedback d-block">{err}</span>}
    </div>
  )
}

Dropzone.propTypes = {
  label: PropTypes.string,
  file: PropTypes.shape({}).isRequired,
  imageUrl: PropTypes.string,
  setFile: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  maxSize: PropTypes.number,
}

Dropzone.defaultProps = {
  label: '',
  imageUrl: '',
  disabled: false,
  className: '',
  maxSize: 500000,
}

export default Dropzone

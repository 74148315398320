/* eslint-disable react/no-danger */
import React from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styles from './post.module.scss'

const Post = () => {
  const params = useParams()
  const noticeId = params?.id || 0
  const { notices } = useSelector((state) => state.notice)
  const notice = notices.find(({ id }) => id === noticeId)

  return (
    <div className={styles.root}>
      <div className="container">
        <div className="container-inner text-center">
          <article>
            <h1 className={styles.entryTitle}>{notice?.title}</h1>
            <span className={styles.date}>
              {moment(notice?.published_at).format('YYYY・M・D')}
            </span>

            <div className={styles.entryThumbnail}>
              <img src={notice?.image_url} alt="" />
            </div>

            <div
              className={styles.entryContent}
              dangerouslySetInnerHTML={{
                __html: notice?.content,
              }}
            />
          </article>
        </div>
      </div>
    </div>
  )
}

export default Post

import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import products from 'data/products'
import Icon from 'components/Icon'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { Collapse } from 'reactstrap'
import Input from 'components/Input'
import Button from 'components/Button'
import config from 'utils/config'
import { actions } from 'slices/review.slice'
import ProductTable from './ProductTable'
import breakpoints from '../../styles/_breakpoints.module.scss'
import styles from './product.module.scss'
import ProductSlider from './ProductSlider'

const Product = () => {
  const ref = useRef(null)
  const params = useParams()
  const productId = Number(params?.id) || 0
  const product = products.find(({ id }) => id === productId)

  const { headerHeight } = useSelector((state) => state.status)

  // States
  const isLaptop = useMediaQuery({ minWidth: breakpoints.lg })
  const isTablet = useMediaQuery({ minWidth: breakpoints.md })
  const isPhone = useMediaQuery({ maxWidth: breakpoints.sm })

  const [ctaHeight, setCtaHeight] = useState(0)
  const [count, setCount] = useState(1)
  const [isOpen, setIsOpen] = useState(false)
  const [reviews, setReviews] = useState([])

  // Handlers
  const handleChange = (e) => setCount(e.target.value)

  const handleClick = () => setIsOpen((prevState) => !prevState)

  const handleSubmit = (e) => {
    e.preventDefault()

    // redirect to the direct purchase page
    window.location.href = isPhone
      ? `${config.app.shopUrl}/smp/cart/direct.php?param[]=${product.id}|${count}||`
      : `${config.app.shopUrl}/cart/direct.php?param[]=${product.id}|${count}||`
  }

  useEffect(() => {
    actions.getReviews(productId).then(setReviews)
    const resizeObserver = new ResizeObserver((entries) =>
      setCtaHeight(entries[0].target.offsetHeight),
    )
    resizeObserver.observe(ref.current)
  }, [])

  return (
    <>
      {/* Hero */}
      <div
        style={{
          minHeight: headerHeight ? `calc(100vh - ${headerHeight}px)` : '',
        }}
        className={styles.hero}
      >
        <ProductSlider images={product.images} />
        <div className={`${styles.heroContainer} container`}>
          <h1 className={styles.heroTitle}>{product.name}</h1>
          <div className={styles.heroContent}>
            <div className={styles.price}>
              <span className={styles.priceMain}>{product.price.amount}</span>
              <span className={styles.priceSub}>{product.price.tax}</span>
            </div>
            <form
              id="add-to-cart-form"
              className={styles.heroForm}
              onSubmit={handleSubmit}
            >
              <Input
                label="数量"
                type="number"
                value={count}
                required
                size="sm"
                className={styles.inputWrap}
                onChange={handleChange}
              />
            </form>
            <Button
              type="submit"
              form="add-to-cart-form"
              className={`${styles.btnSubmit} btn btn-lg btn-primary`}
            >
              バッグに入れる
            </Button>
          </div>
          <span className={styles.heroNotes}>
            ※10,000円以上のご注文で国内送料が無料になります。
          </span>
        </div>
      </div>

      {/* Features */}
      <section
        style={{
          minHeight: isLaptop && `calc(100vh - ${headerHeight + ctaHeight}px)`,
        }}
        className={styles.features}
      >
        <img
          src={product.illustration}
          style={{ width: '100%' }}
          alt="illustration"
        />
      </section>

      {product.lpImage && (
        <div className="container">
          {product.lpImage}
          {/* <img src={product.lpImage} style={{ width: '100%' }} alt="lp" /> */}
        </div>
      )}

      <div>
        {/* Details */}
        <section className={styles.details}>
          <div className="container">
            <div className={styles.detailsContainerInner}>
              <h2 className="sr-only">商品詳細</h2>

              {isTablet ? (
                <ProductTable intro={product.intro} details={product.details} />
              ) : (
                <>
                  <ProductTable
                    intro={product.intro}
                    details={
                      isOpen ? product.details : product.details.slice(0, 3)
                    }
                    className="mb-3"
                  />
                  <Button
                    onClick={handleClick}
                    className={`${styles.btnToggle} ${
                      isOpen ? styles.active : ''
                    }`}
                  >
                    {isOpen ? '詳細を隠す' : 'もっと見る'}
                  </Button>
                  <Collapse isOpen={isOpen}>
                    <ProductTable
                      data={product.details.slice(3)}
                      className="mt-3"
                    />
                  </Collapse>
                </>
              )}
            </div>
          </div>
        </section>

        {/* Reviews */}
        {Array.isArray(reviews) && reviews.length > 0 && (
          <section className={styles.reviews}>
            <div className="container">
              <div className="container-inner">
                <h2 className={styles.reviewsTitle}>製品レビュー</h2>
                <ul className={styles.reviewsList}>
                  {reviews.map((review) => (
                    <li key={review.id} className={styles.reviewsItem}>
                      <div className={styles.reviewsItemRating}>
                        {[...Array(5)].map((val, i) => (
                          <Icon
                            key={i.toString()}
                            name="heart"
                            className={
                              i <= review.score - 1 ? styles.filled : ''
                            }
                          />
                        ))}
                      </div>
                      <span>{review.name}</span>
                      <span>
                        {moment(review.created_at).format('YY-MM-DD')}
                      </span>
                      <span className={styles.reviewsItemContent}>
                        {review.content}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </section>
        )}

        {/* Call-to-action bar */}
        <div ref={ref} className={styles.cta}>
          <div className="container">
            <div className={styles.ctaContent}>
              <span className={styles.ctaTitle}>{product.name}</span>
              <div className={styles.price}>
                <span className={styles.priceMain}>{product.price.amount}</span>
                <span className={styles.priceSub}>{product.price.tax}</span>
              </div>
              <form className={styles.formAddToCart} onSubmit={handleSubmit}>
                <Input
                  label="数量"
                  type="number"
                  value={count}
                  required
                  size="sm"
                  className={styles.inputWrap}
                  onChange={handleChange}
                />
                <Button type="submit" className="btn btn-lg btn-primary">
                  バッグに入れる
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Product.propTypes = {
  match: PropTypes.shape({}),
}

Product.defaultProps = {
  match: {},
}

export default Product

import apollo from 'utils/apollo'
import { gql } from '@apollo/client'
import { createSlice } from '@reduxjs/toolkit'
import { convertToBase64 } from 'utils/helpers'

// ------------------------------------
// State
// ------------------------------------

const initialState = {
  notices: [],
}

// ------------------------------------
// Slices
// -----------------------------------

const slice = createSlice({
  name: 'notice',
  initialState,
  reducers: {
    setNotices: (state, action) => ({
      ...state,
      notices: action.payload,
    }),
  },
})

// ------------------------------------
// Helpers
// ------------------------------------

const formatCreateNoticeInput = async (input) => {
  try {
    const image_base_64 = await convertToBase64(input?.file)
    const newInput = {
      ...input,
      image_base_64,
      category: input?.category?.value || 1,
      status: input?.status?.value || 0,
      published_at: input?.published_at.getTime(),
    }
    delete newInput.id
    delete newInput.file
    return newInput
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

const formatUpdateNoticeInput = async (input) => {
  try {
    const image_base_64 = input?.file
      ? await convertToBase64(input?.file)
      : null
    const newInput = {
      ...input,
      image_base_64,
      category: input?.category?.value || 1,
      status: input?.status?.value || 0,
      published_at:
        typeof input?.published_at !== 'number'
          ? input?.published_at.getTime()
          : input?.published_at,
    }
    delete newInput.file
    if (newInput.image_base_64) delete newInput.image_base_64
    return newInput
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

// ------------------------------------
// Actions
// -----------------------------------

/**
 * get the notice
 * the endpoint is called when
 * updating the notice from admin page
 * @param {string} id
 * @returns
 */
export const getNotice = async (id) => {
  try {
    const { data } = await apollo.query({
      query: gql`
        query notice($id: String!) {
          notice(id: $id) {
            id
            image_url
            category
            status
            title
            content
            published_by {
              id
              name
            }
            published_at
            created_at
            updated_at
          }
        }
      `,
      variables: {
        id,
      },
    })

    return data?.notice
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

/**
 * get public and private notice
 * the endpoint is called when presenting
 * all notices in admin pages
 * @returns
 */
export const getNotices = async () => {
  try {
    const { data } = await apollo.query({
      query: gql`
        query notices {
          notices {
            id
            image_url
            category
            status
            title
            content
            published_by {
              id
              name
            }
            published_at
            created_at
            updated_at
          }
        }
      `,
    })

    return data?.notices
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

export const setPublicNotices = () => async (dispatch) => {
  try {
    const { data } = await apollo.query({
      query: gql`
        query publicNotices {
          publicNotices {
            id
            image_url
            category
            status
            title
            content
            published_by {
              id
              name
            }
            published_at
            created_at
            updated_at
          }
        }
      `,
    })

    // store public notices
    const { setNotices } = slice.actions
    const values = data?.publicNotices || []
    return dispatch(setNotices(values))
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

export const createNotice = async (input) => {
  try {
    // format input
    const formattedInput = await formatCreateNoticeInput(input)
    const variables = { input: formattedInput }

    // create notice
    const { data } = await apollo.mutate({
      mutation: gql`
        mutation createNotice($input: NoticeInput!) {
          createNotice(input: $input) {
            id
          }
        }
      `,
      variables,
    })
    return data?.createNotice
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

const updateNotice = async (input) => {
  try {
    // format input
    const formattedInput = await formatUpdateNoticeInput(input)
    const variables = { input: formattedInput }

    // create notice
    const { data } = await apollo.mutate({
      mutation: gql`
        mutation updateNotice($input: NoticeInput!) {
          updateNotice(input: $input) {
            id
          }
        }
      `,
      variables,
    })
    return data?.updateNotice
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

const deleteNotice = async (id) => {
  try {
    // delete notice
    const { data } = await apollo.mutate({
      mutation: gql`
        mutation deleteNotice($id: String!) {
          deleteNotice(id: $id)
        }
      `,
      variables: {
        id,
      },
    })
    return data
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

// ------------------------------------
// Exports
// ------------------------------------

export const actions = {
  ...slice.actions,
  getNotice,
  getNotices,
  setPublicNotices,
  createNotice,
  updateNotice,
  deleteNotice,
}

export default slice.reducer

import logo from './logo-temporary.png'
import logoRaphas from './logo-raphas.png'

import placeholder from './placeholder-temporary.png'
import media01 from './media01-temporary.png'
import homeAcropassHiaru from './home-acropass-hiaru.png'
import homeAcropassNidoru from './home-acropass-nidoru.png'
import homeCategorySkinCare from './home-category-skin-care-temporary.jpg'
import homeCategoryWhiteningCare from './home-category-whitening-care-temporary.jpg'
import homeCategoryWrinkleCare from './home-category-wrinkle-care-temporary.jpg'
import homeCategoryBroma from './home-category-broma-temporary.jpg'

import bihadaFactoryHeroHiaru from './bihada-factory-top-hiaru.png'
import bihadaFactoryHeroNidoru from './bihada-factory-top-nidoru.png'
import bihadaFactoryHeroMamoru from './bihada-factory-top-mamoru.png'
import bihadaFactoryHeroHiaruPc from './bihada-factory-top-hiaru-pc.png'
import bihadaFactoryHeroNidoruPc from './bihada-factory-top-nidoru-pc.png'
import bihadaFactoryHeroIkumiPc from './bihada-factory-top-ikumi-pc.png'
import bihadaFactoryHeroMamoruPc from './bihada-factory-top-mamoru-pc.png'
import BihadaFactoryMission01 from './bihada-factory-mission-01.jpg'
import BihadaFactoryMission02 from './bihada-factory-mission-02.jpg'
import BihadaFactoryMission03 from './bihada-factory-mission-03.jpg'
import BihadaFactoryMission04 from './bihada-factory-mission-04.jpg'
import BihadaFactoryMission05 from './bihada-factory-mission-05.jpg'
import BihadaFactoryMission06 from './bihada-factory-mission-06.jpg'
import BihadaFactoryMission07 from './bihada-factory-mission-07.jpg'
import BihadaFactoryMission08 from './bihada-factory-mission-08.jpg'
import BihadaFactoryMission09 from './bihada-factory-mission-09.jpg'
import BihadaFactoryMission10 from './bihada-factory-mission-10.jpg'
import BihadaFactoryMission11 from './bihada-factory-mission-11.jpg'
import BihadaFactoryMission12 from './bihada-factory-mission-12.jpg'
import BihadaFactoryMission13 from './bihada-factory-mission-13.jpg'
import bihadaFactoryProfileHiaru from './bihada-factory-profile-hiaru.png'
import bihadaFactoryProfileNidoru from './bihada-factory-profile-nidoru.png'
import bihadaFactoryProfileIkumi from './bihada-factory-profile-ikumi.png'
import bihadaFactoryProfileMamoru from './bihada-factory-profile-mamoru.png'
import bihadaFactoryBrandstory from './bihada-factory-brandstory.jpg'

import history from './ac-brand-tech4.jpg'
import about from './img-about.jpg'

import brandStoryAcro from './bs-acro.png'
import brandStoryPass from './bs-pass.png'
import brandStoryKarada from './bs-karada.png'
import brandStoryArrange from './bs-arrange.png'
import brandStoryPlus from './bs-plus.png'
import brandStoryHiaru from './brand-story-hiaru.png'
import brandStoryNidoru from './brand-story-nidoru.png'
import brandStoryIkumi from './brand-story-ikumi.png'
import brandStoryMamoru from './brand-story-mamoru.png'

import acropassAcCarePlus from './acropass-ac-care-plus-temporary.png'
import acropassAcCare from './acropass-ac-care-temporary.png'
import acropassAcCareSet from './acropass-ac-care-set-of-3-temporary.png'
import acropassApplezone from './acropass-applezone-temporary.png'
import acropassBugPatch from './acropass-bug-patch-temporary.png'
import acropassDiamond from './acropass-diamond-temporary.png'
import acropassLineCarePatch from './acropass-line-care-patch-temporary.png'
import acropassSpotEraserVita from './acropass-spot-eraser-vita-temporary.png'
import acropassTech from './ac-brand-tech2.jpg'

import karadaArrangeRecoveryLacticAcidBacteriaPowder from './karada-arrange-recovery-lactic-acid-bacteria-powder-temporary.png'
import karadaArrangeSmoothlyLacticAcidBacteriaSyrup from './karada-arrange-smoothly-lactic-acid-bacteria-syrup-temporary.png'
import karadaArrangeStabilityLacticAcidBacteriaPowder from './karada-arrange-stability-lactic-acid-bacteria-powder-temporary.png'
import KaradaArrangeLacticAcidBacteria from './karada-arrange-brand-story-top-01.jpg'

import category from './img-acro-04.jpg'

import product01 from './img-acro-05.jpg'
import productFeature01 from './acpc_home_brand.jpg'
import productFeature02 from './img-acro-01.jpg'

import acropassProductDetails from './acropass-product-details.jpg'
import karadaArrangeProductDetails from './karada-arrange-product-details.jpg'

import ytThumb1 from './yt-thum-1.jpg'
import ytThumb2 from './yt-thum-2.jpg'

const images = {
  logo,
  logoRaphas,
  placeholder,
  media01,
  homeAcropassHiaru,
  homeAcropassNidoru,
  homeCategorySkinCare,
  homeCategoryWhiteningCare,
  homeCategoryWrinkleCare,
  homeCategoryBroma,
  bihadaFactoryHeroHiaru,
  bihadaFactoryHeroNidoru,
  bihadaFactoryHeroMamoru,
  bihadaFactoryHeroHiaruPc,
  bihadaFactoryHeroNidoruPc,
  bihadaFactoryHeroIkumiPc,
  bihadaFactoryHeroMamoruPc,
  BihadaFactoryMission01,
  BihadaFactoryMission02,
  BihadaFactoryMission03,
  BihadaFactoryMission04,
  BihadaFactoryMission05,
  BihadaFactoryMission06,
  BihadaFactoryMission07,
  BihadaFactoryMission08,
  BihadaFactoryMission09,
  BihadaFactoryMission10,
  BihadaFactoryMission11,
  BihadaFactoryMission12,
  BihadaFactoryMission13,
  bihadaFactoryProfileHiaru,
  bihadaFactoryProfileNidoru,
  bihadaFactoryProfileIkumi,
  bihadaFactoryProfileMamoru,
  bihadaFactoryBrandstory,
  history,
  about,
  brandStoryAcro,
  brandStoryPass,
  brandStoryPlus,
  brandStoryKarada,
  brandStoryArrange,
  brandStoryHiaru,
  brandStoryNidoru,
  brandStoryIkumi,
  brandStoryMamoru,
  acropassAcCarePlus,
  acropassAcCare,
  acropassAcCareSet,
  acropassApplezone,
  acropassBugPatch,
  acropassDiamond,
  acropassLineCarePatch,
  acropassSpotEraserVita,
  karadaArrangeRecoveryLacticAcidBacteriaPowder,
  karadaArrangeSmoothlyLacticAcidBacteriaSyrup,
  karadaArrangeStabilityLacticAcidBacteriaPowder,
  KaradaArrangeLacticAcidBacteria,
  acropassTech,
  category,
  product01,
  productFeature01,
  productFeature02,
  acropassProductDetails,
  karadaArrangeProductDetails,
  ytThumb1,
  ytThumb2,
}

export default images

import React, { useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { useDispatch } from 'react-redux'
import { actions } from 'slices/member.slice'
import Modal from 'components/Modal'
import Input from 'components/Input'
import Select from 'components/Select'
import Button from 'components/Button'
import FontIcon from 'components/FontIcon'
import ErrorBox from 'components/ErrorBox'
import option, { findOption } from 'utils/options'
import styles from './modalInvite.module.scss'

const ModalInvite = ({ member, isOpen, toggle }) => {
  const dispatch = useDispatch()

  // ------------------------------------
  // States
  // ------------------------------------
  const [inputs, setInputs] = useState({
    name: '',
    email: '',
    role: option.roles[0],
  })
  const [isValidated, setIsValidated] = useState(false)
  const [errors, setErrors] = useState({})
  const [resErr, setResError] = useState('')
  const [isLoading, setLoading] = useState(false)

  // ------------------------------------
  // Handlers
  // ------------------------------------
  const handleChange = ({ target: { name, value } }) => {
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }))
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }))
    setResError('')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    setIsValidated(true)

    const form = e.currentTarget
    // eslint-disable-next-line no-useless-return
    if (!form.checkValidity()) return

    // loading action
    setLoading(true)

    try {
      if (member) {
        // update member role
        await dispatch(
          actions.updateMemberRole({
            id: member?.id,
            role: inputs?.role?.value,
          }),
        )
      } else {
        // invite member
        await dispatch(
          actions.inviteMember({
            name: inputs?.name,
            email: inputs?.email,
            role: inputs?.role?.value,
          }),
        )
      }

      // reload data
      dispatch(actions.getMembers())

      setLoading(false)
      setIsValidated(false)
      setResError('')
      toggle()
    } catch (err) {
      setLoading(false)
      setResError(
        member
          ? '権限の変更にに失敗しました。お手数ですが、時間をおいて再度お試しください。'
          : '管理者招待に失敗しました。お手数ですが、時間をおいて再度お試しください。',
      )
    }
  }

  // ------------------------------------
  // Subscriptions
  // ------------------------------------
  useEffect(() => {
    setInputs({
      name: member?.name,
      email: member?.email,
      role: findOption(option.roles, member?.role) || option.roles[0],
    })
  }, [member])

  // ------------------------------------
  // Renderings
  // ------------------------------------
  return (
    <Modal size="md" isOpen={isOpen} toggle={toggle} centered>
      <div className={styles.inner}>
        {resErr && <ErrorBox>{resErr}</ErrorBox>}
        <h3 className={styles.title}>
          {member ? '権限を編集する' : '管理者を招待する'}
        </h3>
        <form
          onSubmit={handleSubmit}
          className={`${styles.form} ${isValidated ? 'was-validated' : ''}`}
          noValidate
        >
          <Input
            label="お名前"
            type="text"
            name="name"
            pattern="^(.|\s)*\S(.|\s)*$"
            error="お名前を入力してください"
            value={inputs.name}
            onChange={handleChange}
            disabled={!!member}
            required
          />
          <Input
            label="メールアドレス"
            type="email"
            name="email"
            pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
            error="メールアドレスを入力してください"
            value={inputs.email}
            onChange={handleChange}
            disabled={!!member}
            required
          />
          <Select
            label="ユーザータイプ"
            name="role"
            options={option.roles}
            value={inputs.role}
            onChange={handleChange}
            error={errors.role}
            required
          />
          <Button
            type="submit"
            className={`btn btn-primary ${styles.btnSubmit}`}
            isLoading={isLoading}
          >
            <FontIcon
              name={member ? 'check' : 'paper-plane'}
              style={{ marginRight: 8 }}
            />
            <span>{member ? 'ユーザータイプを更新する' : '招待を出す'}</span>
          </Button>
        </form>
      </div>
    </Modal>
  )
}

ModalInvite.propTypes = {
  member: PropTypes.shape({}),
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
}

ModalInvite.defaultProps = {
  member: null,
  isOpen: false,
  toggle: () => null,
}

export default ModalInvite

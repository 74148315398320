import { gql } from '@apollo/client'
import apollo from 'utils/apollo'
import { createSlice } from '@reduxjs/toolkit'

// ------------------------------------
// State
// ------------------------------------

const initialState = {
  members: [],
}

// ------------------------------------
// Slices
// -----------------------------------

const slice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    setMembers: (state, action) => ({
      ...state,
      members: action.payload.members,
    }),
  },
})

// ------------------------------------
// Actions
// -----------------------------------

const getMembers = () => async (dispatch) => {
  // get user
  const { data } = await apollo.query({
    query: gql`
      query users {
        users {
          id
          name
          email
          role
          created_at
          updated_at
        }
      }
    `,
  })
  return dispatch(
    slice.actions.setMembers({
      members: data?.users || [],
    }),
  )
}

const inviteMember = (input) => () =>
  new Promise(async (resolve, reject) => {
    try {
      // invite member
      const { data } = await apollo.mutate({
        mutation: gql`
          mutation inviteUser($input: InviteUserInput!) {
            inviteUser(input: $input) {
              id
              name
              role
            }
          }
        `,
        variables: { input },
      })
      resolve(data?.inviteUser)
    } catch (err) {
      reject(err)
    }
  })

const updateMemberRole = (input) => () =>
  new Promise(async (resolve, reject) => {
    try {
      // invite user
      const { data } = await apollo.mutate({
        mutation: gql`
          mutation updateUserRole($input: UpdateUserRoleInput!) {
            updateUserRole(input: $input) {
              id
            }
          }
        `,
        variables: { input },
      })
      resolve(data?.updateUserRole)
    } catch (err) {
      reject(err)
    }
  })

const deleteMember = (id) => () =>
  new Promise(async (resolve, reject) => {
    try {
      // invite member
      const { data } = await apollo.mutate({
        mutation: gql`
          mutation deleteUser($id: String!) {
            deleteUser(id: $id)
          }
        `,
        variables: { id },
      })
      resolve(data?.deleteUser)
    } catch (err) {
      reject(err)
    }
  })

// ------------------------------------
// Exports
// ------------------------------------

export const actions = {
  ...slice.actions,
  getMembers,
  inviteMember,
  updateMemberRole,
  deleteMember,
}

export default slice.reducer

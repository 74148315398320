import React from 'react'
import { useSelector } from 'react-redux'
import Icon from 'components/Icon'
import HeroPrimary from 'pageComponents/HeroPrimary'
import images from 'assets/images'
import styles from './home.module.scss'
import HomeInformation from './HomeInformation'
import HomeCategory from './HomeCategory'

const Home = () => {
  const { headerHeight } = useSelector((state) => state.status)
  const minHeight = headerHeight ? `calc(100vh - ${headerHeight}px)` : ''

  return (
    <div>
      {/* Hero */}
      <HeroPrimary />

      {/* Acropass */}
      <section
        style={{ minHeight }}
        className={`${styles.section} ${styles.acropass}`}
      >
        <div className={styles.acropassContent}>
          <div className={`${styles.sectionText} ${styles.acropassText}`}>
            <h2 className="sr-only">ACROPASS</h2>
            <Icon name="logo-acropass" className="fill-reset" />
            <p className={styles.acropassDescription}>
              有効成分を
              <br />
              ダイレクトに届ける
            </p>
          </div>

          <img
            src={images.homeAcropassNidoru}
            alt="Nidoru"
            className={`${styles.acropassCharacter} ${styles.acropassNidoru}`}
          />
          <img
            src={images.homeAcropassHiaru}
            alt="Hiaru"
            className={`${styles.acropassCharacter} ${styles.acropassHiaru}`}
          />
        </div>
      </section>

      {/* Karada Arrange */}
      <section
        style={{ minHeight }}
        className={`${styles.section} ${styles.karadaArrange}`}
      >
        <div className={styles.karadaArrangeContent}>
          <div className={`${styles.sectionText} ${styles.karadaArrangeText}`}>
            <h2 className="sr-only">ACROPASS</h2>
            <Icon name="logo-karada-arrange" className="fill-reset" />
            <p className={styles.karadaArrangeDescription}>
              カラダの中から
              <br />
              健康的に美しく整える
            </p>
          </div>

          <img
            src={images.bihadaFactoryProfileIkumi}
            alt="Ikumi"
            className={`${styles.karadaArrangeCharacter} ${styles.karadaArrangeIkumi}`}
          />
          <img
            src={images.bihadaFactoryHeroMamoruPc}
            alt="Mamoru"
            className={`${styles.karadaArrangeCharacter} ${styles.karadaArrangeMamoru}`}
          />
        </div>
      </section>

      {/* Information */}
      <HomeInformation />

      {/* Categories */}
      <HomeCategory />
    </div>
  )
}

export default Home

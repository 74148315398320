import React from 'react'
import styles from './pageWrapper.module.scss'
import Header from './Header'
import Sidebar from './Sidebar'
import Main from './Main'

const PageWrapper = ({ children }) => (
  <div className={styles.root}>
    <Header />
    <Sidebar />
    <Main>{children}</Main>
  </div>
)

export default PageWrapper

import React from 'react'
import { useHistory } from 'react-router-dom'
import Modal from 'components/Modal'
import Button from 'components/Button'
import FontIcon from 'components/FontIcon'
import { PropTypes } from 'prop-types'
import styles from './inquiryComplete.module.scss'

const InquiryComplete = ({ isOpen, toggle }) => {
  const history = useHistory()
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md" centered>
      <div className={styles.root}>
        <div className={styles.container}>
          <FontIcon name="envelope-open-text" className={styles.icon} />
          <h2 className={styles.title}>お問い合わせありがとうございます。</h2>
          <p className={styles.desc}>
            ご記入いただいた情報は無事送信されました。
            <br />
            確認のため、お客様に自動返信メールをお送りしております。
          </p>
          <Button
            label="トップページに戻る"
            className={styles.backButton}
            onClick={() => {
              toggle()
              history.push('/')
            }}
          />
        </div>
      </div>
    </Modal>
  )
}

InquiryComplete.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
}

InquiryComplete.defaultProps = {
  isOpen: false,
  toggle: () => null,
}

export default InquiryComplete

import React from 'react'
import PropTypes from 'prop-types'
import styles from './pageLayout.module.scss'

const PageLayout = ({ title, description, backgroundImage, children }) => (
  <div className={styles.root}>
    <div
      style={{ backgroundImage: `url(${backgroundImage})` }}
      className={styles.hero}
    />
    <div className={styles.content}>
      <div className="container">
        <div className={styles.header}>
          <h1>{title}</h1>
          <p className={styles.description}>{description}</p>
        </div>
        {children}
      </div>
    </div>
  </div>
)

PageLayout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  backgroundImage: PropTypes.string,
  children: PropTypes.node,
}

PageLayout.defaultProps = {
  title: '',
  description: '',
  backgroundImage: '',
  children: null,
}

export default PageLayout

import React from 'react'
import { PropTypes } from 'prop-types'
import styles from './privacy.module.scss'

const Privacy = ({ className, style }) => (
  <div className={`${styles.root} ${className}`} style={style}>
    <div className="container">
      <div className="container-inner">
        <h1 className={styles.title}>個人情報の取り扱いについて</h1>
        <p className={styles.description}>
          株式会社 RAPHAS
          JAPAN（以下、「当社」という）は、情報通信網利用促進及び情報保護などに関する法律、個人情報保護法などの個人情報に関連する法令上の規定を遵守しており、個人情報保護方針を制定して、利用者の権益保護に最善を尽くします。
          <br />
          当社は、個人情報の取り扱い方針を通じて利用者の個人情報がどのような用途と方式で利用されており、個人情報保護のために、会社がどのような措置をとることをお知らせします。
          <br />
          当社は、個人情報の取り扱い方針を改定する場合は、Webサイトのお知らせ（または個別公知）を介して通知します。
        </p>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>
            1. 収集する個人情報の項目と収集方法
          </h2>
          <ol className={styles.ol}>
            <li data-number="1．">（１）収集する個人情報の項目</li>
            <p>
              当社は、会員登録、会員の購入、相談、不良利用の防止などのために以下のような個人情報を収集しています。
              <br />
              -
              必須項目：名前、ID、パスワード、誕生日、性別、E-mail　アドレス、IPアドレス
              <br />-
              選択項目：個人的なサービスを提供するために会社が必要とする情報
            </p>
            <li data-number="2．">（２）収集方法</li>
            <p>
              当社は、利用者が会員サービスを利用するために会員に加入する際、サービスを提供するために必要な情報をオンライン上で入力していただきます。
              <br />
              また、サービス内でのアンケート調査やイベントの時、統計分析や景品提供などのために一部の方に個人情報の入力を要求することがあります。
              <br />
              しかし、利用者の基本的人権侵害の恐れがある個人情報（人種や民族、思想および信条、出身地および本籍地、政治的性向と犯罪記録、健康状態、および性生活など）は収集しません。
              <br />
              やむを得ず収集する場合、利用者の事前の同意を必ず得ます。
            </p>
          </ol>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>2. 個人情報の収集及び利用目的</h2>
          <ol className={styles.ol}>
            <li data-number="1．">（１）新規サービスの開発</li>
            <p>
              利用者が提供した個人情報をもとに、より便利なサービスを開発することがあります。
              <br />
              当社は、新規サービスの開発やコンテンツの拡充の際に既存の利用者が会社に提供した個人情報をもとに、開発すべきサービスの優先順位をより効率的に定め、
              <br />
              会社は利用者が必要とするコンテンツを合理的に選択して提供します。
            </p>
            <li data-number="2．">（２）会員管理</li>
            <p>
              会員制サービス利用による本人確認、個人識別、不良会員の不正利用防止と不正使用防止、加入意思の確認、年齢確認、満14歳未満の児童の個人情報を収集する際、
              <br />
              法定代理人の同意が必要かどうかを確認、苦情処理、告知事項の伝達
            </p>

            <li data-number="3．">（３）マーケティング及び広告への活用</li>
            <p>
              新規サービスの開発やイベントに伴う情報伝達と合わせたサービスを提供し、人口統計学的特性に応じたサービスの提供と広告掲載、
              <br />
              接続頻度の把握または会員のサービス利用に関する統計
            </p>
          </ol>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>
            ３．個人情報の破棄手続き及び方法
          </h2>
          <p>
            利用者の個人情報は、個人情報の収集及び利用目的が達成された段階で、遅滞なく、次のような方法で破棄します。
          </p>
          <ol className={styles.ol}>
            <li data-number="1．">（１）破棄の流れ</li>
            <p>
              利用者がサービス利用などのために入力した情報は、目的が達成された後、別のDBに移され（紙の場合別途の書類箱）内部方針及びその他の関連法令による情報保護事由に基づいて（保有及び利用期間を参照）一定期間保存された後、破棄されます。別途DBに移された個人情報は、法律による場合でない限り、保有以外の他の目的に利用しません。
            </p>
            <li data-number="2．">（２）破棄の方法</li>
            <p>
              －紙に出力された個人情報：シュレッダーにかけた後焼却
              <br />
              －電子ファイル形式で保存された個人情報：記録を再生することができない技術的方法で削除
            </p>
          </ol>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>４.個人情報の第三者への提供</h2>
          <p>
            当社は、お客様の個人情報を会員登録時に以下のように告知または明示して同意を受けた範囲内で、第三者に提供します。
            <br />
            利用者の個人情報は、原則として、上記事項のほか、外部に提供しません。ただし、以下の場合は例外とします。
          </p>
          <ol className={styles.ol}>
            <p>
              - 利用者が事前に同意した場合
              <br />-
              法令の規定に基づき、または、捜査目的で法令に定められた手続きと方法により捜査機関の要求がある場合
            </p>
          </ol>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>
            ５．クッキー（cookie）の運営に関する事項
          </h2>
          <p>
            当社は、パーソナライズされカスタマイズされたサービスを提供するために、利用者の情報を保存し、頻繁に引き出す「クッキー（cookie）」などを運用します。クッキーとは、会社のウェブサイトを運営するのに利用されるサーバーが利用者のブラウザに送信非常に小さなテキストファイルとして、利用者のコンピュータのハードディスクに保存されます。
          </p>
          <ol className={styles.ol}>
            <li data-number="1．">（１）クッキーなどの使用目的</li>
            <p>
              利用者の利用頻度や利用時間帯などを分析し、利用者の好みや興味を把握および追跡、各種イベントへの参加頻度及び利用回数の把握などを通じたターゲットマーケティングと合わせたサービスを提供
            </p>
            <li data-number="2．">（２）クッキーのインストール／運用</li>
            <p>
              利用者は、クッキーの設置に対する選択権を持っています。したがって、利用者は、Webブラウザでオプションを設定することにより、すべてのクッキーを許可するか、クッキーが保存されるたびに確認を取ったり、あるいはすべてのクッキーの保存を拒否することもできます。
            </p>
            <li data-number="3．">（３）クッキーの設定を拒否する</li>
            <p>
              クッキーの設定を拒否する方法では、利用者が使用しているWebブラウザのオプションを選択することにより、すべてのクッキーを許可したり、クッキーを保存するたびに確認を経たり、すべてのクッキーの保存を拒否することができます。ただし、利用者がクッキーの設置を拒否した場合、サービスの提供に困難がある場合があります。
              <br />
            </p>
          </ol>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>
            ６．個人情報保護のための技術的／管理的対策
          </h2>

          <ol className={styles.ol}>
            <li data-number="1．">
              （１）当社は、利用者の個人情報を取り扱うにあたり、個人情報が紛失、盗難、漏洩、改ざん、または毀損されないよう安全性確保のために、次のような技術的対策を講じています。
            </li>
            <p>
              -
              利用者の個人情報は暗号化され保護されています。しかし、利用者の個人情報を会社が暗　　号化させて保護しているにもかかわらず公共の場でのインターネットの使用などの過程で、意図に反して紛失したり、他人に盗まれたり流出する可能性があります。したがって、利用者は、個人情報を第三者に流出させたり、貸与、提供など公開してはならず、フィッシングなどの社会工学的方法による個人情報の無断収集から自分の個人情報を責任を持って管理しなければなりません。これらの個人情報の紛失、盗難流出、フィッシング、公開について同社は一切の責任を負いません。
              <br />
              -
              利用者の個人情報は、基本的には、パスワードによって保護され、ファイルと転送データを暗号化して、重要なデータは、別のセキュリティ機能を使用して保護されています。
              <br />
              -
              会社は、常に新しい情報を自動的にアップデートするワクチンを利用して、コンピューターウイルスによる被害を防止するために、24時間体制で保護措置を講じています。もしウイルスが侵入しても、自動的にウイルス侵入アラームを担当者に送信すると同時に自動的に回復するようになっています。
              <br />
              -
              会社は、暗号アルゴリズムを利用して、ネットワーク上の個人情報を安全に送信することができるセキュリティデバイス（SSLまたはSET）を採用しています。
              <br />-
              会社はハッキングなど、会社の情報通信網の侵入によって、利用者の個人情報が流出されることを防止するために、外部からの侵入検知と侵入防止システムを24時間稼動させています。
            </p>
            <li data-number="2．">
              （２）当社は、利用者の個人情報保護の重要性を認識しており、利用者の個人情報を保護するために、個人情報の取り扱いスタッフを最小限に制限するなど、次のような管理的措置を講じています。
            </li>
            <p>
              -
              個人情報を取り扱う従業員を対象に、新たなセキュリティ技術の習得及び個人情報保護義　務などに関して定期的な社内教育および外部委託教育を行っています。
              <br />
              -
              会社は、すべての従業員にセキュリティ誓約書を提出させ、人的情報流出を事前に防止し、個人情報保護方針の履行事項及び従業員の遵守を監視し、違反内容が確認された場合、これを是正または改善し、他の必要な措置をとるための内部手続きを用意しています。個人情報関連取扱者の業務引継ぎは、セキュリティが維持された状態で徹底的に行われており、入社/退社後、個人情報事故に対する責任を明確化しています。
              <br />
              -
              個人情報と一般データを混合して保管することなく、別のサーバーを介して分離して保管しています。
              <br />
              -
              電算室および資料保管室などを特別保護区域に設定して出入りを統制しています。
              <br />-
              会社は利用者の個人的ミスや基本的なインターネットの危険性により発生したトラブルについての責任は負いかねます。利用者一人一人が自分の個人情報を保護するために、自分のIDとパスワードを適切に管理し、その責任を負う必要があります。
            </p>
          </ol>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>７．告知の義務</h2>
          <ol className={styles.ol}>
            <li data-number="1．">
              （１）本プライバシーポリシーを含むその他の個人情報の保護の詳細についてはサービスホームページのメイン画面に公開することで、利用者がいつでも容易に見られるよう措置しています。
            </li>
            <li data-number="2．">
              （２）法令ポリシーやセキュリティ技術の変更に応じて、重要な内容の追加、削除、変更がある場合には、変更されている個人情報の取り扱い方針を施行する前に、サービスのホームページを介して変更の理由と内容等についてお知らせいたします。
            </li>
            <li data-number="3．">
              （３）本プライバシーポリシーの内容は、随時変更されることがありますので、サービスのホームページをご覧いただくたびに、ご確認お願いいたします。
            </li>
          </ol>
        </section>

        <p>本方針は、2016年3月1日から施行されます</p>
      </div>
    </div>
  </div>
)

Privacy.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
}

Privacy.defaultProps = {
  className: '',
  style: {},
}

export default Privacy

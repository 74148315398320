import prodImages from 'assets/images/products'
import images from 'assets/images'

export default {
  id: 13,
  name: 'カラダアレンジ スタビリティー乳酸菌顆粒（30包入り）',
  images: prodImages.stability.slides.map((image, i) => ({
    id: i + 1,
    src: image,
  })),
  illustration: images.karadaArrangeProductDetails,
  lpImage: (
    <img
      src={prodImages.stability.lps?.[0]}
      style={{ width: '100%' }}
      alt="lp"
    />
  ),
  intro:
    '「高濃度乳酸菌がイキイキした毎日を目指すあなたをサポート」\n\n 乳酸菌をライフスタイルに取り入れて、整える毎日を。\n\n KARADA ARRANGEに含まれている乳酸菌は、\n 健やかさをサポートする『エンテロコッカス・フェカリス菌』なのです。v そして、\n 本品には、１包あたり乳酸菌がヨーグルト100個分（※）含まれているんです！\n ※ヨーグルト一個（100グラム）に100億個の乳酸菌が含まれるとした場合',
  details: [
    {
      title: '商品名',
      content: 'カラダアレンジ スタビリティー乳酸菌顆粒（30包入り）',
    },
    {
      title: 'メーカー',
      content: '株式会社 RAPHAS JAPAN / ラパス ジャパン',
    },
    {
      title: '用途',
      content:
        '・薬に頼らない健やかなからだをつくりたい\n ・生活習慣の乱れによる不調を乳酸菌で予防したい\n ・腸内環境を整えて免疫力を高めたい ',
    },
    {
      title: 'JAN',
      content: '4573161350308',
    },
    {
      title: '容量',
      content: '30ｇ（1ｇ×30包）',
    },
    {
      title: '成分',
      content:
        '乳糖（乳成分を含む）（アメリカ製造）、デキストリン、乳酸菌末（国内製造）、フラクトオリゴ糖／リン酸三カルシウム、グァーガム',
    },
    {
      title: '製造国',
      content: '日本',
    },
    {
      title: '使用の目安',
      content:
        '健康補助食品として１日１包を目安にそのまま、または水などでお召し上がりください。',
    },
    {
      title: '使用上のご注意',
      content:
        '【保存方法】\n 直射日光、高温多湿を避けて保存してください。\n\n 【使用上の注意】\n ※食物アレルギーがある方は、原材料をご確認ください。\n ※開封後はお早めにお召し上がりください。\n ※小さなお子様の手の届かない所に保管してください。\n ※体質・体調に合わない場合は、ご利用を中止してください。\n ※本品は、多量摂取により疾病が治癒したり、より健康が増進するものではありません。',
    },
  ],
  price: {
    amount: '¥ 5,378',
    tax: '（税込）',
  },
}

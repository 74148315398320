import { createSlice } from '@reduxjs/toolkit'

// ------------------------------------
// State
// ------------------------------------

const initialState = {
  headerHeight: 0,
}

// ------------------------------------
// Slices
// -----------------------------------

const status = createSlice({
  name: 'status',
  initialState,
  reducers: {
    getHeaderHeight: (state, action) => ({
      ...state,
      headerHeight: action.payload,
    }),
  },
})

export const { getHeaderHeight } = status.actions

export default status.reducer

import React from 'react'
import Scroll from 'react-scroll'
import { PropTypes } from 'prop-types'
import styles from './productTopItem.module.scss'

const { Link } = Scroll

const ProductTop = ({ id, title, image, className, style }) => (
  <Link
    to={id}
    offset={-80}
    smooth
    className={`${styles.root} ${className}`}
    style={style}
  >
    <div className={styles.imageContainer}>
      <img src={image} alt={title} />
    </div>
    <span>{title}</span>
  </Link>
)

ProductTop.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape({}),
}

ProductTop.defaultProps = {
  id: '',
  title: '',
  image: '',
  className: '',
  style: {},
}

export default ProductTop

import accare from './accare'
import accarePlus from './accarePlus'
import beautyEye from './beautyEye'
import beautySmile from './beautySmile'
import bug from './bug'
import coolLip from './coolLip'
import fluCandy from './fluCandy'
import lacticCandy from './lacticCandy'
import lineCare from './lineCare'
import poreCare from './poreCare'
import recovery from './recovery'
import relaxy from './relaxy'
import smoothly_7 from './smoothly_7'
import smoothly_20 from './smoothly_20'
import stability_30 from './stability_30'
import vita from './vita'

export default [
  accare,
  accarePlus,
  beautyEye,
  beautySmile,
  bug,
  coolLip,
  fluCandy,
  lacticCandy,
  lineCare,
  poreCare,
  recovery,
  relaxy,
  smoothly_7,
  smoothly_20,
  stability_30,
  vita,
]

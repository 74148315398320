import React from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Button from 'components/Button'
import breakpoints from '../../../styles/_breakpoints.module.scss'
import styles from './productSlider.module.scss'

const ProductSlider = ({ images }) => {
  const isLaptop = useMediaQuery({ minWidth: breakpoints.lg })

  const settings = {
    dots: true,
    dotsClass: isLaptop ? styles.sliderThumb : styles.sliderDots,
    customPaging: isLaptop
      ? (i) => (
          <Button>
            <img src={images[i].src} alt="" />
          </Button>
        )
      : (i) => <Button>{i + 1}</Button>,
    arrows: false,
  }

  return (
    <div className={styles.sliderWrapper}>
      <Slider {...settings}>
        {images.map((image) => (
          <div key={image.id} className={styles.slide}>
            <img src={image.src} alt="" />
          </div>
        ))}
      </Slider>
    </div>
  )
}

ProductSlider.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default ProductSlider

import React from 'react'
import PropTypes from 'prop-types'
import styles from './avatar.module.scss'
import FontIcon from '../FontIcon'

const Avatar = ({ src, size, className }) => (
  <div style={{ width: size }} className={`${styles.root} ${className}`}>
    {src ? (
      <img src={src} alt="avatar" />
    ) : (
      <FontIcon name="user-circle" style={{ fontSize: size }} />
    )}
  </div>
)

Avatar.propTypes = {
  src: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
}

Avatar.defaultProps = {
  src: '',
  size: '',
  className: '',
}

export default Avatar

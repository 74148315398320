import prodImages from 'assets/images/products'
import images from 'assets/images'

export default {
  id: 18,
  name: 'うるおいチャージ CooL Lip',
  images: prodImages.coolLip.slides.map((image, i) => ({
    id: i + 1,
    src: image,
  })),
  illustration: images.acropassProductDetails,
  lpImage: (
    <>
      <img
        src={prodImages.coolLip.lps?.[0]}
        style={{ width: '100%' }}
        alt="lp"
      />
      <img
        src={prodImages.coolLip.lps?.[1]}
        style={{ width: '100%' }}
        alt="lp"
      />
    </>
  ),
  intro:
    '日本初!!!　アスタキサンチン配合成分の赤いマイクロニードル　★特許出願中★\n DENマイクロニードル技術から誕生したパッチ型化粧品「アクロパス ラインケアパッチ」は、\n 日本初！！！アスタキサンチンをついにマイクロニードルに配合することに成功しました！\n 世界13か国で特許技術を誇るアクロパスならではの高濃度配合！\n マスクが外せない時代だからこそ、気になる口元のケア！\n 口唇の荒れを防ぎ、うるおいを与えすこやかな憧れのぷっくりしたうるつやリップに！！！\n 吸収率が特に高いくちびるに、「アスタキサンチン」配合のリップパッチを使い続けることで、顔全体がツヤのあるお肌に!!!\n 特別な日の前日に！貼って寝るだけのうるつや集中ケア！',
  details: [
    {
      title: '商品名',
      content: 'うるおいチャージ CooL Lip',
    },
    {
      title: 'メーカー',
      content: '株式会社 RAPHAS JAPAN / ラパス ジャパン',
    },
    {
      title: '用途',
      content: '乾燥、乾燥による縦じわの解消、弾力のある血色の良い唇に',
    },
    {
      title: 'JAN',
      content: '4573161350360',
    },
    {
      title: '容量',
      content: '１箱２パウチ入り　※1パウチ１回分（上下1枚づつ）',
    },
    {
      title: '成分',
      content:
        'ヒアルロン酸Ｎａ、デキストリン、アラビアゴム、アスコルビルグルコシド、バニリルブチル、ヘマトコッカスプルビアリスエキス、水、トコフェロール、アラントイン、アブラナ種子油、アスタキサンチン、アセチルオクタペプチド-３（アルギニン、リシン）ポリペプチド',
    },
    {
      title: '使用の目安',
      content:
        '週１～２回（使い始めの２ヶ月は週２回、３ヵ月目以降は週１回）　　※2時間以上の付着をお薦めします。\n※ご使用感に個人差があります。',
    },
    {
      title: '使用上のご注意 ',
      content:
        '・水分に触れると表面のヒアルロン酸が溶けてしまいますので、必ず水分や油分をふき取った部位に、乾いた手でお貼りください。\n・傷、はれもの、湿疹などがある部位にはお使いにならないでください。\n・使用中、または使用後に赤み、はれ、かゆみや刺激などがあらわれた時は、すぐにご使用を中止してください。',
    },
  ],
  price: {
    amount: '¥ 1,936',
    tax: '（税込）',
  },
}
